import dayjs from 'dayjs';
import { transformedMedia } from '../transform/mediaLibrary';

import {
	MESSAGE_STATUSES,
	MESSAGE_PERIOD_STATUSES,
} from '../../../enums/onSiteMessages';

import {
	pathOr,
	datetimeFormat,
} from '../helpers';

export const getImage = (Images = []) => {
	let file = null;

	if (Images.length) {
		file = transformedMedia(pathOr(null, [0])(Images));
	}

	return file && file.imageUrls && file.imageUrls.thumbnail ? file : null;
};

export const transformedCampaignDetailForm = (campaign = {}) => {
	const id = pathOr(null, ['id'])(campaign);
	const name = pathOr(null, ['name'])(campaign);
	const status = !!pathOr(false, ['is_active'])(campaign);
	const priority = pathOr(0, ['priority'])(campaign);
	const duration = pathOr(0, ['re_eligibility_duration_hours'])(campaign);
	const sessionBased = !!pathOr(false, ['is_re_eligibility_session_based'])(campaign);

	const startDate = campaign.start_at ? dayjs(campaign.start_at).toDate() : null;
	const startTime = campaign.start_at ? datetimeFormat(campaign.start_at, 'HH:mm') : '00:00';
	const endDate = campaign.end_at ? dayjs(campaign.end_at).toDate() : null;
	const endTime = campaign.end_at ? datetimeFormat(campaign.end_at, 'HH:mm') : '23:59';

	const desktopImage = getImage(campaign.desktop_image);
	const mobileImage = getImage(campaign.mobile_image);
	const desktopBackgroundColor = campaign.desktop_background_color;
	const mobileBackgroundColor = campaign.mobile_background_color;
	const link = pathOr(null, ['link'])(campaign);

	return {
		id,
		name,
		status,
		priority,
		duration,
		sessionBased,

		startDate,
		startTime,
		endDate,
		endTime,

		desktopImage,
		mobileImage,
		desktopBackgroundColor,
		mobileBackgroundColor,
		link,
	};
};

export const transformedCampaignDetail = (campaign = {}) => {
	let periodStatus = null;

	const id = pathOr(null, ['id'])(campaign);
	const name = pathOr(null, ['name'])(campaign);
	const status = MESSAGE_STATUSES[!!pathOr(false, ['is_active'])(campaign)];
	const startAt = campaign.start_at ? datetimeFormat(campaign.start_at) : '-';
	const endAt = campaign.end_at ? datetimeFormat(campaign.end_at) : '-';
	const currentTimeFormat = new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
	const currentTimestamp = new Date(currentTimeFormat).getTime();
	const startAtTimestamp = startAt ? new Date(startAt).getTime() : null;
	const endAtTimestamp = endAt ? new Date(endAt).getTime() : null;

	/**
	 * Compare current time with start at and end at
	 */
	if (currentTimestamp < startAtTimestamp) {
		periodStatus = MESSAGE_PERIOD_STATUSES.SCHEDULE;
	} else if (
		currentTimestamp > startAtTimestamp
		&& (currentTimestamp < endAtTimestamp || !endAtTimestamp)
	) {
		periodStatus = MESSAGE_PERIOD_STATUSES.ON_GOING;
	} else if (currentTimestamp > endAtTimestamp) {
		periodStatus = MESSAGE_PERIOD_STATUSES.EXPIRED;
	}

	const createdAt = campaign.created_at ? datetimeFormat(campaign.created_at) : '-';
	const updatedAt = campaign.updated_at ? datetimeFormat(campaign.updated_at) : '-';

	return {
		id,
		name,
		status,
		startAt,
		endAt,
		periodStatus,
		createdAt,
		updatedAt,
	};
};

export const transformedCampaignList = (campaigns = []) => {
	return campaigns.map(transformedCampaignDetail);
};
