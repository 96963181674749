import isEmpty from 'lodash/isEmpty';
import { pathOr, datetimeFormat, priceToAPI, priceFromAPI } from '../helpers';
import {
	PREORDER_PAYMENT,
	PREORDER_PAYMENT_METHOD_KEY,
} from '../../../enums/preorders';
import { transformedProduct } from './products';
import { transformedProductLabels } from './productLabels';

export const transformedPreOrder = (preorder = {}) => {
	const id = pathOr(null, ['id'])(preorder);
	const duplicateId = id;
	const name = pathOr(null, ['name'])(preorder);
	const status = pathOr(null, ['is_active'])(preorder);
	const skus = pathOr(null, ['total_sku'])(preorder);
	const payment = pathOr(null, ['payment_type'])(preorder);
	const startAt = preorder.period_start_at ? datetimeFormat(preorder.period_start_at) : '-';
	const endAt = preorder.period_end_at ? datetimeFormat(preorder.period_end_at) : '-';
	const periodStatus = pathOr(null, ['period_status'])(preorder);
	const releaseDate = preorder.released_date ? datetimeFormat(preorder.released_date) : '-';
	const paymentStartAt = preorder.remaining_start_at ? datetimeFormat(preorder.remaining_start_at) : '-';
	const paymentEndAt = preorder.remaining_end_at ? datetimeFormat(preorder.remaining_end_at) : '-';
	const remainingStatus = pathOr(null, ['remaining_status'])(preorder);
	const releaseStatus = pathOr(null, ['released_status'])(preorder);

	return {
		id,
		name,
		status,
		skus,
		startAt,
		endAt,
		periodStatus,
		payment,
		releaseDate,
		paymentStartAt,
		paymentEndAt,
		remainingStatus,
		releaseStatus,
		duplicateId,
	};
};

export const transformedPreOrderList = (preorders = []) => {
	return preorders.map(transformedPreOrder);
};

export const transformedPreOrderInfo = (preorder = {}) => {
	const id = pathOr(null, ['id'])(preorder);
	const name = pathOr(null, ['name'])(preorder);
	const status = pathOr(null, ['is_active'])(preorder);
	const skus = pathOr(null, ['total_sku'])(preorder);
	const payment = pathOr(null, ['payment_type'])(preorder);
	const startAt = preorder.period_start_at ? datetimeFormat(preorder.period_start_at) : '-';
	const endAt = preorder.period_end_at ? datetimeFormat(preorder.period_end_at) : '-';
	const periodStatus = pathOr(null, ['period_status'])(preorder);
	const releaseDate = preorder.released_date ? datetimeFormat(preorder.released_date) : '-';
	const paymentStartAt = preorder.remaining_start_at ? datetimeFormat(preorder.remaining_start_at) : '-';
	const paymentEndAt = preorder.remaining_end_at ? datetimeFormat(preorder.remaining_end_at) : '-';
	const remainingStatus = pathOr(null, ['remaining_status'])(preorder);
	const releaseStatus = pathOr(null, ['released_status'])(preorder);
	const createdAt = preorder.created_at ? datetimeFormat(preorder.created_at) : '-';
	const updatedAt = preorder.updated_at ? datetimeFormat(preorder.updated_at) : '-';

	return {
		id,
		name,
		status,
		skus,
		startAt,
		endAt,
		periodStatus,
		payment,
		releaseDate,
		paymentStartAt,
		paymentEndAt,
		remainingStatus,
		releaseStatus,
		createdAt,
		updatedAt,
	};
};

/**
 * transformedPreOrderProductsFromAPI
 * @param {Array} Product object list
 * @returns {Array} Transformed object of product list
 */
export const transformedPreOrderProductsFromAPI = (products) => {
	if (isEmpty(products)) {
		return null;
	}

	return products.map((product) => {
		const transProduct = transformedProduct(product);
		return {
			id: product.id,
			sku: product.sku,
			name: product.name,
			thumbnail: transProduct.thumbnail,
			priceSelling: transProduct.priceSelling,
			manipulationViews: product.manipulation_views || null,
			manipulationSold: product.manipulation_sold || null,
			priceReserve: priceFromAPI(product.price_reserve) || null,
		};
	});
};

/**
 * transformedPreOrderRestrictProductsFromAPI
 * @param {Array} Products object list
 * @returns {Array} List of product sku
 */
export const transformedPreOrderRestrictProductsFromAPI = (products) => {
	if (isEmpty(products)) {
		return [];
	}

	return products.map((product) => product.sku);
};

/**
 * transformedPreOrderShipmentMethodFromAPI
 * @param {Array} Product object list
 * @returns {Array} sku list
 */
export const transformedPreOrderShipmentMethodFromAPI = (preOrder) => {
	if (isEmpty(preOrder)) {
		return null;
	}

	const {
		shipping_payment_method: shippingPaymentMethod = {},
		is_delivery_method_available: isDeliveryAvailable = false,
	} = preOrder;

	if (isEmpty(shippingPaymentMethod)) {
		return null;
	}

	const {
		standard = {},
		express = {},
		payment_method_setting: paymentMethodSettings = [],
	} = shippingPaymentMethod.delivery_method;

	return {
		isAvailable: !!isDeliveryAvailable,
		isStandardDeliveryAvailable: !!standard.is_active,
		standardDeliveryEn: standard.estimated_delivery_title_en,
		standardDeliveryTh: standard.estimated_delivery_title_th,
		isExpressDeliveryAvailable: !!express.is_active,
		expressDeliveryEn: express.estimated_delivery_title_en,
		expressDeliveryTh: express.estimated_delivery_title_th,
		paymentMethodSettings,
	};
};

/**
 * transformedPreOrderPickupRules
 * @param {Array} PreOrder Array
 * @returns {Array} PreOrder pickup rules
 */
export const transformedPreOrderPickupRules = (rules = []) => {
	if (Array.isArray(rules)) {
		return rules.map((rule) => ({
			id: rule.id,
			name: rule.name,
			branchIds: rule.branch_ids || [],
			branchQuantity: rule.branch_quantity || 0,
		}));
	}
	return [];
};

/**
 * transformedPreOrderPickupAtStoreFromAPI
 * @param {Object} preOrder object
 * @returns {Object} pickup at store object
 */
/* eslint-disable camelcase */
export const transformedPreOrderPickupAtStoreFromAPI = (preOrder = {}) => {
	return {
		isRulesEditable: preOrder?.is_pre_order_pickup_rules_editable ?? true,
		rules: transformedPreOrderPickupRules(preOrder?.pre_order_pickup_rules),
		paymentMethodSettings: preOrder?.shipping_payment_method?.pickup_method?.['pickup-at-store']?.payment_method_setting ?? [],
	};
};
/* eslint-enable camelcase */

/**
 * transformedPreOrderGrabAndGoFromAPI
 * @param {Object} preOrder object
 * @returns {Object} grab and go object
 */
/* eslint-disable camelcase */
export const transformedPreOrderGrabAndGoFromAPI = (preOrder = {}) => {
	const {
		branch_ids: branchIds = [],
		payment_method_setting: paymentMethodSettings = [],
	} = preOrder?.shipping_payment_method?.pickup_method?.['grab-and-go'] ?? {};

	return {
		isAvailable: preOrder?.is_grab_and_go_method_available === 1,
		branchIds,
		paymentMethodSettings: paymentMethodSettings ?? [], // Case payment_method_setting is null from API
	};
};
/* eslint-enable camelcase */

/**
 * transformedPreOrderToMultipleSectionFromAPI
 * @param {Object} PreOrder object
 * @returns {Object} PreOrder information multiple section
 */
export const transformedPreOrderToMultipleSectionFromAPI = (preOrder) => {
	if (isEmpty(preOrder)) {
		return null;
	}

	const generalInfo = {
		preOrderName: preOrder.name,
		preOrderStatus: preOrder.is_active, // enum value is numeric.
		preOrderPaymentOption: preOrder.payment_type,
	};

	const campaignSku = {
		preOrderSkus: transformedPreOrderProductsFromAPI(preOrder.products),
		preOrderIsAllowedRestrictProduct: !!preOrder.is_allowed_restrict_product,
		preOrderIsRestrictAllProducts: !!preOrder.is_restrict_all_products,
		preOrderRestrictSkus: transformedPreOrderRestrictProductsFromAPI(preOrder.restrict_products),
	};

	const productPriceDisplay = {
		isHideProductPriceDisplay: !preOrder.is_product_price_display,
		priceUnavailableDescriptionTh: preOrder.price_unavailable_description_th,
		priceUnavailableDescriptionEn: preOrder.price_unavailable_description_en,
	};

	const scheduleInfo = {
		isTimeCounterVisible: !!preOrder.is_countdown_visible,
		preOrderStartDate: preOrder.period_start_at ? new Date(datetimeFormat(preOrder.period_start_at, 'YYYY-MM-DD')) : null,
		preOrderStartTime: preOrder.period_start_at ? datetimeFormat(preOrder.period_start_at, 'HH:mm') : null,
		preOrderEndDate: preOrder.period_end_at ? new Date(datetimeFormat(preOrder.period_end_at, 'YYYY-MM-DD')) : null,
		preOrderEndTime: preOrder.period_end_at ? datetimeFormat(preOrder.period_end_at, 'HH:mm') : null,
		periodStatus: preOrder.period_status,
		remainingStatus: preOrder.remaining_status,
		releasedStatus: preOrder.released_status,
		releaseDate: preOrder.released_date ? new Date(datetimeFormat(preOrder.released_date, 'YYYY-MM-DD')) : null,
		releaseTime: preOrder.released_date ? datetimeFormat(preOrder.released_date, 'HH:mm') : null,
		releaseTitleEn: preOrder.released_title_en,
		releaseTitleTh: preOrder.released_title_th,
		releaseDescriptionEn: preOrder.released_description_en,
		releaseDescriptionTh: preOrder.released_description_th,
		paymentStartDate: preOrder.remaining_start_at ? new Date(datetimeFormat(preOrder.remaining_start_at, 'YYYY-MM-DD')) : null,
		paymentStartTime: preOrder.remaining_start_at ? datetimeFormat(preOrder.remaining_start_at, 'HH:mm') : null,
		paymentEndDate: preOrder.remaining_end_at ? new Date(datetimeFormat(preOrder.remaining_end_at, 'YYYY-MM-DD')) : null,
		paymentEndTime: preOrder.remaining_end_at ? datetimeFormat(preOrder.remaining_end_at, 'HH:mm') : null,
	};

	const limit = {
		limitPerUser: preOrder.usage_limit,
		limitPerOrder: preOrder.order_limit,
		isOverridePaymentPeriod: (preOrder.payment_expire_hour !== null || preOrder.payment_expire_minute !== null)
			|| (!!preOrder.payment_expire_hour || !!preOrder.payment_expire_minute),
		overridePaymentPeriodHourValue: preOrder.payment_expire_hour,
		overridePaymentPeriodMinuteValue: preOrder.payment_expire_minute,
	};

	const deliveryShippingInfo = transformedPreOrderShipmentMethodFromAPI(preOrder);

	const label = {
		primary: transformedProductLabels(preOrder.labels),
	};

	const campaignInfo = {
		campaignInformationEn: preOrder.scheduled_campaign_info_en,
		campaignInformationTh: preOrder.scheduled_campaign_info_th,
	};

	const termsAndConditions = {
		termsAndConditionsEn: preOrder.terms_and_conditions_en,
		termsAndConditionsTh: preOrder.terms_and_conditions_th,
		isStoreIdCard: !!preOrder.is_store_identity_document,
	};

	const pickupAtStore = transformedPreOrderPickupAtStoreFromAPI(preOrder);

	const grabAndGo = transformedPreOrderGrabAndGoFromAPI(preOrder);

	return {
		generalInfo,
		campaignSku,
		productPriceDisplay,
		scheduleInfo,
		limit,
		deliveryShippingInfo,
		label,
		campaignInfo,
		termsAndConditions,
		pickupAtStore,
		grabAndGo,
	};
};

/**
 * transformedPreOrderProductsToAPI
 * @param {Array} Product object list
 * @returns {Array} Transformed object of product list
 */
export const transformedPreOrderProductsToAPI = (products, paymentOption) => {
	if (isEmpty(products) || !paymentOption) {
		return null;
	}

	const isPaymentOptionFullPriceOnly = paymentOption === PREORDER_PAYMENT.FULL_PRICE_ONLY;

	return products.map(({ id, manipulationSold, manipulationViews, priceReserve }) => (
		{
			id,
			manipulation_views: manipulationViews || null,
			manipulation_sold: manipulationSold || null,
			price_reserve: isPaymentOptionFullPriceOnly ? null : priceToAPI(priceReserve) || null,
		}
	));
};

/**
 * transformedPreOrderPaymentMethodSettingsToAPI
 * @param {Array} paymentMethodSettings
 * @param {String} paymentOption payment option
 * @returns {Array} payment method settings
 */
export const transformedPreOrderPaymentMethodSettingsToAPI = (paymentMethodSettings = [], paymentOption) => {
	if (paymentMethodSettings?.length) {
		let list = paymentMethodSettings;

		// If is full price only mode will ignore reserve and remaining price setting
		// If is reserve price only mode will ignore full price setting
		if (paymentOption === PREORDER_PAYMENT.FULL_PRICE_ONLY) {
			list = paymentMethodSettings.map((method) => ({
				...method,
				payment_type: method.payment_type.filter((type) => type === PREORDER_PAYMENT_METHOD_KEY.FULL_PRICE),
			}));
		} else if (paymentOption === PREORDER_PAYMENT.RESERVE_ONLY) {
			list = paymentMethodSettings.map((method) => ({
				...method,
				payment_type: method.payment_type.filter((type) => type !== PREORDER_PAYMENT_METHOD_KEY.FULL_PRICE),
			}));
		}

		return list.filter((method) => !isEmpty(method.payment_type)); // Filter empty payment type out
	}
	return [];
};

/**
 * transformedPreOrderShipmentMethodToAPI
 * @param {Object} Delivery shipping
 * @param {Object} pickupAtStore pickup at store
 * @param {Object} grabAndGo grabAndGo
 * @param {String} Payment option
 * @returns {Array} Shipment method
 */
export const transformedPreOrderShipmentMethodToAPI = (deliveryShipping = {}, pickupAtStore = {}, grabAndGo = {}, paymentOption = '') => {
	const pickupPaymentMethodSettings = transformedPreOrderPaymentMethodSettingsToAPI(pickupAtStore?.paymentMethodSettings, paymentOption);
	const grabAndGoPaymentMethodSettings = transformedPreOrderPaymentMethodSettingsToAPI(grabAndGo?.paymentMethodSettings, paymentOption);

	let deliveryMethod = {};
	if (deliveryShipping?.isAvailable) {
		const deliveryPaymentMethodSettings = transformedPreOrderPaymentMethodSettingsToAPI(deliveryShipping?.paymentMethodSettings, paymentOption);

		deliveryMethod = {
			delivery_method: {
				standard: {
					is_active: deliveryShipping.isStandardDeliveryAvailable ? 1 : 0,
					estimated_delivery_title_en: deliveryShipping.standardDeliveryEn,
					estimated_delivery_title_th: deliveryShipping.standardDeliveryTh,
				},
				express: {
					is_active: deliveryShipping.isExpressDeliveryAvailable ? 1 : 0,
					estimated_delivery_title_en: deliveryShipping.expressDeliveryEn,
					estimated_delivery_title_th: deliveryShipping.expressDeliveryTh,
				},
				payment_method_setting: deliveryPaymentMethodSettings,
			},
		};
	}

	return {
		...deliveryMethod,
		pickup_method: {
			'pickup-at-store': {
				is_active: 1, // Currently is always true
				payment_method_setting: pickupPaymentMethodSettings,
			},
			'grab-and-go': {
				is_active: 1, // Currently is always true
				branch_ids: grabAndGo?.branchIds ?? [],
				payment_method_setting: grabAndGoPaymentMethodSettings,
			},
		},
	};
};

/**
 * transformedPickupRule
 * @param {Object} rule object
 * @returns {Object} rule information
 */
export const transformedPickupRule = (rule) => {
	const {
		id = null,
		name = null,
		branch_ids: branchIds = [],
		records_count: recordsCount = 0,
		is_removable: isRemovable = true,
		is_importable: isImportable = true,
	} = rule ?? {};

	return {
		id,
		name,
		branchIds,
		recordsCount,
		isRemovable,
		isImportable,
	};
};
