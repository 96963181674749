export const STATUS_OPTIONS = [
	{ name: 'All status', value: null },
	{ name: 'Active', value: 1 },
	{ name: 'Inactive', value: 0 },
];

export const BANNER_STATUSES = {
	true: { name: 'Active', value: 'active' },
	false: { name: 'Inactive', value: 'inactive' },
};

export const PERIOD_OPTIONS = [
	{ name: 'All period', value: null },
	{ name: 'Ongoing', value: 'ongoing' },
	{ name: 'Scheduled', value: 'scheduled' },
	{ name: 'Expired', value: 'expired' },
];

export const BANNER_PERIODS = {
	ongoing: { name: 'Ongoing', value: 'ongoing' },
	scheduled: { name: 'Scheduled', value: 'scheduled' },
	expired: { name: 'Expired', value: 'expired' },
};

// mapping period status color,
export const BANNER_PERIOD_COLOR = {
	[BANNER_PERIODS.ongoing.value]: 'orange',
	[BANNER_PERIODS.scheduled.value]: 'info',
	[BANNER_PERIODS.expired.value]: 'alto',
};

export const BANNER_TYPE = {
	hero: 'hero',
	highlight: 'highlight',
	small: 'small',
	text: 'text_widget',
	picture: 'picture_thumbnail',
	shortcut: 'shortcut',
	content: 'content',
};

export const BANNER_IMAGE_TYPE = {
	homepage_desktop_banner_image: 'homepage_desktop_banner_image',
	homepage_mobile_banner_image: 'homepage_mobile_banner_image',
	homepage_highlight_1_banner_image: 'homepage_highlight_1_banner_image', // highlight banner left desktop
	homepage_highlight_1_mobile_banner_image: 'homepage_highlight_1_mobile_banner_image', // highlight banner left mobile
	homepage_highlight_2_banner_image: 'homepage_highlight_2_banner_image',
	homepage_highlight_3_banner_image: 'homepage_highlight_3_banner_image',
	homepage_small_banner_image: 'homepage_small_banner_image',
	homepage_picture_thumbnail_banner_image: 'homepage_picture_thumbnail_banner_image',
};

export const BANNER_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-30' },
	{ key: 'name', label: 'Banner Name', class: 'col-name th-mw-250' },
	{ key: 'period', label: 'Period', class: 'col-period th-mw-80' },
	{ key: 'startDate', label: 'Start date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'endDate', label: 'End date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'priority', label: 'Priority', class: 'col-priority th-mw-10' },
	{ key: 'widgets', label: 'Link to', class: 'col-widget th-mw-100' },
	{ key: 'status', label: 'Status', class: 'col-status' },
];
