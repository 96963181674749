export const PRODUCT_TABLE_FIELDS = [
	{ key: 'thumbnail', label: '', class: 'col-thumbnail th-mw-50' },
	{ key: 'sku', label: 'SKU', class: 'col-sku th-mw-110', cellClass: 'td-nowrap' },
	{ key: 'appleProgramId', label: 'Apple program ID', class: 'col-apple-program-id th-mw-110', cellClass: 'td-nowrap' },
	{ key: 'name', label: 'Product name', class: 'col-name th-mw-200', sortKey: 'name' },
	{ key: 'brand', label: 'Brand', class: 'col-brand th-mw-120', sortKey: 'brand' },
	{ key: 'status', label: 'Status', class: 'col-status th-mw-80' },
	{ key: 'priceStudent', label: 'Student price', class: 'col-price-student th-mw-100', sortKey: 'price_student' },
	{ key: 'priceSRP', label: 'SRP price', class: 'col-price-src th-mw-100' },
	{ key: 'priceSelling', label: 'Selling price', class: 'col-price-sell th-mw-100', sortKey: 'selling_price' },
	{ key: 'priceSellingFranchise', label: 'Franchise price', class: 'col-price-sell th-mw-100' },
	{ key: 'stockTotal', label: 'Total qty.', class: 'col-stock-total th-mw-120', cellClass: 'td-stock-total', sortKey: 'stock_total' },
	{ key: 'stockReservedAndCommitted', label: 'Reserved+committed', class: 'col-stock-reserved', sortKey: 'stock_reserved_committed' },
	{ key: 'stockAvailable', label: 'Available stock', class: 'col-stock-available th-mw-100', cellClass: 'td-stock-available', sortKey: 'stock_available' },
	{ key: 'categories', label: 'Category', class: 'col-category th-mw-280' },
	{ key: 'createdAt', label: 'Created date', class: 'col-created-at', cellClass: 'td-nowrap' },
	{ key: 'newProductUntil', label: 'New product until', class: 'col-new-product-until' },
	{ key: 'type', label: 'Type', class: 'col-type', cellClass: 'td-nowrap' },
	{ key: 'appleProductType', label: 'Apple product type', class: 'col-apple-product-type', cellClass: 'td-nowrap' },
	{ key: 'visibility', label: 'Visibility', class: 'col-visibility th-mw-110', cellClass: 'td-nowrap' },
	{ key: 'attribute', label: 'Attribute set', class: 'col-attribute th-mw-120' },
];

export const PRODUCT_STATUSES = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	EOL: 'eol',
};

export const NEW_PRODUCT_STATUSES = {
	only_new_product: 'only_new_product',
	exclude_new_product: 'exclude_new_product',
};

export const PRODUCT_TYPES = {
	NORMAL: 'Normal',
	FREEBIE: 'Freebie',
	MAIN: 'main',
	PENCIL: 'pencil',
	APPLE_CARE: 'apple-care',
	ACCESSORY: 'accessory',
};

export const PRODUCT_TYPE_OPTIONS = [
	{ name: 'All types', value: null },
	{ name: 'Normal', value: PRODUCT_TYPES.NORMAL },
	{ name: 'Freebie', value: PRODUCT_TYPES.FREEBIE },
];

export const PRODUCT_STATUS_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Active', value: PRODUCT_STATUSES.ACTIVE },
	{ name: 'Inactive', value: PRODUCT_STATUSES.INACTIVE },
	{ name: 'EOL', value: PRODUCT_STATUSES.EOL },
];

export const NEW_PRODUCT_STATUS_OPTIONS = [
	{ name: 'All products', value: null },
	{ name: 'Only new product', value: 1 },
	{ name: 'Exclude new product', value: 0 },
];

export const PRODUCT_VISIBILITY_STATUSES = {
	LIST: 'list',
	DETAIL: 'detail',
	SEARCH: 'search',
	HIDDEN: 'hidden',
};

export const PRODUCT_VISIBILITY_STATUS_OPTIONS = [
	{ name: 'All', value: null },
	{ name: 'Product list', value: PRODUCT_VISIBILITY_STATUSES.LIST },
	{ name: 'Product detail', value: PRODUCT_VISIBILITY_STATUSES.DETAIL },
	{ name: 'Search', value: PRODUCT_VISIBILITY_STATUSES.SEARCH },
	{ name: 'All hidden', value: PRODUCT_VISIBILITY_STATUSES.HIDDEN },
];

export const PRODUCT_EXPORT = {
	PRODUCT_INFO: 'product_info',
	PRODUCT_PRICE: 'product_price',
	PRODUCT_GROUP: 'product_group',
	PRODUCT_INFO_DYNAMIC_FIELD: 'product_info_dynamic_field',
	PRODUCT_SHORT_ATTRIBUTE: 'product_short_attribute',
	APPLE_CARE_PRODUCTS: 'apple_care_products',
	STUDENT_PRICE_INFO: 'student_price_info',
};

export const APPLE_PRODUCT_TYPE_OPTIONS = [
	{ name: 'All types', value: null },
	{ name: 'Main', value: PRODUCT_TYPES.MAIN },
	{ name: 'Pencil', value: PRODUCT_TYPES.PENCIL },
	{ name: 'Apple care', value: PRODUCT_TYPES.APPLE_CARE },
	{ name: 'Accessory', value: PRODUCT_TYPES.ACCESSORY },
];

export const PRODUCT_REFS = {
	STOCK: ['product-stock'],
	GENERAL_INFO: ['product-general-info'],
	EDUCATION: ['product-education'],
	STORE_FRONT_VISIBILITY: ['product-store-front-visibility'],
	PRICE: ['product-price'],
	MEDIA: ['product-media'],
	DIMENSION: ['product-dimension'],
	CATEGORY: ['product-category'],
	LABEL: ['product-label'],
	DESCRIPTION: ['product-description'],
	SPECIFICATION: ['product-specification'],
	WARRANTY: ['product-warranty'],
	SEO: ['product-seo'],
	GROUP: ['product-group'],
	RELATED: ['product-group', 'product-related'],
	FREQUENTLY_BOUGHT_TOGETHER: ['product-group', 'product-frequently-bought-together'],
	APPLE_CARE: ['apple-care'],
	TRADE_IN_TRUEMOVE: ['trade-in-truemove'],
	REMARK: ['product-remark'],
	MUST_HAVE: ['must-have'],
};

export const PRODUCT_REFS_TITLE = {
	[PRODUCT_REFS.STOCK]: 'Stock',
	[PRODUCT_REFS.GENERAL_INFO]: 'General info',
	[PRODUCT_REFS.STORE_FRONT_VISIBILITY]: ' Store front visibility (Website) ',
	[PRODUCT_REFS.EDUCATION]: 'Education',
	[PRODUCT_REFS.PRICE]: 'Price',
	[PRODUCT_REFS.MEDIA]: 'Media',
	[PRODUCT_REFS.DIMENSION]: 'Dimension and weight',
	[PRODUCT_REFS.CATEGORY]: 'Category',
	[PRODUCT_REFS.LABEL]: 'Label',
	[PRODUCT_REFS.DESCRIPTION]: 'Description',
	[PRODUCT_REFS.SPECIFICATION]: 'Specification',
	[PRODUCT_REFS.WARRANTY]: 'Warranty',
	[PRODUCT_REFS.SEO]: 'SEO',
	[PRODUCT_REFS.GROUP]: 'Product group (Variations)',
	[PRODUCT_REFS.RELATED]: 'Related products',
	[PRODUCT_REFS.FREQUENTLY_BOUGHT_TOGETHER]: 'Frequently bought together products',
	[PRODUCT_REFS.APPLE_CARE]: 'Apple care+',
	[PRODUCT_REFS.MUST_HAVE]: 'Must have product',
	[PRODUCT_REFS.TRADE_IN_TRUEMOVE]: 'TRUE / Trade in visibility',
	[PRODUCT_REFS.REMARK]: 'Remark',
};
