import dayjs from 'dayjs';
import { transformedMedia } from '../transform/mediaLibrary';
import {
	BANNER_STATUSES,
	BANNER_PERIODS,
	BANNER_TYPE,
	BANNER_IMAGE_TYPE,
} from '../../../enums/banners';
import { IMAGE_PLACEHOLDER_URL } from '../../../enums/general';
import { pathOr, datetimeFormat } from '../helpers';


export const transformedWidgetList = (widget = {}) => {
	return pathOr(null, ['name'])(widget);
};

export const transformedBannerGroupList = (banner = {}) => {
	let period = null;
	let thumbnail = null;
	const id = pathOr(0, ['id'])(banner);
	const banners = pathOr([], ['banners'])(banner);

	/**
	 * Get thumnail from first banner
	 */
	if (banners.length) {
		thumbnail = transformedMedia(pathOr(null, ['0', 'file', '0'])(banners));
	}

	thumbnail = pathOr(IMAGE_PLACEHOLDER_URL, ['imageUrls', 'thumbnail'])(thumbnail);

	const name = pathOr(null, ['name'])(banner);
	const startDate = banner.start_at ? datetimeFormat(banner.start_at) : '-';
	const endDate = banner.end_at ? datetimeFormat(banner.end_at) : '-';
	const priority = pathOr(0, ['priority'])(banner);
	const status = BANNER_STATUSES[!!pathOr(false, ['is_visible'])(banner)];
	const currentTimeFormat = new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
	const currentTimestamp = new Date(currentTimeFormat).getTime();
	const startAtTimestamp = startDate ? new Date(startDate).getTime() : null;
	const endAtTimestamp = startDate ? new Date(endDate).getTime() : null;
	const widgetList = pathOr([], ['widgets'])(banner);
	const widgets = widgetList.map(transformedWidgetList);

	/**
	 * Comapre current time with start at and end at
	 */
	if (currentTimestamp < startAtTimestamp) {
		period = BANNER_PERIODS.scheduled;
	} else if (
		currentTimestamp > startAtTimestamp
		&& (currentTimestamp < endAtTimestamp || !endAtTimestamp)
	) {
		period = BANNER_PERIODS.ongoing;
	} else if (currentTimestamp > endAtTimestamp) {
		period = BANNER_PERIODS.expired;
	} else {
		period = { name: '-', value: '-' };
	}

	return {
		id,
		thumbnail,
		name,
		period,
		startDate,
		endDate,
		priority,
		widgets,
		status,
	};
};

export const getBannerFromType = (banners = [], type) => {
	const index = banners.findIndex((banner) => banner.type === type);

	let file = null;
	if (banners.length) {
		file = transformedMedia(pathOr(null, [index, 'file', '0'])(banners));
	}

	return file && file.imageUrls && file.imageUrls.thumbnail ? file : null;
};

export const getBannerLinkFromType = (banners = [], type) => {
	const index = banners.findIndex((banner) => banner.type === type);

	return pathOr(null, [index, 'link'])(banners);
};

export const transformedBannerGroupDetailHero = (banners = []) => {
	return {
		desktopBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_desktop_banner_image),
		mobileBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_mobile_banner_image),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_desktop_banner_image),
	};
};

export const transformedBannerGroupDetailHighlight = (banners = []) => {
	return {
		leftBannerDesktop: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image),
		leftBannerMobile: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_1_mobile_banner_image),
		topRightBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image),
		bottomRightBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image),
		bannerLinkLeft: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image),
		bannerLinkTopRight: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image),
		bannerLinkBottomRight: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image),
	};
};

export const transformedBannerGroupDetailSmall = (banners = []) => {
	return {
		banner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_small_banner_image),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_small_banner_image),
	};
};

export const transformedBannerGroupDetailPictureThumbnail = (banners = []) => {
	return {
		banner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_picture_thumbnail_banner_image),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_picture_thumbnail_banner_image),
	};
};

export const transformedContentGroupDetailText = (banner = []) => {
	return {
		desktopTh: pathOr(null, ['content_desktop_th'])(banner),
		desktopEn: pathOr(null, ['content_desktop_en'])(banner),
		mobileTh: pathOr(null, ['content_mobile_th'])(banner),
		mobileEn: pathOr(null, ['content_mobile_en'])(banner),
	};
};

export const transformedBannerGroupDetail = (banner = {}) => {
	// general and date
	const id = pathOr(0, ['id'])(banner);
	const name = pathOr(null, ['name'])(banner);
	const priority = pathOr(0, ['priority'])(banner);
	const status = !!pathOr(false, ['is_visible'])(banner);
	const startDate = banner.start_at ? dayjs(banner.start_at).toDate() : null;
	const startTime = banner.start_at ? datetimeFormat(banner.start_at, 'HH:mm') : '00:00';
	const endDate = banner.end_at ? dayjs(banner.end_at).toDate() : null;
	const endTime = banner.end_at ? datetimeFormat(banner.end_at, 'HH:mm') : '23:59';
	const widgets = pathOr(null, ['widgets'])(banner);

	// banner images and links
	let banners = {};
	if (banner.type === BANNER_TYPE.hero) {
		banners = transformedBannerGroupDetailHero(banner.banners);
	} else if (banner.type === BANNER_TYPE.highlight) {
		banners = transformedBannerGroupDetailHighlight(banner.banners);
	} else if (banner.type === BANNER_TYPE.small) {
		banners = transformedBannerGroupDetailSmall(banner.banners);
	} else if (banner.type === BANNER_TYPE.picture) {
		banners = transformedBannerGroupDetailPictureThumbnail(banner.banners);
	}

	let contents = {};
	if (banner.type === BANNER_TYPE.text) {
		contents = transformedContentGroupDetailText(banner);
	}

	return {
		id,
		name,
		priority,
		status,
		startDate,
		startTime,
		endDate,
		endTime,
		banners,
		contents,
		widgets,
	};
};
