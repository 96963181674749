import httpClient from './httpClient';

export const PAYMENT_ENDPOINT = '/backoffice/payment-methods';

export const getPaymentMethodGroupsAPI = (params) => httpClient.get(`${PAYMENT_ENDPOINT}/groups`, { params });
export const getPaymentMethodAPI = (id) => httpClient.get(`${PAYMENT_ENDPOINT}/${id}`);
export const getPaymentMethodGroupAPI = (id) => httpClient.get(`${PAYMENT_ENDPOINT}/groups/${id}`);
export const updatePaymentMethodAPI = (id, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${id}`, params);
export const updatePaymentMethodGroupAPI = (id, params) => httpClient.put(`${PAYMENT_ENDPOINT}/groups/${id}`, params);

// Installment
export const createInstallmentPlanAPI = (methodId, params) => httpClient.post(`${PAYMENT_ENDPOINT}/${methodId}/installment-plans`, params);
export const getInstallmentPlansAPI = (methodId, params) => httpClient.get(`${PAYMENT_ENDPOINT}/${methodId}/installment-plans`, { params });
export const getInstallmentPlanAPI = (methodId, planId) => httpClient.get(`${PAYMENT_ENDPOINT}/${methodId}/installment-plans/${planId}`);
export const updateInstallmentPlanAPI = (methodId, planId, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${methodId}/installment-plans/${planId}`, params);
export const deleteInstallmentPlanAPI = (methodId, planId) => httpClient.delete(`${PAYMENT_ENDPOINT}/${methodId}/installment-plans/${planId}`);

// Installment plan group
export const createInstallmentPlanGroupAPI = (methodId, params) => httpClient.post(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups`, params);
export const getInstallmentPlanGroupAPI = (methodId, brandId) => httpClient.get(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups/${brandId}`);
export const updateInstallmentPlanGroupAPI = (methodId, brandId, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups/${brandId}`, params);
export const deleteInstallmentPlanGroupAPI = (methodId, brandId) => httpClient.delete(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups/${brandId}`);

// Installment plan for group
export const createInstallmentPlanForGroupAPI = (methodId, brandId, params) => httpClient.post(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups/${brandId}/installment-plans`, params);
export const getInstallmentPlanForGroupAPI = (methodId, brandId, planId) => httpClient.get(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups/${brandId}/installment-plans/${planId}`);
export const updateInstallmentPlanForGroupAPI = (methodId, brandId, planId, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${methodId}/installment-plan-groups/${brandId}/installment-plans/${planId}`, params);

// Create channel in installment (other bank)
export const createInstallmentPlanChannelAPI = (methodId, channelId, params) => httpClient.post(`${PAYMENT_ENDPOINT}/${methodId}/channels/${channelId}/installment-plans`, params);
export const updateInstallmentPlanChannelAPI = (methodId, channelId, planId, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${methodId}/channels/${channelId}/installment-plans/${planId}`, params);

// Bank Channel
export const createBankChannelAPI = (methodId, params) => httpClient.post(`${PAYMENT_ENDPOINT}/${methodId}/channels`, params);
export const getBankChannelAPI = (methodId, channel) => httpClient.get(`${PAYMENT_ENDPOINT}/${methodId}/channels/${channel}`);
export const updateBankChannelAPI = (methodId, channel, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${methodId}/channels/${channel}`, params);
export const deleteBankChannelAPI = (methodId, channel) => httpClient.delete(`${PAYMENT_ENDPOINT}/${methodId}/channels/${channel}`);

// KTC forever plan
export const getForeverPlanAPI = (methodId, planId) => httpClient.get(`${PAYMENT_ENDPOINT}/${methodId}/forever-plans/${planId}`);
export const createForeverPlanAPI = (methodId, params) => httpClient.post(`${PAYMENT_ENDPOINT}/${methodId}/forever-plans`, params);
export const updateForeverPlanAPI = (methodId, planId, params) => httpClient.put(`${PAYMENT_ENDPOINT}/${methodId}/forever-plans/${planId}`, params);
export const deleteForeverPlanAPI = (methodId, planId) => httpClient.delete(`${PAYMENT_ENDPOINT}/${methodId}/forever-plans/${planId}`);
