import { CATEGORY_LEVEL } from '../../../enums/categories';
import { transformedMedia, transformedMediaBanner } from '../transform/mediaLibrary';
import { transformedAttributeSetList } from './attributeSets';

export const getCategoryLevel = (categoryParent = null) => {
	if (categoryParent) {
		if (categoryParent.parent) {
			return {
				level: CATEGORY_LEVEL.THREE,
				parentLevelOneId: categoryParent.parent.id || null,
				parentLevelTwoId: categoryParent.id || null,
			};
		}
		return {
			level: CATEGORY_LEVEL.TWO,
			parentLevelOneId: categoryParent.id || null,
			parentLevelTwoId: null,
		};
	}
	return {
		level: CATEGORY_LEVEL.ONE,
		parentLevelOneId: null,
		parentLevelTwoId: null,
	};
};

export const transformedCategory = (category) => {
	const categoryData = category || {};
	const children = categoryData.children || [];
	const seo = categoryData.seo || {};
	const attributeKeys = categoryData.attribute_keys || [];
	const levelData = getCategoryLevel(categoryData.parent);
	const banners = categoryData?.banners ?? [];
	const iconImage = categoryData?.media?.icons?.[0] ?? null;

	return {
		id: categoryData.id || null,
		slug: categoryData.slug || null,
		name: categoryData.name || categoryData.name_en || null,
		nameTH: categoryData.name_th || null,
		customUrl: categoryData.custom_url || null,
		commission: categoryData.commission || null,
		status: !!(categoryData.is_active || false),
		new: !!(categoryData.is_new || false),
		visibility: !!(categoryData.is_visible || false),
		tradeInTruemoveVisibility: !!(categoryData.is_trade_in_truemove_visible || false),
		descriptionTop: categoryData.description_top || null,
		descriptionBottom: categoryData.description_bottom || null,
		banners: banners.map((banner) => ({
			link: banner.link,
			file: transformedMediaBanner(banner?.files?.[0]),
		})),
		icon: iconImage ? transformedMedia(iconImage) : null,
		bannerVisibility: false, // TODO: use real data from API
		seo: {
			title: seo.title_en || null,
			titleTH: seo.title_th || null,
			keyword: seo.keyword_en || null,
			keywordTH: seo.keyword_th || null,
			description: seo.description_en || null,
			descriptionTH: seo.description_th || null,
		},
		attributeKeys: transformedAttributeSetList(attributeKeys),
		hasChild: children.length > 0,
		children: children.map(transformedCategory),
		level: levelData.level,
		parentLevelOneId: levelData.parentLevelOneId,
		parentLevelTwoId: levelData.parentLevelTwoId,
		link: categoryData.compare_link || null,
		compare: categoryData.compare_text_en || null,
		compareTH: categoryData.compare_text_th || null,
		isExpand: categoryData.is_description_top_expanded || false,
	};
};

export const transformedCategoryDropdownOption = (category) => {
	const categoryData = category || {};
	return {
		value: categoryData.id || null,
		name: categoryData.name || null,
	};
};

export const transformedManageFilterItem = (item = {}) => ({
	name: item.name || null,
	data: {
		id: item.id || null,
		status: item.status || false,
	},
});

export const transformedManageFilterList = (list = []) => list.map(transformedManageFilterItem);
