import httpClient from './httpClient';

export const POPUP_CAMPAIGNS_ENDPOINT = '/backoffice/pop-up-campaigns';
export const STRIP_CAMPAIGNS_ENDPOINT = '/backoffice/strip-banner-campaigns';

export const getPopupCampaignsAPI = (params = {}) => httpClient.get(POPUP_CAMPAIGNS_ENDPOINT, { params });
export const getPopupCampaignAPI = (id) => httpClient.get(`${POPUP_CAMPAIGNS_ENDPOINT}/${id}`);
export const postPopupCampaignAPI = (params) => httpClient.post(`${POPUP_CAMPAIGNS_ENDPOINT}`, params);
export const updatePopupCampaignAPI = (id, params) => httpClient.put(`${POPUP_CAMPAIGNS_ENDPOINT}/${id}`, params);
export const deletePopupCampaignAPI = (id) => httpClient.delete(`${POPUP_CAMPAIGNS_ENDPOINT}/${id}`);

export const getStripCampaignsAPI = (params = {}) => httpClient.get(STRIP_CAMPAIGNS_ENDPOINT, { params });
export const getStripCampaignAPI = (id) => httpClient.get(`${STRIP_CAMPAIGNS_ENDPOINT}/${id}`);
export const postStripCampaignAPI = (params) => httpClient.post(`${STRIP_CAMPAIGNS_ENDPOINT}`, params);
export const updateStripCampaignAPI = (id, params) => httpClient.put(`${STRIP_CAMPAIGNS_ENDPOINT}/${id}`, params);
export const deleteStripCampaignAPI = (id) => httpClient.delete(`${STRIP_CAMPAIGNS_ENDPOINT}/${id}`);

