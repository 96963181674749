import { PAGE_LEVEL } from '../../../enums/pages';
import { transformedMedia } from './mediaLibrary';

export const getPageLevel = (parent = null) => {
	if (parent) {
		return {
			level: PAGE_LEVEL.TWO,
			parentLevelOneId: parent.id || null,
		};
	}
	return {
		level: PAGE_LEVEL.ONE,
		parentLevelOneId: null,
	};
};

export const transformedPageDetails = (page = {}) => {
	const levelData = getPageLevel(page.parent);
	const seo = page.seo || {};

	return {
		id: page.id || null,
		slug: page.slug || null,
		name: page.name || page.name_en || null,
		nameTH: page.name_th || null,
		level: levelData.level,
		parentLevelOneId: levelData.parentLevelOneId,
		status: !!page.is_active,
		visibility: !!page.is_visible,
		headerVisibility: !!page.is_header_visible,
		sidebarVisibility: !!page.is_sidebar_visible,
		subHeaderVisibility: !!page.is_sub_header_visible,
		subHeaderLink: page.sub_header_link || '',
		content: page.content_en || '',
		contentTH: page.content_th || '',
		metaTitle: seo.title_en || '',
		metaTitleTH: seo.title_th || '',
		metaKeyword: seo.keyword_en || '',
		metaKeywordTH: seo.keyword_th || '',
		metaDescription: seo.description_en || '',
		metaDescriptionTH: seo.description_th || '',
		metaImage: seo.image && seo.image[0] ? transformedMedia(seo.image[0]) : null,
	};
};

export const transformedPageList = (page = {}) => {
	const children = page.children || [];
	const levelData = getPageLevel(page.parent);

	return {
		id: page.id || null,
		slug: page.slug || null,
		name: page.name || page.name_en || null,
		nameTH: page.name_th || null,
		hasChild: children.length > 0,
		children: children.map(transformedPageList),
		level: levelData.level,
		parentLevelOneId: levelData.parentLevelOneId,
	};
};

export const transformedPageDropdownOption = (page = {}) => ({
	value: page.id || null,
	name: page.name || null,
});
