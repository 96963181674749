import { i18n } from '@/i18n';
import {
	getPromotionsAPI,
	getPromotionAPI,
	postPromotionAPI,
	updatePromotionAPI,
	deletePromotionAPI,
	postPromotionRemarkAPI,
} from '../../services/api/promotions.api';
import {
	GET_PROMOTIONS_REQUEST,
	GET_PROMOTIONS_SUCCESS,
	GET_PROMOTIONS_FAILURE,
	GET_PROMOTION_REQUEST,
	GET_PROMOTION_SUCCESS,
	GET_PROMOTION_FAILURE,
	CREATE_PROMOTION_REQUEST,
	CREATE_PROMOTION_SUCCESS,
	CREATE_PROMOTION_FAILURE,
	UPDATE_PROMOTION_REQUEST,
	UPDATE_PROMOTION_SUCCESS,
	UPDATE_PROMOTION_FAILURE,
	DELETE_PROMOTION_REQUEST,
	DELETE_PROMOTION_SUCCESS,
	DELETE_PROMOTION_FAILURE,
	CREATE_PROMOTION_REMARK_REQUEST,
	CREATE_PROMOTION_REMARK_SUCCESS,
	CREATE_PROMOTION_REMARK_FAILURE,
} from '../types';
import {
	transformedPromotionList,
	transformedPromotion,
} from '../../assets/js/transform/promotions';
import { numberFormat } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			data: [],
			lastUpdated: null,
			isLoading: false,
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
	},
	getters: {
		getPromotionList(state) {
			return transformedPromotionList(state.list.data);
		},
		getPromotionDetail(state) {
			return state.edit.data;
		},
		getPromotionGeneralInfo(state) {
			return transformedPromotion(state.edit.data);
		},
	},
	mutations: {
		[GET_PROMOTIONS_REQUEST](state) {
			state.list = {
				...state.list,
				data: [],
				meta: {
					currentPage: 1,
					lastPage: 0,
				},
				isLoading: true,
			};
		},
		[GET_PROMOTIONS_SUCCESS](state, { data, meta = {}, ...rest }) {
			state.list = {
				...rest,
				data,
				meta: {
					...meta,
					currentPage: meta.current_page,
					lastPage: meta.last_page,
					total: numberFormat(meta.total),
				},
				isLoading: false,
			};
		},
		[GET_PROMOTIONS_FAILURE](state) {
			state.list.isLoading = false;
		},
		[GET_PROMOTION_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_PROMOTION_SUCCESS](state, { data }) {
			state.edit.data = data;
			state.edit.isLoading = false;
		},
		[GET_PROMOTION_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[CREATE_PROMOTION_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_PROMOTION_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_PROMOTION_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_PROMOTION_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_PROMOTION_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_PROMOTION_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_PROMOTION_REQUEST]() { },
		[DELETE_PROMOTION_SUCCESS]() { },
		[DELETE_PROMOTION_FAILURE]() { },
		[CREATE_PROMOTION_REMARK_REQUEST]() { },
		[CREATE_PROMOTION_REMARK_SUCCESS]() { },
		[CREATE_PROMOTION_REMARK_FAILURE]() { },
	},
	actions: {
		async getPromotions({ commit }, query) {
			try {
				commit(GET_PROMOTIONS_REQUEST);
				const { data } = await getPromotionsAPI(query);
				commit(GET_PROMOTIONS_SUCCESS, data);
			} catch (error) {
				commit(GET_PROMOTIONS_FAILURE);
			}
		},
		async getPromotion({ commit }, id) {
			try {
				commit(GET_PROMOTION_REQUEST);

				const { data } = await getPromotionAPI(id);

				commit(GET_PROMOTION_SUCCESS, data);
			} catch (error) {
				commit(GET_PROMOTION_FAILURE);
			}
		},
		async createPromotion({ commit, dispatch }, params) {
			try {
				commit(CREATE_PROMOTION_REQUEST);
				await postPromotionAPI(params);
				commit(CREATE_PROMOTION_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PROMOTION_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async updatePromotion({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_PROMOTION_REQUEST);

				await updatePromotionAPI(id, params);

				commit(UPDATE_PROMOTION_SUCCESS);
				dispatch('getPromotion', id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_PROMOTION_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async deletePromotion({ commit, dispatch }, id) {
			try {
				commit(DELETE_PROMOTION_REQUEST);

				await deletePromotionAPI(id);

				commit(DELETE_PROMOTION_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Promotion' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_PROMOTION_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async createPromotionRemark({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(CREATE_PROMOTION_REMARK_REQUEST);

				const { data } = await postPromotionRemarkAPI(id, params);

				commit(CREATE_PROMOTION_REMARK_SUCCESS);

				dispatch(
					'toast/showCreateSuccessToast',
					'remark',
					{ root: true },
				);

				return data;
			} catch (error) {
				commit(CREATE_PROMOTION_REMARK_FAILURE);
				dispatch(
					'toast/showCreateErrorToast',
					null,
					{ root: true },
				);

				throw error;
			}
		},
	},
};
