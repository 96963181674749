import {
	GET_CATEGORY_LIST_REQUEST,
	GET_CATEGORY_LIST_SUCCESS,
	GET_CATEGORY_LIST_FAILURE,
	CHECKED_CATEGORY,
	SELECT_CATEGORY,
	// SELECT_ALL_CATEGORY,
	CLEAR_ALL_CHECKED,
	CLEAR_ALL_SELECTED,
} from '../types';
import { getCategoriesAPI } from '../../services/api/categories.api';

const normalizeCategoriesWithLevel = (categories, parent = null, level = 1) => categories
	.reduce(
		(acc, category) => {
			let node = {
				result: [
					...new Set([
						...acc.result,
						category.id,
					]),
				],
				entities: {
					...acc.entities,
					[category.id]: category,
				},
			};
			if (category.children) {
				category.pathName = parent
					? [...parent.pathName, category.name]
					: [category.name];

				const { entities, result } = normalizeCategoriesWithLevel(category.children, category, level + 1);
				node = {
					result: [
						...new Set([
							...node.result,
							...result,
						]),
					],
					entities: {
						...node.entities,
						...entities,
					},
				};
				category.level = level;
				category.parentId = parent ? parent.id : null;
				category.children = category.children.map((categoryChild) => categoryChild.id);
				category.checked = false;
				category.childrenCount = 0;
			}
			return node;
		},
		{ result: [], entities: {} },
	);

const countChildren = (state, categoryId, checked) => {
	const category = state.categories.byIds[categoryId];
	if (category.parentId) {
		const parentCategory = state.categories.byIds[category.parentId];
		if (checked) {
			parentCategory.childrenCount += 1;
		} else {
			parentCategory.childrenCount -= 1;
		}

		countChildren(state, parentCategory.id, checked);
	}
};

export default {
	namespaced: true,
	state: {
		isLoading: false,
		categories: {
			byIds: {},
			allIds: [],
		},
		level1: {
			selectedId: null,
			data: [],
		},
		level2: {
			selectedId: null,
			data: [],
		},
		level3: {
			selectedId: null,
			data: [],
		},
	},
	getters: {
		isSelectedCategoryLevel: (state) => (level) => {
			return state[`level${level}`].selectedId;
		},
		getTotalChecked(state) {
			return Object.values(state.categories.byIds).filter((category) => category.checked).length;
		},
		getSelectedCategoryLevel: (state, getters) => (categoryLevel) => {
			const categoryId = state[`level${categoryLevel}`].selectedId;
			return categoryId ? getters.getCategoryById(categoryId) : null;
		},
		getCategoryById: (state) => (categoryId) => {
			return state.categories.byIds[categoryId];
		},
		getCategoriesByLevel: (state) => (level) => {
			return state[`level${level}`].data.map((id) => state.categories.byIds[id]);
		},
		getSelectedCategories: (state) => {
			return Object.values(state.categories.byIds).filter((category) => category.checked);
		},
	},
	mutations: {
		[SELECT_CATEGORY](state, { level, selectedId }) {
			state[`level${level}`].selectedId = selectedId;

			if (state[`level${level + 1}`]) {
				state[`level${level + 1}`].data = state.categories.byIds[selectedId].children;
			}

			if (state[`level${level + 2}`]) {
				state[`level${level + 2}`].data = [];
			}
		},
		[CHECKED_CATEGORY](state, { categoryId, checked }) {
			if (state.categories.byIds[categoryId]) {
				state.categories.byIds[categoryId].checked = checked;
				countChildren(state, categoryId, checked);
			}
		},
		[CLEAR_ALL_CHECKED](state) {
			Object.values(state.categories.byIds).forEach((category) => {
				category.checked = false;
				category.childrenCount = 0;
			});
		},
		[CLEAR_ALL_SELECTED](state) {
			// Reset level 1
			state.level1.selectedId = null;

			// Reset level 2
			state.level2.data = [];
			state.level2.selectedId = null;

			// Reset level 3
			state.level3.data = [];
			state.level3.selectedId = null;
		},
		[GET_CATEGORY_LIST_REQUEST](state) {
			state.isLoading = true;
		},
		[GET_CATEGORY_LIST_SUCCESS](state, payload) {
			const { entities, result } = payload;

			state.categories.byIds = {
				...state.categories.byIds,
				...entities,
			};

			state.categories.allIds = [...new Set([
				...state.categories.allIds,
				...result,
			])];

			state.level1.data = Object.values(entities)
				.reduce((acc, category) => {
					return category.level === 1
						? [...acc, category.id]
						: acc;
				}, []);

			state.isLoading = false;
		},
		[GET_CATEGORY_LIST_FAILURE](state) {
			state.isLoading = false;
		},
	},
	actions: {
		async selectCategory({ state, commit }, categoryId) {
			const category = state.categories.byIds[categoryId];
			const payload = {
				level: category.level,
				selectedId: categoryId,
			};

			if (category.level === 1) {
				state.level2.selectedId = false;
				state.level3.selectedId = false;
			} else if (category.level === 2) {
				state.level3.selectedId = false;
			}

			commit(SELECT_CATEGORY, payload);
		},
		async checkedCategory({ commit }, { categoryId, checked }) {
			commit(CHECKED_CATEGORY, { categoryId, checked });
		},
		async getCategories({ commit }) {
			commit(GET_CATEGORY_LIST_REQUEST);
			try {
				const { data } = await getCategoriesAPI();
				const normalizeData = normalizeCategoriesWithLevel(data.data);
				commit(GET_CATEGORY_LIST_SUCCESS, normalizeData);
			} catch (e) {
				commit(GET_CATEGORY_LIST_FAILURE);
			}
		},
		clearAllChecked({ commit }) {
			commit(CLEAR_ALL_CHECKED);
		},
		clearAllSelected({ commit }) {
			commit(CLEAR_ALL_SELECTED);
		},
		/**
		 * Auto pre select category
		 * @param {Array} categoryIds
		 */
		preSelectCategories({ commit, dispatch }, categoryIds = []) {
			// Reset All checked and selected before select new ids
			dispatch('clearAllChecked');
			dispatch('clearAllSelected');

			categoryIds.forEach((id) => {
				commit(CHECKED_CATEGORY, { categoryId: id, checked: true });
			});
		},
		selectAllCategory({ state, commit, dispatch }) {
			dispatch('clearAllChecked');

			state.categories.allIds.forEach((categoryId) => {
				commit(CHECKED_CATEGORY, { categoryId, checked: true });
			});
		},
	},
};
