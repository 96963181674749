export default {
	create: {
		slugFormat: 'Allow letters, numbers, and ( - )',
		duplicateSlug: 'Slug already exists',
		successMessage: 'New category has been created.',
		linkBannerInvalidFormat: 'Invalid url format',
		linkBannerHelperText: 'Leave it blank to dismiss the banner link',
	},
	edit: {
		categoryId: 'Category ID',
		filterDetail: 'Manage what attributes can be filters when users browse products through product list page.',
	},
	delete: {
		modalConfirm: {
			title: 'Remove this category?',
			description: 'Removing this category will also remove all its sub-categories underneath, detach from associated SKU, and detach from settings like payment method by category.',
			inputPlaceholder: 'type REMOVE to confirm',
		},
		successMessage: 'Category has been removed.',
	},
	manageFilter: {
		description: 'These are attributes from all the products within this category. Turning an attribute off, users won\'t be able to use that attribute to filter products in product list, but will still see the attribute in product detail specification.',
	},
	status: {
		active: 'Active',
		inActive: 'Inactive',
	},
	visibility: {
		on: 'On',
		off: 'Off',
	},
	expand: {
		on: 'Expand',
		off: 'Collapse',
	},
};
