import httpClient from './httpClient';

const SUB_HEADER_ENDPOINT = '/backoffice/sub-headers';

const getSubHeaderAPI = () => httpClient.get(SUB_HEADER_ENDPOINT);
const putSubHeaderAPI = (type, params) => httpClient.put(`${SUB_HEADER_ENDPOINT}/${type}`, params);

export {
	getSubHeaderAPI,
	putSubHeaderAPI,
};
