import { i18n } from '@/i18n';
import {
	getPaymentMethodGroupsAPI,
	getPaymentMethodGroupAPI,
	updatePaymentMethodGroupAPI,
	getPaymentMethodAPI,
	updatePaymentMethodAPI,
	getInstallmentPlanAPI,
	createInstallmentPlanAPI,
	updateInstallmentPlanAPI,
	deleteInstallmentPlanAPI,
	getForeverPlanAPI,
	createForeverPlanAPI,
	updateForeverPlanAPI,
	deleteForeverPlanAPI,
	getBankChannelAPI,
	createBankChannelAPI,
	updateBankChannelAPI,
	deleteBankChannelAPI,
	createInstallmentPlanChannelAPI,
	updateInstallmentPlanChannelAPI,
	createInstallmentPlanGroupAPI,
	getInstallmentPlanGroupAPI,
	updateInstallmentPlanGroupAPI,
	deleteInstallmentPlanGroupAPI,
	createInstallmentPlanForGroupAPI,
	getInstallmentPlansAPI,
	getInstallmentPlanForGroupAPI,
	updateInstallmentPlanForGroupAPI,
} from '@/services/api/payments.api';
import {
	GET_PAYMENT_METHOD_GROUPS_REQUEST,
	GET_PAYMENT_METHOD_GROUPS_SUCCESS,
	GET_PAYMENT_METHOD_GROUPS_FAILURE,
	GET_PAYMENT_METHOD_GROUP_REQUEST,
	GET_PAYMENT_METHOD_GROUP_SUCCESS,
	GET_PAYMENT_METHOD_GROUP_FAILURE,
	GET_PRE_ORDER_PAYMENT_METHOD_GROUPS_REQUEST,
	SET_PRE_ORDER_PAYMENT_LIST,
	UPDATE_PAYMENT_METHOD_GROUP_REQUEST,
	UPDATE_PAYMENT_METHOD_GROUP_SUCCESS,
	UPDATE_PAYMENT_METHOD_GROUP_FAILURE,
	GET_PAYMENT_METHOD_REQUEST,
	GET_PAYMENT_METHOD_SUCCESS,
	GET_PAYMENT_METHOD_FAILURE,
	UPDATE_PAYMENT_METHOD_REQUEST,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_FAILURE,
	GET_INSTALLMENT_PLAN_REQUEST,
	GET_INSTALLMENT_PLAN_SUCCESS,
	GET_INSTALLMENT_PLAN_FAILURE,
	GET_INSTALLMENT_PLANS_REQUEST,
	GET_INSTALLMENT_PLANS_SUCCESS,
	GET_INSTALLMENT_PLANS_FAILURE,
	CREATE_INSTALLMENT_PLAN_REQUEST,
	CREATE_INSTALLMENT_PLAN_SUCCESS,
	CREATE_INSTALLMENT_PLAN_FAILURE,
	UPDATE_INSTALLMENT_PLAN_REQUEST,
	UPDATE_INSTALLMENT_PLAN_SUCCESS,
	UPDATE_INSTALLMENT_PLAN_FAILURE,
	DELETE_INSTALLMENT_PLAN_REQUEST,
	DELETE_INSTALLMENT_PLAN_SUCCESS,
	DELETE_INSTALLMENT_PLAN_FAILURE,
	GET_BANK_CHANNEL_REQUEST,
	GET_BANK_CHANNEL_SUCCESS,
	GET_BANK_CHANNEL_FAILURE,
	CREATE_BANK_CHANNEL_REQUEST,
	CREATE_BANK_CHANNEL_SUCCESS,
	CREATE_BANK_CHANNEL_FAILURE,
	UPDATE_BANK_CHANNEL_REQUEST,
	UPDATE_BANK_CHANNEL_SUCCESS,
	UPDATE_BANK_CHANNEL_FAILURE,
	DELETE_BANK_CHANNEL_REQUEST,
	DELETE_BANK_CHANNEL_SUCCESS,
	DELETE_BANK_CHANNEL_FAILURE,
	CREATE_INSTALLMENT_PLAN_CHANNEL_REQUEST,
	CREATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS,
	CREATE_INSTALLMENT_PLAN_CHANNEL_FAILURE,
	UPDATE_INSTALLMENT_PLAN_CHANNEL_REQUEST,
	UPDATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS,
	UPDATE_INSTALLMENT_PLAN_CHANNEL_FAILURE,
	CREATE_INSTALLMENT_PLAN_GROUP_REQUEST,
	CREATE_INSTALLMENT_PLAN_GROUP_SUCCESS,
	CREATE_INSTALLMENT_PLAN_GROUP_FAILURE,
	GET_CC_FOREVER_PLAN_REQUEST,
	GET_CC_FOREVER_PLAN_SUCCESS,
	GET_CC_FOREVER_PLAN_FAILURE,
	UPDATE_CC_FOREVER_PLAN_REQUEST,
	UPDATE_CC_FOREVER_PLAN_SUCCESS,
	UPDATE_CC_FOREVER_PLAN_FAILURE,
	CREATE_CC_FOREVER_PLAN_REQUEST,
	CREATE_CC_FOREVER_PLAN_SUCCESS,
	CREATE_CC_FOREVER_PLAN_FAILURE,
	DELETE_CC_FOREVER_PLAN_REQUEST,
	DELETE_CC_FOREVER_PLAN_SUCCESS,
	DELETE_CC_FOREVER_PLAN_FAILURE,
	GET_INSTALLMENT_PLAN_GROUP_REQUEST,
	GET_INSTALLMENT_PLAN_GROUP_SUCCESS,
	GET_INSTALLMENT_PLAN_GROUP_FAILURE,
	UPDATE_INSTALLMENT_PLAN_GROUP_REQUEST,
	UPDATE_INSTALLMENT_PLAN_GROUP_SUCCESS,
	UPDATE_INSTALLMENT_PLAN_GROUP_FAILURE,
	DELETE_INSTALLMENT_PLAN_GROUP_REQUEST,
	DELETE_INSTALLMENT_PLAN_GROUP_SUCCESS,
	DELETE_INSTALLMENT_PLAN_GROUP_FAILURE,
	CREATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST,
	CREATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS,
	CREATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE,
	GET_INSTALLMENT_PLAN_FOR_GROUP_REQUEST,
	GET_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS,
	GET_INSTALLMENT_PLAN_FOR_GROUP_FAILURE,
	UPDATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST,
	UPDATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS,
	UPDATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE,
} from '../types';
import {
	transformedPaymentMethodGroupList,
	transformedPaymentMethodDetails,
	transformedInstallmentPlanDetails,
	transformedBankChannelDetails,
	transformedBrandInstallmentPlanGroupDetails,
	transformedCCForeverPlanDetails,
	transformedInstallmentPlanList,
} from '../../assets/js/transform/payments';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			data: [],
			isLoading: false,
		},
		paymentGroup: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		paymentMethod: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		installment: {
			isLoading: false,
			data: transformedInstallmentPlanList(),
			meta: transformedMeta(),
			error: false,
			create: {
				isCreating: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
		installmentChannel: {
			create: {
				isCreating: false,
			},
		},
		installmentBrand: {
			create: {
				isCreating: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
		installmentPlanForGroup: {
			create: {
				isCreating: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
		bankChannel: {
			create: {
				isCreating: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
		preOrder: {
			preOrderId: null,
			paymentList: [],
		},
		ccForeverPlan: {
			create: {
				isCreating: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
	},
	getters: {
		paymentMethodGroupList(state) {
			return transformedPaymentMethodGroupList(state.list.data);
		},
		paymentMethodGroupDetails(state) {
			return transformedPaymentMethodDetails(state.paymentGroup.data);
		},
		paymentMethodDetails(state) {
			return transformedPaymentMethodDetails(state.paymentMethod.data);
		},
		installmentDetails(state) {
			return state.installment.edit.data;
		},
		bankChannelDetails(state) {
			return transformedBankChannelDetails(state.bankChannel.edit.data);
		},
		CCForeverDetails(state) {
			return transformedCCForeverPlanDetails(state.ccForeverPlan.edit.data);
		},
	},
	mutations: {
		[GET_PAYMENT_METHOD_GROUPS_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_PAYMENT_METHOD_GROUPS_SUCCESS](state, { data }) {
			state.list.isLoading = false;
			state.list.data = data;
		},
		[GET_PAYMENT_METHOD_GROUPS_FAILURE](state) {
			state.list.isLoading = false;
		},
		[GET_PAYMENT_METHOD_GROUP_REQUEST](state) {
			state.paymentGroup.isLoading = true;
		},
		[GET_PAYMENT_METHOD_GROUP_SUCCESS](state, { data }) {
			state.paymentGroup.isLoading = false;
			state.paymentGroup.data = data;
		},
		[GET_PAYMENT_METHOD_GROUP_FAILURE](state) {
			state.paymentGroup.isLoading = false;
		},
		[GET_PRE_ORDER_PAYMENT_METHOD_GROUPS_REQUEST](state, preOrderId) {
			state.preOrder.preOrderId = preOrderId;
		},
		[SET_PRE_ORDER_PAYMENT_LIST](state, list) {
			state.preOrder.paymentList = list;
		},
		[UPDATE_PAYMENT_METHOD_GROUP_REQUEST](state) {
			state.paymentGroup.isUpdating = true;
		},
		[UPDATE_PAYMENT_METHOD_GROUP_SUCCESS](state) {
			state.paymentGroup.isUpdating = false;
		},
		[UPDATE_PAYMENT_METHOD_GROUP_FAILURE](state) {
			state.paymentGroup.isUpdating = false;
		},
		[GET_PAYMENT_METHOD_REQUEST](state) {
			state.paymentMethod.isLoading = true;
		},
		[GET_PAYMENT_METHOD_SUCCESS](state, { data }) {
			state.paymentMethod.isLoading = false;
			state.paymentMethod.data = data;
		},
		[GET_PAYMENT_METHOD_FAILURE](state) {
			state.paymentMethod.isLoading = false;
		},
		[UPDATE_PAYMENT_METHOD_REQUEST](state) {
			state.paymentMethod.isUpdating = true;
		},
		[UPDATE_PAYMENT_METHOD_SUCCESS](state) {
			state.paymentMethod.isUpdating = false;
		},
		[UPDATE_PAYMENT_METHOD_FAILURE](state) {
			state.paymentMethod.isUpdating = false;
		},
		[GET_INSTALLMENT_PLANS_REQUEST](state) {
			state.installment.isLoading = true;
		},
		[GET_INSTALLMENT_PLANS_SUCCESS](state, { data = [], meta = {} }) {
			state.installment.isLoading = false;
			state.installment.data = transformedInstallmentPlanList(data);
			state.installment.meta = transformedMeta(meta);
		},
		[GET_INSTALLMENT_PLANS_FAILURE](state) {
			state.installment.isLoading = false;
			state.installment.error = true;
		},
		[GET_INSTALLMENT_PLAN_REQUEST](state) {
			state.installment.edit.isLoading = true;
		},
		[GET_INSTALLMENT_PLAN_SUCCESS](state, { data = {} }) {
			state.installment.edit.isLoading = false;
			state.installment.edit.data = transformedInstallmentPlanDetails(data);
		},
		[GET_INSTALLMENT_PLAN_FAILURE](state) {
			state.installment.edit.isLoading = false;
			state.installment.edit.error = true;
		},
		[CREATE_INSTALLMENT_PLAN_REQUEST](state) {
			state.installment.create.isCreating = true;
		},
		[CREATE_INSTALLMENT_PLAN_SUCCESS](state) {
			state.installment.create.isCreating = false;
		},
		[CREATE_INSTALLMENT_PLAN_FAILURE](state) {
			state.installment.create.isCreating = false;
		},
		[UPDATE_INSTALLMENT_PLAN_REQUEST](state) {
			state.installment.edit.isUpdating = true;
		},
		[UPDATE_INSTALLMENT_PLAN_SUCCESS](state) {
			state.installment.edit.isUpdating = false;
		},
		[UPDATE_INSTALLMENT_PLAN_FAILURE](state) {
			state.installment.edit.isUpdating = false;
		},
		[DELETE_INSTALLMENT_PLAN_REQUEST]() { },
		[DELETE_INSTALLMENT_PLAN_SUCCESS]() { },
		[DELETE_INSTALLMENT_PLAN_FAILURE]() { },
		[GET_CC_FOREVER_PLAN_REQUEST](state) {
			state.ccForeverPlan.edit.isLoading = true;
		},
		[GET_CC_FOREVER_PLAN_SUCCESS](state, { data }) {
			state.ccForeverPlan.edit.isLoading = false;
			state.ccForeverPlan.edit.data = data;
		},
		[GET_CC_FOREVER_PLAN_FAILURE](state) {
			state.ccForeverPlan.edit.isLoading = false;
		},
		[CREATE_CC_FOREVER_PLAN_REQUEST](state) {
			state.ccForeverPlan.create.isCreating = true;
		},
		[CREATE_CC_FOREVER_PLAN_SUCCESS](state) {
			state.ccForeverPlan.create.isCreating = false;
		},
		[CREATE_CC_FOREVER_PLAN_FAILURE](state) {
			state.ccForeverPlan.create.isCreating = false;
		},
		[UPDATE_CC_FOREVER_PLAN_REQUEST](state) {
			state.ccForeverPlan.edit.isUpdating = true;
		},
		[UPDATE_CC_FOREVER_PLAN_SUCCESS](state) {
			state.ccForeverPlan.edit.isUpdating = false;
		},
		[UPDATE_CC_FOREVER_PLAN_FAILURE](state) {
			state.ccForeverPlan.edit.isUpdating = false;
		},
		[DELETE_CC_FOREVER_PLAN_REQUEST]() { },
		[DELETE_CC_FOREVER_PLAN_SUCCESS]() { },
		[DELETE_CC_FOREVER_PLAN_FAILURE]() { },
		[CREATE_BANK_CHANNEL_REQUEST](state) {
			state.bankChannel.create.isCreating = true;
		},
		[GET_BANK_CHANNEL_REQUEST](state) {
			state.bankChannel.edit.isLoading = true;
		},
		[GET_BANK_CHANNEL_SUCCESS](state, { data }) {
			state.bankChannel.edit.isLoading = false;
			state.bankChannel.edit.data = data;
		},
		[GET_BANK_CHANNEL_FAILURE](state) {
			state.bankChannel.edit.isLoading = false;
		},
		[CREATE_BANK_CHANNEL_SUCCESS](state) {
			state.bankChannel.create.isCreating = false;
		},
		[CREATE_BANK_CHANNEL_FAILURE](state) {
			state.bankChannel.create.isCreating = false;
		},
		[UPDATE_BANK_CHANNEL_REQUEST](state) {
			state.bankChannel.edit.isUpdating = true;
		},
		[UPDATE_BANK_CHANNEL_SUCCESS](state) {
			state.bankChannel.edit.isUpdating = false;
		},
		[UPDATE_BANK_CHANNEL_FAILURE](state) {
			state.bankChannel.edit.isUpdating = false;
		},
		[DELETE_BANK_CHANNEL_REQUEST]() { },
		[DELETE_BANK_CHANNEL_SUCCESS]() { },
		[DELETE_BANK_CHANNEL_FAILURE]() { },
		[CREATE_INSTALLMENT_PLAN_CHANNEL_REQUEST](state) {
			state.installment.create.isCreating = true;
		},
		[CREATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS](state) {
			state.installment.create.isCreating = false;
		},
		[CREATE_INSTALLMENT_PLAN_CHANNEL_FAILURE](state) {
			state.installment.create.isCreating = false;
		},
		[UPDATE_INSTALLMENT_PLAN_CHANNEL_REQUEST](state) {
			state.installment.edit.isUpdating = true;
		},
		[UPDATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS](state) {
			state.installment.edit.isUpdating = false;
		},
		[UPDATE_INSTALLMENT_PLAN_CHANNEL_FAILURE](state) {
			state.installment.edit.isUpdating = false;
		},
		[CREATE_INSTALLMENT_PLAN_GROUP_REQUEST](state) {
			state.installmentBrand.create.isCreating = true;
		},
		[CREATE_INSTALLMENT_PLAN_GROUP_SUCCESS](state) {
			state.installmentBrand.create.isCreating = false;
		},
		[CREATE_INSTALLMENT_PLAN_GROUP_FAILURE](state) {
			state.installmentBrand.create.isCreating = false;
		},
		[GET_INSTALLMENT_PLAN_GROUP_REQUEST](state) {
			state.installmentBrand.edit.isLoading = true;
		},
		[GET_INSTALLMENT_PLAN_GROUP_SUCCESS](state, { data }) {
			state.installmentBrand.edit.data = transformedBrandInstallmentPlanGroupDetails(data);
			state.installmentBrand.edit.isLoading = false;
		},
		[GET_INSTALLMENT_PLAN_GROUP_FAILURE](state) {
			state.installmentBrand.edit.isLoading = false;
		},
		[UPDATE_INSTALLMENT_PLAN_GROUP_REQUEST](state) {
			state.installmentBrand.edit.isUpdating = true;
		},
		[UPDATE_INSTALLMENT_PLAN_GROUP_SUCCESS](state) {
			state.installmentBrand.edit.isUpdating = false;
		},
		[UPDATE_INSTALLMENT_PLAN_GROUP_FAILURE](state) {
			state.installmentBrand.edit.isUpdating = false;
		},
		[DELETE_INSTALLMENT_PLAN_GROUP_REQUEST]() {},
		[DELETE_INSTALLMENT_PLAN_GROUP_SUCCESS]() {},
		[DELETE_INSTALLMENT_PLAN_GROUP_FAILURE]() {},
		[CREATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST](state) {
			state.installmentPlanForGroup.create.isCreating = true;
		},
		[CREATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS](state) {
			state.installmentPlanForGroup.create.isCreating = false;
		},
		[CREATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE](state) {
			state.installmentPlanForGroup.create.isCreating = false;
		},
		[GET_INSTALLMENT_PLAN_FOR_GROUP_REQUEST](state) {
			state.installmentPlanForGroup.edit.isLoading = true;
		},
		[GET_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS](state, { data }) {
			state.installmentPlanForGroup.edit.data = transformedInstallmentPlanDetails(data);
			state.installmentPlanForGroup.edit.isLoading = false;
		},
		[GET_INSTALLMENT_PLAN_FOR_GROUP_FAILURE](state) {
			state.installmentPlanForGroup.edit.isLoading = false;
		},
		[UPDATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST](state) {
			state.installmentPlanForGroup.edit.isUpdating = true;
		},
		[UPDATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS](state) {
			state.installmentPlanForGroup.edit.isUpdating = false;
		},
		[UPDATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE](state) {
			state.installmentPlanForGroup.edit.isUpdating = false;
		},
	},
	actions: {
		async getPaymentMethodGroups({ commit }, query) {
			try {
				commit(GET_PAYMENT_METHOD_GROUPS_REQUEST, query);
				const { data } = await getPaymentMethodGroupsAPI(query);
				commit(GET_PAYMENT_METHOD_GROUPS_SUCCESS, data);
			} catch (error) {
				commit(GET_PAYMENT_METHOD_GROUPS_FAILURE);
			}
		},
		async getPaymentMethodGroup({ commit }, id) {
			try {
				commit(GET_PAYMENT_METHOD_GROUP_REQUEST);
				const { data } = await getPaymentMethodGroupAPI(id);
				commit(GET_PAYMENT_METHOD_GROUP_SUCCESS, data);
			} catch (error) {
				commit(GET_PAYMENT_METHOD_GROUP_FAILURE);
			}
		},

		/**
		 * Action getPreOrderPaymentMethodGroups create for using instead of
		 * action getPaymentMethodGroups in pre-order create/edit ONLY,
		 * because in pre-order page has calling action getPaymentMethodGroups
		 * multiple times with same query/id. (because has payment in many section).
		 *
		 * So new action are creating for prevent call action multiple times,
		 * If user called before system will skip call API
		 */
		async getPreOrderPaymentMethodGroups({ commit, state, dispatch }, { preOrderId, query }) {
			if (state.preOrder.preOrderId !== preOrderId) {
				commit(GET_PRE_ORDER_PAYMENT_METHOD_GROUPS_REQUEST, preOrderId);
				await dispatch('getPaymentMethodGroups', query);
			}
		},
		setPreOrderPaymentList({ commit }, list) {
			commit(SET_PRE_ORDER_PAYMENT_LIST, list);
		},

		async updatePaymentMethodGroup({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_PAYMENT_METHOD_GROUP_REQUEST);

				const { data } = await updatePaymentMethodGroupAPI(id, params);

				commit(UPDATE_PAYMENT_METHOD_GROUP_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				dispatch('getPaymentMethodGroup', id);
			} catch (error) {
				commit(UPDATE_PAYMENT_METHOD_GROUP_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async updatePaymentMethod({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_PAYMENT_METHOD_REQUEST);

				const { data } = await updatePaymentMethodAPI(id, params);

				commit(UPDATE_PAYMENT_METHOD_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_PAYMENT_METHOD_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async getPaymentMethod({ commit }, id) {
			try {
				commit(GET_PAYMENT_METHOD_REQUEST);
				const { data } = await getPaymentMethodAPI(id);
				commit(GET_PAYMENT_METHOD_SUCCESS, data);
			} catch (error) {
				commit(GET_PAYMENT_METHOD_FAILURE);

				throw error;
			}
		},
		async getInstallmentPlans({ commit }, { methodId, params }) {
			try {
				commit(GET_INSTALLMENT_PLANS_REQUEST);
				const { data } = await getInstallmentPlansAPI(methodId, params);
				commit(GET_INSTALLMENT_PLANS_SUCCESS, data);
			} catch (error) {
				commit(GET_INSTALLMENT_PLANS_FAILURE);
				throw error;
			}
		},
		async getInstallmentPlan({ commit }, { methodId, planId }) {
			try {
				commit(GET_INSTALLMENT_PLAN_REQUEST);
				const { data } = await getInstallmentPlanAPI(methodId, planId);
				commit(GET_INSTALLMENT_PLAN_SUCCESS, data);
			} catch (error) {
				commit(GET_INSTALLMENT_PLAN_FAILURE);

				throw error;
			}
		},
		async createInstallmentPlan({ commit, dispatch }, { methodId, params }) {
			try {
				commit(CREATE_INSTALLMENT_PLAN_REQUEST);
				await createInstallmentPlanAPI(methodId, params);
				commit(CREATE_INSTALLMENT_PLAN_SUCCESS);
				dispatch(
					'toast/showCreateSuccessToast',
					'installment',
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_INSTALLMENT_PLAN_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async updateInstallmentPlan({ commit, dispatch }, { methodId, planId, params }) {
			try {
				commit(UPDATE_INSTALLMENT_PLAN_REQUEST);
				await updateInstallmentPlanAPI(methodId, planId, params);
				commit(UPDATE_INSTALLMENT_PLAN_SUCCESS);
				dispatch(
					'toast/showUpdateSuccessToast',
					'installment',
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_INSTALLMENT_PLAN_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async deleteInstallmentPlan({ commit, dispatch }, { methodId, planId }) {
			try {
				commit(DELETE_INSTALLMENT_PLAN_REQUEST);

				await deleteInstallmentPlanAPI(methodId, planId);
				commit(DELETE_INSTALLMENT_PLAN_SUCCESS);

				dispatch(
					'toast/showDeleteSuccessToast',
					'Installment plan',
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_INSTALLMENT_PLAN_FAILURE);

				dispatch(
					'toast/showDeleteErrorToast',
					'Installment plan',
					{ root: true },
				);
			}
		},
		async getBankChannel({ commit }, { methodId, channelId }) {
			try {
				commit(GET_BANK_CHANNEL_REQUEST);
				const { data } = await getBankChannelAPI(methodId, channelId);
				commit(GET_BANK_CHANNEL_SUCCESS, data);
			} catch (error) {
				commit(GET_BANK_CHANNEL_FAILURE);

				throw error;
			}
		},
		async createBankChannel({ commit, dispatch }, { methodId, params }) {
			try {
				commit(CREATE_BANK_CHANNEL_REQUEST);
				await createBankChannelAPI(methodId, params);
				commit(CREATE_BANK_CHANNEL_SUCCESS);

				dispatch(
					'toast/showCreateSuccessToast',
					'channel',
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_BANK_CHANNEL_FAILURE);

				dispatch(
					'toast/showCreateErrorToast',
					null,
					{ root: true },
				);

				throw error;
			}
		},
		async updateBankChannel({ commit, dispatch }, { methodId, channelId, params }) {
			try {
				commit(UPDATE_BANK_CHANNEL_REQUEST);
				await updateBankChannelAPI(methodId, channelId, params);
				commit(UPDATE_BANK_CHANNEL_SUCCESS);
				dispatch(
					'toast/showUpdateSuccessToast',
					'channel',
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_BANK_CHANNEL_FAILURE);

				dispatch(
					'toast/showUpdateErrorToast',
					null,
					{ root: true },
				);

				throw error;
			}
		},
		async deleteBankChannel({ commit, dispatch }, { methodId, channelId }) {
			try {
				commit(DELETE_BANK_CHANNEL_REQUEST);

				await deleteBankChannelAPI(methodId, channelId);
				commit(DELETE_BANK_CHANNEL_SUCCESS);

				dispatch(
					'toast/showDeleteSuccessToast',
					'channel',
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_BANK_CHANNEL_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage,
						header: i18n.t('global.errorMessageTitleDelete'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async createInstallmentPlanChannel({ commit, dispatch }, { methodId, channelId, params }) {
			try {
				commit(CREATE_INSTALLMENT_PLAN_CHANNEL_REQUEST);
				await createInstallmentPlanChannelAPI(methodId, channelId, params);
				commit(CREATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS);
				dispatch(
					'toast/showCreateSuccessToast',
					'installment',
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_INSTALLMENT_PLAN_CHANNEL_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async updateInstallmentPlanChannel({ commit, dispatch }, { methodId, channelId, planId, params }) {
			try {
				commit(UPDATE_INSTALLMENT_PLAN_CHANNEL_REQUEST);
				await updateInstallmentPlanChannelAPI(methodId, channelId, planId, params);
				commit(UPDATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS);
				dispatch(
					'toast/showUpdateSuccessToast',
					'installment',
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_INSTALLMENT_PLAN_CHANNEL_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async createInstallmentPlanGroup({ commit, dispatch }, { methodId, params }) {
			try {
				commit(CREATE_INSTALLMENT_PLAN_GROUP_REQUEST);
				await createInstallmentPlanGroupAPI(methodId, {
					name: params.name,
					config_by: params.configBy,
					config_data: params.configData,
				});
				commit(CREATE_INSTALLMENT_PLAN_GROUP_SUCCESS);
				dispatch(
					'toast/showCreateSuccessToast',
					'installment',
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_INSTALLMENT_PLAN_GROUP_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async getInstallmentPlanGroup({ commit }, { methodId, brandId }) {
			try {
				commit(GET_INSTALLMENT_PLAN_GROUP_REQUEST);
				const { data } = await getInstallmentPlanGroupAPI(methodId, brandId);
				commit(GET_INSTALLMENT_PLAN_GROUP_SUCCESS, data);
			} catch (error) {
				commit(GET_INSTALLMENT_PLAN_GROUP_FAILURE);

				throw error;
			}
		},
		async updateInstallmentPlanGroup({ commit, dispatch }, { methodId, brandId, params }) {
			try {
				commit(UPDATE_INSTALLMENT_PLAN_GROUP_REQUEST);

				await updateInstallmentPlanGroupAPI(
					methodId,
					brandId,
					{
						name: params.name,
						config_by: params.configBy,
						config_data: params.configData,
					},
				);
				commit(UPDATE_INSTALLMENT_PLAN_GROUP_SUCCESS);

				dispatch(
					'toast/showUpdateSuccessToast',
					'installment plan',
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_INSTALLMENT_PLAN_GROUP_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async deleteInstallmentPlanGroup({ commit, dispatch }, { methodId, brandId }) {
			try {
				commit(DELETE_INSTALLMENT_PLAN_GROUP_REQUEST);

				await deleteInstallmentPlanGroupAPI(methodId, brandId);
				commit(DELETE_INSTALLMENT_PLAN_GROUP_SUCCESS);

				dispatch(
					'toast/showDeleteSuccessToast',
					'installment plan',
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_INSTALLMENT_PLAN_GROUP_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage,
						header: i18n.t('global.errorMessageTitleDelete'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async createInstallmentPlanForGroup({ commit, dispatch }, { methodId, brandId, params }) {
			try {
				commit(CREATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST);
				await createInstallmentPlanForGroupAPI(methodId, brandId, params);
				commit(CREATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS);
				dispatch(
					'toast/showCreateSuccessToast',
					'installment plan',
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async getInstallmentPlanForGroup({ commit }, { methodId, brandId, planId }) {
			try {
				commit(GET_INSTALLMENT_PLAN_FOR_GROUP_REQUEST);
				const { data } = await getInstallmentPlanForGroupAPI(methodId, brandId, planId);
				commit(GET_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS, data);
			} catch (error) {
				commit(GET_INSTALLMENT_PLAN_FOR_GROUP_FAILURE);

				throw error;
			}
		},
		async updateInstallmentPlanForGroup({ commit, dispatch }, { methodId, brandId, planId, params }) {
			try {
				commit(UPDATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST);
				await updateInstallmentPlanForGroupAPI(methodId, brandId, planId, params);
				commit(UPDATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS);
				dispatch(
					'toast/showUpdateSuccessToast',
					'installment plan',
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async getCCForeverPlan({ commit }, { methodId, planId }) {
			try {
				commit(GET_CC_FOREVER_PLAN_REQUEST);
				const { data } = await getForeverPlanAPI(methodId, planId);
				commit(GET_CC_FOREVER_PLAN_SUCCESS, data);
			} catch (error) {
				commit(GET_CC_FOREVER_PLAN_FAILURE);

				throw error;
			}
		},
		async createCCForeverPlan({ commit, dispatch }, { methodId, params }) {
			try {
				commit(CREATE_CC_FOREVER_PLAN_REQUEST);
				await createForeverPlanAPI(methodId, params);
				commit(CREATE_CC_FOREVER_PLAN_SUCCESS);

				dispatch(
					'toast/showCreateSuccessToast',
					'CC forever plan',
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_CC_FOREVER_PLAN_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async updateCCForeverPlan({ commit, dispatch }, { methodId, planId, params }) {
			try {
				commit(UPDATE_CC_FOREVER_PLAN_REQUEST);
				await updateForeverPlanAPI(methodId, planId, params);
				commit(UPDATE_CC_FOREVER_PLAN_SUCCESS);

				dispatch(
					'toast/showUpdateSuccessToast',
					'CC forever plan',
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_CC_FOREVER_PLAN_FAILURE);

				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage || i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async deleteCCForeverPlan({ commit, dispatch }, { methodId, planId }) {
			try {
				commit(DELETE_CC_FOREVER_PLAN_REQUEST);
				await deleteForeverPlanAPI(methodId, planId);
				commit(DELETE_CC_FOREVER_PLAN_SUCCESS);

				dispatch(
					'toast/showDeleteSuccessToast',
					'CC forever plan',
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_CC_FOREVER_PLAN_FAILURE);

				dispatch(
					'toast/showDeleteErrorToast',
					'CC forever plan',
					{ root: true },
				);
			}
		},
	},
};
