export const PRODUCT_LABEL_TYPES = {
	primary: 'primary',
	secondary: 'secondary',
	frame: 'frame',
	default: 'default',
};

export const PRODUCT_LABEL_SUB_TYPES = {
	freeGift: 'free_gift',
	zeroInstallment: 'zero_installment',
	newProduct: 'new_product',
	collectInOneHourPrimary: 'collect_in_one_hour_primary',
	collectInOneHourSecondary: 'collect_in_one_hour_secondary',
	normal: 'normal',
};

export const PRODUCT_LABEL_SUB_TYPE_LABELS = {
	freeGift: 'free gift',
	zeroInstallment: 'installment',
	newProduct: 'new product',
	collectInOneHourPrimary: 'collect in one hour primary',
	collectInOneHourSecondary: 'collect in one hour secondary',
};

export const PRODUCT_LABEL_STATUSES = {
	active: { name: 'Active', value: 'active' },
	inactive: { name: 'Inactive', value: 'inactive' },
};

export const VISIBILITY_ITEM_MAPPER = {
	isListVisible: 'Product list',
	isDetailVisible: 'Product details',
	isSearchVisible: 'Search',
};

export const PRODUCT_LABEL_TABS = ['primary', 'secondary', 'frame', 'default'];
export const ERROR_REMOVE_DEFAULT_LABEL_KEY = 'label_is_set_in_default_label';
