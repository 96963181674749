import {
	PAYMENT_ZERO_CHECKOUT_SLUG,
	INSTALLMENT_PLAN_PRODUCT_BY_TYPE,
	PAYMENT_BILL_PAYMENT_SLUG,
	PAYMENT_COUNTER_PAYMENT_SLUG,
} from '../../../enums/payments';
import {
	INSTALLMENT_PLAN_TITLE,
	INSTALLMENT_PLAN_COLOR,
} from '../../../enums/ktcInstallmentPlans';
import { PREORDER_PAYMENT_METHOD_KEY } from '../../../enums/preorders';
import {
	priceFromAPI,
	pathOr,
	getThumbnailFromProduct,
	datetimeFormat,
	convertDateFormatToDateObject,
} from '../helpers';
import { transformedProductList } from './products';
import { transformedMedia } from './mediaLibrary';

/**
 * transformedBankChannelDetails
 * @param {Object} bank channel object
 * @returns {Object} Object of channelsinfo
 */
export const transformedBankChannelDetails = (channel = {}) => ({
	id: channel.id || null,
	nameEN: channel.name_en || null,
	nameTH: channel.name_th || null,
	logo: Array.isArray(channel.files) && channel.files.length ? transformedMedia(channel.files[0]) : null,
	thumbnail: getThumbnailFromProduct(channel),
	isActive: !!(channel.is_active || null),
	// eslint-disable-next-line no-use-before-define
	installmentPlans: transformedInstallmentPlanList(channel.installment_plans),
});

/**
 * transformedBankChannelList
 * @param {Array} bank channels array
 * @returns {Array} Array of channels info
 */
export const transformedBankChannelList = (channels = []) => {
	return channels.map(transformedBankChannelDetails);
};

/**
 * transformedPaymentMethodGroupList
 * @param {Object} payment list data
 * @returns {Array} array of payment list
 */
export const transformedPaymentMethodGroupList = (data = []) => {
	const notAllowPaymentMethods = [
		PAYMENT_ZERO_CHECKOUT_SLUG,
	];
	const list = data
		.filter((item) => !notAllowPaymentMethods.includes(item.slug))
		.map((payment) => ({ id: pathOr(null, ['id'])(payment), name: payment.name_en }));
	return list;
};

/**
 * transformedMethodSubGroups
 * @param {Array} array of subgroup
 * @returns {Array} array of new subgroup details
 */
export const transformedMethodSubGroups = (groups = []) => groups.map((group) => ({
	id: group.id,
	nameEN: group.name_en || null,
	nameTH: group.name_th || null,
	paymentChannels: transformedBankChannelList(group.payment_methods || []),
}));

/**
 * transformedPaymentMethodDetails
 * @param {Object} payment data from api
 * @returns {Object} payment info
 */
export const transformedPaymentMethodDetails = (data = {}) => {
	const paymentMethodSubGroups = transformedMethodSubGroups(data.payment_method_subgroups || []);
	const customerGroups = data.customer_groups
		? data.customer_groups.map((group) => ({ id: group.id }))
		: [];

	let logo = null;
	if (data.files?.[0]) {
		logo = transformedMedia(data.files[0]);
	} else if (data.payment_methods?.[0].files?.[0]) {
		logo = transformedMedia(data.payment_methods[0].files[0]);
	} else {
		logo = null;
	}

	return {
		id: data.id || null,
		name: data.name_en || null,
		nameEN: data.name_en || null,
		nameTH: data.name_th || null,
		agent: data.agent || null,
		logo,
		gateway: data.gateway || null,
		slug: data.slug || null,
		group: data.payment_method_group || null,
		isActive: !!(data.is_active || null),
		expiryPeriodHr: data.expiry_period_hr || null,
		isRepaymentAllowed: !!(data.is_repayment_allowed || null),
		rate: data.rate || null,
		ktcProductId: data.ktc_product_id || null,
		minOrder: priceFromAPI(data.min_order) || null,
		maxOrder: priceFromAPI(data.max_order) || null,
		contentTH: data.content_th || null,
		contentEN: data.content_en || null,
		categories: data.categories || [],
		paymentMethods: data.payment_methods || [],
		paymentChannels: transformedBankChannelList(data.payment_channels || []),
		paymentMethodSubGroups,
		excludeProducts: transformedProductList(data.excluded_products || []),
		customerGroups,
		// eslint-disable-next-line no-use-before-define
		installmentPlans: transformedInstallmentPlanList(data.installment_plans),
		// eslint-disable-next-line no-use-before-define
		installmentPlanGroups: transformedInstallmentPlanGroupList(data.installment_plan_groups),
		// eslint-disable-next-line no-use-before-define
		ccForeverPlans: transformedCCForeverPlanList(data.cc_forever_plans),
	};
};

/**
 * transformedInstallmentPlanDetails
 * @param {Object} installment plan data from api
 * @returns {Object} Object of installment plan
 */
export const transformedInstallmentPlanDetails = (plan = {}) => {
	const minOrder = pathOr(null, ['min_order_amount'])(plan);
	const maxOrder = pathOr(null, ['max_order_amount'])(plan);
	const paymentMethod = pathOr({}, ['payment_method'])(plan);
	const brandCode = pathOr(null, ['custom_attributes', 'brand_code'])(plan);
	const campaignCode = pathOr(null, ['custom_attributes', 'campaign_code'])(plan);
	const supplierId = pathOr(null, ['custom_attributes', 'supplier_id'])(plan);
	const absorptionType = pathOr(null, ['custom_attributes', 'absorption_type'])(plan);
	const startDate = plan.started_at ? convertDateFormatToDateObject(plan.started_at) : null;
	const startTime = plan.started_at ? datetimeFormat(plan.started_at, 'HH:mm') : '00:00';
	const endDate = plan.ended_at ? convertDateFormatToDateObject(plan.ended_at) : null;
	const endTime = plan.ended_at ? datetimeFormat(plan.ended_at, 'HH:mm') : '00:00';
	const periodValue = plan.period_status || null;
	/* eslint-enable camelcase */
	const periodStatus = {
		title: INSTALLMENT_PLAN_TITLE.PERIODS_STATUSES[periodValue],
		color: INSTALLMENT_PLAN_COLOR.PERIODS_STATUSES[periodValue],
	};

	return {
		id: plan.id || null,
		name: plan.name || null,
		availableSkus: plan.available_product_count || 0,
		interestRate: plan.interest_rate || 0,
		minOrderAmount: minOrder === null ? null : priceFromAPI(minOrder),
		maxOrderAmount: maxOrder === null ? null : priceFromAPI(maxOrder),
		paymentChannelId: plan.payment_channel_id || null,
		paymentMethod: transformedPaymentMethodDetails(paymentMethod),
		period: plan.period || 0,
		startDate,
		startTime,
		endDate,
		endTime,
		periodStatus,
		periodValue,
		// Duplicated ID for delete installment plan on list page
		planId: plan.id || null,
		// Custom attribute for
		customAttributes: {
			// BAY setting
			brandCode,
			campaignCode,

			// KTC setting
			supplierId,
			absorptionType,
		},
	};
};

/**
 * transformedCCForeverPlanDetails
 * @param {Object} forever plan data from api
 * @returns {Object} Object of forever plan
 */
export const transformedCCForeverPlanDetails = (plan = {}) => {
	const paymentMethod = pathOr({}, ['payment_method'])(plan);

	return {
		id: plan.plan_id || null,
		name: plan.plan_name || null,
		rate: plan.rate || null,
		discountPercent: plan.discount_percent || 0,
		paymentMethod: transformedPaymentMethodDetails(paymentMethod),
		ktcProductId: plan.ktc_product_id || null,
	};
};

/**
 * transformedCCForeverPlanList
 * @param {Array} forever plans data from api
 * @returns {Array} Array of forever plan
 */
export const transformedCCForeverPlanList = (plans = []) => {
	return plans.map(transformedCCForeverPlanDetails);
};

/**
 * transformedInstallmentPlanGroupDetails
 * @param {Object} installment plan group data from api
 * @returns {Object} Object of installment plan group
 */
export const transformedInstallmentPlanGroupDetails = (plan = {}) => {
	const id = plan.id || null;
	const name = plan.name || null;
	const availableProductCount = plan.available_product_count || 0;
	const installmentPlanCount = plan.installment_plan_count || 0;
	const paymentMethodId = plan.payment_method_id || null;

	return {
		id,
		name,
		availableProductCount,
		installmentPlanCount,
		paymentMethodId,
	};
};


/**
 * transformedInstallmentPlanList
 * @param {Array} installment plans data from api
 * @returns {Array} Array of installment plan
 */
export const transformedInstallmentPlanList = (plans = []) => {
	return plans.map(transformedInstallmentPlanDetails);
};

/**
 * transformedInstallmentPlanGroupList
 * @param {Array} installment plans group data from api
 * @returns {Array} Array of installment plan group
 */
export const transformedInstallmentPlanGroupList = (plans = []) => {
	return plans.map(transformedInstallmentPlanGroupDetails);
};

/**
 * transformedBrandInstallmentPlanGroupDetails
 * @param {Object} brand installment plan group data from api
 * @returns {Object} Object of brand installment plan group
 */
export const transformedBrandInstallmentPlanGroupDetails = (data = {}) => {
	const id = data.id || null;
	const name = data.name || null;
	const paymentMethodId = data.payment_method_id || null;
	const configBy = data.config_by || null;
	const installmentPlans = transformedInstallmentPlanList(data.installment_plans);
	let configData = data.config_data || [];
	configData = configBy === INSTALLMENT_PLAN_PRODUCT_BY_TYPE.BRAND
		? configData.map((item) => ({ id: item.brand_id, name: item.brand_name }))
		: configData;

	return {
		id,
		name,
		paymentMethodId,
		installmentPlans,
		configBy,
		configData,
	};
};

export const transformSelectedPaymentList = (paymentMethod, isGroup = false, paymentMethodSettings = []) => {
	// Check if dont have one of them
	if (!paymentMethod) {
		return null;
	}
	const key = isGroup ? 'payment_method_group_id' : 'payment_method_id';

	const isSelected = !!paymentMethodSettings.find((method) => (method[key] === paymentMethod.id));

	return {
		...paymentMethod,
		isSelected,
	};
};

export const transformSelectedPreOrderPaymentList = (paymentMethod, isGroup = false, paymentMethodSettings = []) => {
	// Check if dont have one of them
	if (!paymentMethod) {
		return null;
	}
	const key = isGroup ? 'payment_method_group_id' : 'payment_method_id';

	const { payment_type: paymentTypes = [] } = paymentMethodSettings.find((method) => (method[key] === paymentMethod.id)) || {};

	return {
		...paymentMethod,
		selected_full_price: paymentTypes.includes(PREORDER_PAYMENT_METHOD_KEY.FULL_PRICE),
		selected_reserve_price: paymentTypes.includes(PREORDER_PAYMENT_METHOD_KEY.RESERVE_PRICE),
		selected_remaining_price: paymentTypes.includes(PREORDER_PAYMENT_METHOD_KEY.REMAINING_PRICE),
	};
};

const paymentMethodHasChildSlugs = [
	PAYMENT_BILL_PAYMENT_SLUG,
	PAYMENT_COUNTER_PAYMENT_SLUG,
];

/**
 *
 * @param {*} paymentMethodGroup
 * @param {*} selectedPaymentList
 * @param {*} paymentMethodSettings
 * @returns
 */
export const transformPaymentMethodSelector = (paymentMethodGroup = {}, selectedPaymentList, paymentMethodSettings = []) => {
	// Check if dont have one of them
	if (!paymentMethodGroup || !selectedPaymentList) {
		return [];
	}
	const { id, name, slug, paymentMethods = [] } = paymentMethodGroup;

	// Check method is method group
	if (paymentMethodHasChildSlugs.includes(slug)) {
		return selectedPaymentList(
			{
				id,
				name,
				methods: null,
			},
			true,
			paymentMethodSettings,
		);
	}

	// If sub payment method is null or only one
	// It can assume that it's the main group without sub payment method
	const methods = paymentMethods && paymentMethods.length > 1
		? paymentMethods.map((method) => {
			return selectedPaymentList(
				{
					id: method.id,
					name: method.name_en,
				},
				false,
				paymentMethodSettings,
			);
		})
		: null;

	return selectedPaymentList(
		{
			id,
			name,
			methods,
		},
		true,
		paymentMethodSettings,
	);
};
