import { i18n } from '@/i18n';
import {
	getSubHeaderAPI,
	putSubHeaderAPI,
} from '../../services/api/subHeader.api';
import {
	GET_SUB_HEADERS_REQUEST,
	GET_SUB_HEADERS_SUCCESS,
	GET_SUB_HEADERS_FAILURE,

	UPDATE_SUB_HEADERS_REQUEST,
	UPDATE_SUB_HEADERS_SUCCESS,
	UPDATE_SUB_HEADERS_FAILURE,
} from '../types';
import { transformedSubHeader } from '../../assets/js/transform/subHeaders';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			error: false,
			data: {},
		},
	},

	getters: {
		getSubHeaderList(state) {
			return state.list.data.map(transformedSubHeader);
		},
	},

	mutations: {
		[GET_SUB_HEADERS_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_SUB_HEADERS_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
			};
		},
		[GET_SUB_HEADERS_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[UPDATE_SUB_HEADERS_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_SUB_HEADERS_SUCCESS](state, data) {
			state.edit = {
				...state.edit,
				isUpdating: false,
				error: false,
				data,
			};
		},
		[UPDATE_SUB_HEADERS_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
				error: true,
			};
		},
	},
	actions: {
		async getSubHeader({ commit }) {
			try {
				commit(GET_SUB_HEADERS_REQUEST);
				const { data } = await getSubHeaderAPI();
				commit(GET_SUB_HEADERS_SUCCESS, data);
			} catch (error) {
				commit(GET_SUB_HEADERS_FAILURE);
			}
		},
		async updateSubHeader({ commit, dispatch }, { type, params }) {
			try {
				commit(UPDATE_SUB_HEADERS_REQUEST);
				const { data } = await putSubHeaderAPI(type, params);
				commit(UPDATE_SUB_HEADERS_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getSubHeader', params);
			} catch (error) {
				commit(UPDATE_SUB_HEADERS_FAILURE, error);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},
	},
};
