export const PAGE_LEVEL = Object.freeze({
	ONE: 'ONE',
	TWO: 'TWO',
});

export const PAGE_LEVEL_OPTIONS = [
	{ value: PAGE_LEVEL.ONE, label: 'Level 1' },
	{ value: PAGE_LEVEL.TWO, label: 'Level 2' },
];

export const CONFIRM_DELETE_TEXT = 'REMOVE';
export const CONTACT_US_SLUG = 'contact-us';
