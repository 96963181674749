<template>
	<div class="action-panel-footer">
		<div :class="contentClass">
			<CRow>
				<CCol
					sm="12"
					class="d-flex justify-content-end align-items-center"
				>
					<div v-if="isShowRemove" class="d-flex align-items-center flex-fill">
						<a
							:disabled="disabledRemove"
							href="#"
							class="btn-remove"
							data-test-id="remove"
							@click.prevent="handleRemove"
						>
							{{ removeText }}
						</a>
					</div>
					<CButton
						:disabled="disabledCancel"
						color="tertiary"
						class="transparent mr-3"
						@click.prevent="handleCancel"
					>
						Cancel
					</CButton>

					<BaseSaveButton
						:text="confirmText"
						:disabled="disabledConfirm"
						@click="handleConfirm"
					/>
				</CCol>
			</CRow>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseActionPanelStickyFooter',
	props: {
		contentClass: {
			type: String,
			default: 'main-wrapper',
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		removeText: {
			type: String,
			default: null,
		},
		disabledConfirm: {
			type: Boolean,
			default: false,
		},
		disabledCancel: {
			type: Boolean,
			default: false,
		},
		disabledRemove: {
			type: Boolean,
			default: false,
		},
		buttonConfirmText: {
			type: String,
			default: 'Create',
		},
		buttonEditConfirmText: {
			type: String,
			default: 'Save changes',
		},
	},
	computed: {
		isShowRemove() {
			return this.removeText;
		},
		confirmText() {
			return this.isEdit ? this.buttonEditConfirmText : this.buttonConfirmText;
		},
	},
	methods: {
		handleRemove() {
			this.$emit('onRemove');
		},
		handleCancel() {
			this.$emit('onCancel');
		},
		handleConfirm() {
			this.$emit('onConfirm');
		},
	},
};
</script>

<style lang="scss" scoped>
	.action-panel-footer {
		position: fixed;
		left: rem(56);
		right: 0;
		bottom: 0;
		padding: rem(12) 0;
		box-shadow: 0 1px 2px 0 $color-gray-07, 0 4px 16px 0 $color-gray-15;
		background-color: $color-white;
		z-index: 9;

		@media (max-width: 991.98px) {
			left: 0;
		}

		::v-deep button {
			min-width: rem(80);
		}
	}
</style>
