import dayjs from 'dayjs';
import { pathOr, dateFormat } from '../helpers';
import { PRODUCT_LABEL_STATUSES } from '../../../enums/productLabels';

export const transformedProductLabel = (label = {}) => {
	const isActive = label.is_active || 0;
	const status = isActive === 1
		? PRODUCT_LABEL_STATUSES.active
		: PRODUCT_LABEL_STATUSES.inactive;

	return {
		id: label.id || null,
		name: label.name || null,
		type: label.type || null,
		subType: label.sub_type || null,
		order: label.order || null,
		isActive: label.is_active || 0,
		status,
		skus: label.skus || [],
		thumbnail: pathOr(null, ['medium', 'urls', 'thumbnail'])(label),
		expiredStartDate: label.start_at ? dayjs(label.start_at).toDate() : null,
		expiredStartTime: label.start_at ? dateFormat(label.start_at, 'HH:mm') : null,
		expiredEndDate: label.end_at ? dayjs(label.end_at).toDate() : null,
		expiredEndTime: label.end_at ? dateFormat(label.end_at, 'HH:mm') : null,
	};
};

export const transformedProductLabels = (labels = []) => {
	const data = labels.map((label) => transformedProductLabel(label));

	return data;
};
