import httpClient from './httpClient';

export const ORDERS_ENDPOINT = '/backoffice/orders';
export const CANCEL_ORDERS_ENDPOINT = '/backoffice/order-cancelation-reasons';

export const getOrdersAPI = (params = {}) => httpClient.get(`${ORDERS_ENDPOINT}`, { params });
export const getOrderAPI = (id) => httpClient.get(`${ORDERS_ENDPOINT}/${id}`);
export const updateOrderAPI = (id, params = {}) => httpClient.put(`${ORDERS_ENDPOINT}/${id}`, params);
export const updateOrderPaymentExpireAPI = (id, params = {}) => httpClient.put(`${ORDERS_ENDPOINT}/${id}/payment-expirations`, params);
export const createOrderAddressAPI = (orderId, params) => httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/order-addresses`, params);
export const createOrderRemarkAPI = (orderId, params) => httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/remarks`, params);
export const sendOrderEmailAPI = (orderId) => httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/notify/email`);
export const sendOrderSmsAPI = (orderId) => httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/notify/sms`);
export const getCancelOrderAPI = () => httpClient.get(`${CANCEL_ORDERS_ENDPOINT}`);
export const postCancelOrderAPI = (orderId, params = {}) => httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/cancel`, params);
export const postOrderReportExport = (params = {}) => httpClient.post(`${ORDERS_ENDPOINT}/reports/export`, params);