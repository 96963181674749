// Toast
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

// Addresses
export const FETCH_THAILAND_ADDRESS_REQUEST = 'FETCH_THAILAND_ADDRESS_REQUEST';
export const FETCH_THAILAND_ADDRESS_SUCCESS = 'FETCH_THAILAND_ADDRESS_SUCCESS';
export const FETCH_THAILAND_ADDRESS_FAILURE = 'FETCH_THAILAND_ADDRESS_FAILURE';

// Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CLEAR_ERROR = 'LOGIN_CLEAR_ERROR';

export const LOGOUT = 'LOGOUT';

// Profile
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const SET_USERNAME = 'SET_USERNAME';

// User Management
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_CLEAR_ERROR = 'CREATE_USER_CLEAR_ERROR';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_REQUEST';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_CLEAR_ERROR = 'UPDATE_USER_CLEAR_ERROR';

// Categories
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_ALL_CATEGORY = 'SELECT_ALL_CATEGORY';

// Sort categories
export const SORT_CATEGORIES_REQUEST = 'SORT_CATEGORIES_REQUEST';
export const SORT_CATEGORIES_SUCCESS = 'SORT_CATEGORIES_SUCCESS';
export const SORT_CATEGORIES_FAILURE = 'SORT_CATEGORIES_FAILURE';

// Category
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';
export const RESET_CATEGORY = 'RESET_CATEGORY';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';
export const CREATE_CATEGORY_CLEAR_ERROR = 'CREATE_CATEGORY_CLEAR_ERROR';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';
export const UPDATE_CATEGORY_CLEAR_ERROR = 'UPDATE_CATEGORY_CLEAR_ERROR';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
export const DELETE_CATEGORY_CLEAR_ERROR = 'DELETE_CATEGORY_CLEAR_ERROR';

export const PUT_ATTRIBUTE_STATUS_REQUEST = 'PUT_ATTRIBUTE_STATUS_REQUEST';
export const PUT_ATTRIBUTE_STATUS_SUCCESS = 'PUT_ATTRIBUTE_STATUS_SUCCESS';
export const PUT_ATTRIBUTE_STATUS_FAILURE = 'PUT_ATTRIBUTE_STATUS_FAILURE';
export const TOGGLE_ATTRIBUTE_STATUS = 'TOGGLE_ATTRIBUTE_STATUS';

// Customer
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const GET_CUSTOMER_LIST_REQUEST = 'GET_CUSTOMER_LIST_REQUEST';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const RESEND_VERIFICATION_LINK_REQUEST = 'RESEND_VERIFICATION_LINK_REQUEST';
export const RESEND_VERIFICATION_LINK_SUCCESS = 'RESEND_VERIFICATION_LINK_SUCCESS';
export const RESEND_VERIFICATION_LINK_FAILURE = 'RESEND_VERIFICATION_LINK_FAILURE';

// Customer Group
export const GET_CUSTOMER_GROUP_REQUEST = 'GET_CUSTOMER_GROUP_REQUEST';
export const GET_CUSTOMER_GROUP_SUCCESS = 'GET_CUSTOMER_GROUP_SUCCESS';
export const GET_CUSTOMER_GROUP_FAILURE = 'GET_CUSTOMER_GROUP_FAILURE';

export const GET_CUSTOMER_GROUP_LIST_REQUEST = 'GET_CUSTOMER_GROUP_LIST_REQUEST';
export const GET_CUSTOMER_GROUP_LIST_SUCCESS = 'GET_CUSTOMER_GROUP_LIST_SUCCESS';
export const GET_CUSTOMER_GROUP_LIST_FAILURE = 'GET_CUSTOMER_GROUP_LIST_FAILURE';

export const CREATE_CUSTOMER_GROUP_REQUEST = 'CREATE_CUSTOMER_GROUP_REQUEST';
export const CREATE_CUSTOMER_GROUP_SUCCESS = 'CREATE_CUSTOMER_GROUP_SUCCESS';
export const CREATE_CUSTOMER_GROUP_FAILURE = 'CREATE_CUSTOMER_GROUP_FAILURE';

export const UPDATE_CUSTOMER_GROUP_REQUEST = 'UPDATE_CUSTOMER_GROUP_REQUEST';
export const UPDATE_CUSTOMER_GROUP_SUCCESS = 'UPDATE_CUSTOMER_GROUP_SUCCESS';
export const UPDATE_CUSTOMER_GROUP_FAILURE = 'UPDATE_CUSTOMER_GROUP_FAILURE';

export const DELETE_CUSTOMER_GROUP_REQUEST = 'DELETE_CUSTOMER_GROUP_REQUEST';
export const DELETE_CUSTOMER_GROUP_SUCCESS = 'DELETE_CUSTOMER_GROUP_SUCCESS';
export const DELETE_CUSTOMER_GROUP_FAILURE = 'DELETE_CUSTOMER_GROUP_FAILURE';

export const GET_CUSTOMER_GROUP_USERS_REQUEST = 'GET_CUSTOMER_GROUP_USERS_REQUEST';
export const GET_CUSTOMER_GROUP_USERS_SUCCESS = 'GET_CUSTOMER_GROUP_USERS_SUCCESS';
export const GET_CUSTOMER_GROUP_USERS_FAILURE = 'GET_CUSTOMER_GROUP_USERS_FAILURE';

export const ADD_USER_TO_GROUP_REQUEST = 'ADD_USER_TO_GROUP_REQUEST';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAILURE = 'ADD_USER_TO_GROUP_FAILURE';

export const REMOVE_USER_FROM_GROUP_REQUEST = 'REMOVE_USER_FROM_GROUP_REQUEST';
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_FAILURE = 'REMOVE_USER_FROM_GROUP_FAILURE';

// page title
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

// breadcrumb
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';

// Products
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const GET_PRODUCT_IMPORT_HISTORIES_REQUEST = 'GET_PRODUCT_IMPORT_HISTORIES_REQUEST';
export const GET_PRODUCT_IMPORT_HISTORIES_SUCCESS = 'GET_PRODUCT_IMPORT_HISTORIES_SUCCESS';
export const GET_PRODUCT_IMPORT_HISTORIES_FAILURE = 'GET_PRODUCT_IMPORT_HISTORIES_FAILURE';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const CREATE_PRODUCT_REMARK_REQUEST = 'CREATE_PRODUCT_REMARK_REQUEST';
export const CREATE_PRODUCT_REMARK_SUCCESS = 'CREATE_PRODUCT_REMARK_SUCCESS';
export const CREATE_PRODUCT_REMARK_FAILURE = 'CREATE_PRODUCT_REMARK_FAILURE';

// Marketing campaign
export const GET_MARKETING_CAMPAIGN_REQUEST = 'GET_MARKETING_CAMPAIGN_REQUEST';
export const GET_MARKETING_CAMPAIGN_SUCCESS = 'GET_MARKETING_CAMPAIGN_SUCCESS';
export const GET_MARKETING_CAMPAIGN_FAILURE = 'GET_MARKETING_CAMPAIGN_FAILURE';

export const GET_MARKETING_CAMPAIGN_LIST_REQUEST = 'GET_MARKETING_CAMPAIGN_LIST_REQUEST';
export const GET_MARKETING_CAMPAIGN_LIST_SUCCESS = 'GET_MARKETING_CAMPAIGN_LIST_SUCCESS';
export const GET_MARKETING_CAMPAIGN_LIST_FAILURE = 'GET_MARKETING_CAMPAIGN_LIST_FAILURE';

export const UPDATE_MARKETING_CAMPAIGN_REQUEST = 'UPDATE_MARKETING_CAMPAIGN_REQUEST';
export const UPDATE_MARKETING_CAMPAIGN_SUCCESS = 'UPDATE_MARKETING_CAMPAIGN_SUCCESS';
export const UPDATE_MARKETING_CAMPAIGN_FAILURE = 'UPDATE_MARKETING_CAMPAIGN_FAILURE';

export const CREATE_MARKETING_CAMPAIGN_REQUEST = 'CREATE_MARKETING_CAMPAIGN_REQUEST';
export const CREATE_MARKETING_CAMPAIGN_SUCCESS = 'CREATE_MARKETING_CAMPAIGN_SUCCESS';
export const CREATE_MARKETING_CAMPAIGN_FAILURE = 'CREATE_MARKETING_CAMPAIGN_FAILURE';

export const REMOVE_MARKETING_CAMPAIGN_REQUEST = 'REMOVE_MARKETING_CAMPAIGN_REQUEST';
export const REMOVE_MARKETING_CAMPAIGN_SUCCESS = 'REMOVE_MARKETING_CAMPAIGN_SUCCESS';
export const REMOVE_MARKETING_CAMPAIGN_FAILURE = 'REMOVE_MARKETING_CAMPAIGN_FAILURE';

export const ADD_MARKETING_CAMPAIGN_PRODUCTS_REQUEST = 'ADD_MARKETING_CAMPAIGN_PRODUCTS_REQUEST';
export const ADD_MARKETING_CAMPAIGN_PRODUCTS_SUCCESS = 'ADD_MARKETING_CAMPAIGN_PRODUCTS_SUCCESS';
export const ADD_MARKETING_CAMPAIGN_PRODUCTS_FAILURE = 'ADD_MARKETING_CAMPAIGN_PRODUCTS_FAILURE';

export const GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_REQUEST = 'GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_REQUEST';
export const GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_SUCCESS = 'GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_SUCCESS';
export const GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_FAILURE = 'GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_FAILURE';

export const UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_REQUEST = 'UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_REQUEST';
export const UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_SUCCESS = 'UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_SUCCESS';
export const UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_FAILURE = 'UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_FAILURE';

// Media library
export const GET_FILES_REQUEST = 'GET_FILES_REQUEST';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';

// Attributes
export const GET_ATTRIBUTE_SET_LIST_REQUEST = 'GET_ATTRIBUTE_SET_LIST_REQUEST';
export const GET_ATTRIBUTE_SET_LIST_SUCCESS = 'GET_ATTRIBUTE_SET_LIST_SUCCESS';
export const GET_ATTRIBUTE_SET_LIST_FAILURE = 'GET_ATTRIBUTE_SET_LIST_FAILURE';
export const CREATE_ATTRIBUTE_SET_REQUEST = 'CREATE_ATTRIBUTE_SET_REQUEST';
export const CREATE_ATTRIBUTE_SET_SUCCESS = 'CREATE_ATTRIBUTE_SET_SUCCESS';
export const CREATE_ATTRIBUTE_SET_FAILURE = 'CREATE_ATTRIBUTE_SET_FAILURE';
export const GET_ATTRIBUTE_SET_REQUEST = 'GET_ATTRIBUTE_SET_REQUEST';
export const GET_ATTRIBUTE_SET_SUCCESS = 'GET_ATTRIBUTE_SET_SUCCESS';
export const GET_ATTRIBUTE_SET_FAILURE = 'GET_ATTRIBUTE_SET_FAILURE';
export const UPDATE_ATTRIBUTE_SET_REQUEST = 'UPDATE_ATTRIBUTE_SET_REQUEST';
export const UPDATE_ATTRIBUTE_SET_SUCCESS = 'UPDATE_ATTRIBUTE_SET_SUCCESS';
export const UPDATE_ATTRIBUTE_SET_FAILURE = 'UPDATE_ATTRIBUTE_SET_FAILURE';
export const DELETE_ATTRIBUTE_SET_REQUEST = 'DELETE_ATTRIBUTE_SET_REQUEST';
export const DELETE_ATTRIBUTE_SET_SUCCESS = 'DELETE_ATTRIBUTE_SET_SUCCESS';
export const DELETE_ATTRIBUTE_SET_FAILURE = 'DELETE_ATTRIBUTE_SET_FAILURE';

// Atttribute keys
export const GET_ATTRIBUTE_KEYS_REQUEST = 'GET_ATTRIBUTE_KEYS_REQUEST';
export const GET_ATTRIBUTE_KEYS_SUCCESS = 'GET_ATTRIBUTE_KEYS_SUCCESS';
export const GET_ATTRIBUTE_KEYS_FAILURE = 'GET_ATTRIBUTE_KEYS_FAILURE';
export const CREATE_ATTRIBUTE_KEY_REQUEST = 'CREATE_ATTRIBUTE_KEY_REQUEST';
export const CREATE_ATTRIBUTE_KEY_SUCCESS = 'CREATE_ATTRIBUTE_KEY_SUCCESS';
export const CREATE_ATTRIBUTE_KEY_FAILURE = 'CREATE_ATTRIBUTE_KEY_FAILURE';
export const RESET_ERROR_CREATE_ATTRIBUTE_KEY = 'RESET_ERROR_CREATE_ATTRIBUTE_KEY';
export const DELETE_ATTRIBUTE_KEY_REQUEST = 'DELETE_ATTRIBUTE_KEY_REQUEST';
export const DELETE_ATTRIBUTE_KEY_SUCCESS = 'DELETE_ATTRIBUTE_KEY_SUCCESS';
export const DELETE_ATTRIBUTE_KEY_FAILURE = 'DELETE_ATTRIBUTE_KEY_FAILURE';

// Product labels
export const CREATE_PRODUCT_LABEL_REQUEST = 'CREATE_PRODUCT_LABEL_REQUEST';
export const CREATE_PRODUCT_LABEL_SUCCESS = 'CREATE_PRODUCT_LABEL_SUCCESS';
export const CREATE_PRODUCT_LABEL_FAILURE = 'CREATE_PRODUCT_LABEL_FAILURE';

export const CREATE_PRODUCT_LABEL_DEFAULT_REQUEST = 'CREATE_PRODUCT_LABEL_DEFAULT_REQUEST';
export const CREATE_PRODUCT_LABEL_DEFAULT_SUCCESS = 'CREATE_PRODUCT_LABEL_DEFAULT_SUCCESS';
export const CREATE_PRODUCT_LABEL_DEFAULT_FAILURE = 'CREATE_PRODUCT_LABEL_DEFAULT_FAILURE';

export const GET_PRODUCT_LABEL_REQUEST = 'GET_PRODUCT_LABEL_REQUEST';
export const GET_PRODUCT_LABEL_SUCCESS = 'GET_PRODUCT_LABEL_SUCCESS';
export const GET_PRODUCT_LABEL_FAILURE = 'GET_PRODUCT_LABEL_FAILURE';

export const GET_PRODUCT_LABELS_REQUEST = 'GET_PRODUCT_LABELS_REQUEST';
export const GET_PRODUCT_LABELS_SUCCESS = 'GET_PRODUCT_LABELS_SUCCESS';
export const GET_PRODUCT_LABELS_FAILURE = 'GET_PRODUCT_LABELS_FAILURE';

export const SORT_PRODUCT_LABEL_REQUEST = 'SORT_PRODUCT_LABEL_REQUEST';
export const SORT_PRODUCT_LABEL_SUCCESS = 'SORT_PRODUCT_LABEL_SUCCESS';
export const SORT_PRODUCT_LABEL_FAILURE = 'SORT_PRODUCT_LABEL_FAILURE';

export const UPDATE_PRODUCT_LABEL_REQUEST = 'UPDATE_PRODUCT_LABEL_REQUEST';
export const UPDATE_PRODUCT_LABEL_SUCCESS = 'UPDATE_PRODUCT_LABEL_SUCCESS';
export const UPDATE_PRODUCT_LABEL_FAILURE = 'UPDATE_PRODUCT_LABEL_FAILURE';

export const DELETE_PRODUCT_LABEL_REQUEST = 'DELETE_PRODUCT_LABEL_REQUEST';
export const DELETE_PRODUCT_LABEL_SUCCESS = 'DELETE_PRODUCT_LABEL_SUCCESS';
export const DELETE_PRODUCT_LABEL_FAILURE = 'UPDATE_PRODUCT_LABEL_FAILURE';

export const BULK_ATTACH_PRODUCT_LABEL_REQUEST = 'BULK_ATTACH_PRODUCT_LABEL_REQUEST';
export const BULK_ATTACH_PRODUCT_LABEL_SUCCESS = 'BULK_ATTACH_PRODUCT_LABEL_SUCCESS';
export const BULK_ATTACH_PRODUCT_LABEL_FAILURE = 'BULK_ATTACH_PRODUCT_LABEL_FAILURE';

// Setting Payment
export const GET_CATEGORY_LIST_REQUEST = 'GET_CATEGORY_LIST_REQUEST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAILURE = 'GET_CATEGORY_LIST_FAILURE';
export const CHECKED_CATEGORY = 'CHECKED_CATEGORY';
export const CLEAR_ALL_CHECKED = 'CLEAR_ALL_CHECKED';
export const CLEAR_ALL_SELECTED = 'CLEAR_ALL_SELECTED';

// Payments
export const GET_PAYMENT_METHOD_GROUPS_REQUEST = 'GET_PAYMENT_METHOD_GROUPS_REQUEST';
export const GET_PAYMENT_METHOD_GROUPS_SUCCESS = 'GET_PAYMENT_METHOD_GROUPS_SUCCESS';
export const GET_PAYMENT_METHOD_GROUPS_FAILURE = 'GET_PAYMENT_METHOD_GROUPS_FAILURE';

export const GET_PAYMENT_METHOD_GROUP_REQUEST = 'GET_PAYMENT_METHOD_GROUP_REQUEST';
export const GET_PAYMENT_METHOD_GROUP_SUCCESS = 'GET_PAYMENT_METHOD_GROUP_SUCCESS';
export const GET_PAYMENT_METHOD_GROUP_FAILURE = 'GET_PAYMENT_METHOD_GROUP_FAILURE';

export const GET_PRE_ORDER_PAYMENT_METHOD_GROUPS_REQUEST = 'GET_PRE_ORDER_PAYMENT_METHOD_GROUPS_REQUEST';
export const SET_PRE_ORDER_PAYMENT_LIST = 'SET_PRE_ORDER_PAYMENT_LIST';

export const UPDATE_PAYMENT_METHOD_GROUP_REQUEST = 'UPDATE_PAYMENT_METHOD_GROUP_REQUEST';
export const UPDATE_PAYMENT_METHOD_GROUP_SUCCESS = 'UPDATE_PAYMENT_METHOD_GROUP_SUCCESS';
export const UPDATE_PAYMENT_METHOD_GROUP_FAILURE = 'UPDATE_PAYMENT_METHOD_GROUP_FAILURE';

export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';
export const GET_PAYMENT_METHOD_FAILURE = 'GET_PAYMENT_METHOD_FAILURE';

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';

export const CREATE_INSTALLMENT_PLAN_REQUEST = 'CREATE_INSTALLMENT_PLAN_REQUEST';
export const CREATE_INSTALLMENT_PLAN_SUCCESS = 'CREATE_INSTALLMENT_PLAN_SUCCESS';
export const CREATE_INSTALLMENT_PLAN_FAILURE = 'CREATE_INSTALLMENT_PLAN_FAILURE';

export const UPDATE_INSTALLMENT_PLAN_REQUEST = 'UPDATE_INSTALLMENT_PLAN_REQUEST';
export const UPDATE_INSTALLMENT_PLAN_SUCCESS = 'UPDATE_INSTALLMENT_PLAN_SUCCESS';
export const UPDATE_INSTALLMENT_PLAN_FAILURE = 'UPDATE_INSTALLMENT_PLAN_FAILURE';

export const GET_INSTALLMENT_PLAN_REQUEST = 'GET_INSTALLMENT_PLAN_REQUEST';
export const GET_INSTALLMENT_PLAN_SUCCESS = 'GET_INSTALLMENT_PLAN_SUCCESS';
export const GET_INSTALLMENT_PLAN_FAILURE = 'GET_INSTALLMENT_PLAN_FAILURE';

export const DELETE_INSTALLMENT_PLAN_REQUEST = 'DELETE_INSTALLMENT_PLAN_REQUEST';
export const DELETE_INSTALLMENT_PLAN_SUCCESS = 'DELETE_INSTALLMENT_PLAN_SUCCESS';
export const DELETE_INSTALLMENT_PLAN_FAILURE = 'DELETE_INSTALLMENT_PLAN_FAILURE';

export const GET_CC_FOREVER_PLAN_REQUEST = 'GET_CC_FOREVER_PLAN_REQUEST';
export const GET_CC_FOREVER_PLAN_SUCCESS = 'GET_CC_FOREVER_PLAN_SUCCESS';
export const GET_CC_FOREVER_PLAN_FAILURE = 'GET_CC_FOREVER_PLAN_FAILURE';

export const UPDATE_CC_FOREVER_PLAN_REQUEST = 'UPDATE_CC_FOREVER_PLAN_REQUEST';
export const UPDATE_CC_FOREVER_PLAN_SUCCESS = 'UPDATE_CC_FOREVER_PLAN_SUCCESS';
export const UPDATE_CC_FOREVER_PLAN_FAILURE = 'UPDATE_CC_FOREVER_PLAN_FAILURE';

export const CREATE_CC_FOREVER_PLAN_REQUEST = 'CREATE_CC_FOREVER_PLAN_REQUEST';
export const CREATE_CC_FOREVER_PLAN_SUCCESS = 'CREATE_CC_FOREVER_PLAN_SUCCESS';
export const CREATE_CC_FOREVER_PLAN_FAILURE = 'CREATE_CC_FOREVER_PLAN_FAILURE';

export const DELETE_CC_FOREVER_PLAN_REQUEST = 'DELETE_CC_FOREVER_PLAN_REQUEST';
export const DELETE_CC_FOREVER_PLAN_SUCCESS = 'DELETE_CC_FOREVER_PLAN_SUCCESS';
export const DELETE_CC_FOREVER_PLAN_FAILURE = 'DELETE_CC_FOREVER_PLAN_FAILURE';

export const CREATE_BANK_CHANNEL_REQUEST = 'CREATE_BANK_CHANNEL_REQUEST';
export const CREATE_BANK_CHANNEL_SUCCESS = 'CREATE_BANK_CHANNEL_SUCCESS';
export const CREATE_BANK_CHANNEL_FAILURE = 'CREATE_BANK_CHANNEL_FAILURE';

export const UPDATE_BANK_CHANNEL_REQUEST = 'UPDATE_BANK_CHANNEL_REQUEST';
export const UPDATE_BANK_CHANNEL_SUCCESS = 'UPDATE_BANK_CHANNEL_SUCCESS';
export const UPDATE_BANK_CHANNEL_FAILURE = 'UPDATE_BANK_CHANNEL_FAILURE';

export const GET_BANK_CHANNEL_REQUEST = 'GET_BANK_CHANNEL_REQUEST';
export const GET_BANK_CHANNEL_SUCCESS = 'GET_BANK_CHANNEL_SUCCESS';
export const GET_BANK_CHANNEL_FAILURE = 'GET_BANK_CHANNEL_FAILURE';

export const DELETE_BANK_CHANNEL_REQUEST = 'DELETE_BANK_CHANNEL_REQUEST';
export const DELETE_BANK_CHANNEL_SUCCESS = 'DELETE_BANK_CHANNEL_SUCCESS';
export const DELETE_BANK_CHANNEL_FAILURE = 'DELETE_BANK_CHANNEL_FAILURE';

export const CREATE_INSTALLMENT_PLAN_CHANNEL_REQUEST = 'CREATE_INSTALLMENT_PLAN_CHANNEL_REQUEST';
export const CREATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS = 'CREATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS';
export const CREATE_INSTALLMENT_PLAN_CHANNEL_FAILURE = 'CREATE_INSTALLMENT_PLAN_CHANNEL_FAILURE';

export const UPDATE_INSTALLMENT_PLAN_CHANNEL_REQUEST = 'UPDATE_INSTALLMENT_PLAN_CHANNEL_REQUEST';
export const UPDATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS = 'UPDATE_INSTALLMENT_PLAN_CHANNEL_SUCCESS';
export const UPDATE_INSTALLMENT_PLAN_CHANNEL_FAILURE = 'UPDATE_INSTALLMENT_PLAN_CHANNEL_FAILURE';

export const CREATE_INSTALLMENT_PLAN_GROUP_REQUEST = 'CREATE_INSTALLMENT_PLAN_GROUP_REQUEST';
export const CREATE_INSTALLMENT_PLAN_GROUP_SUCCESS = 'CREATE_INSTALLMENT_PLAN_GROUP_SUCCESS';
export const CREATE_INSTALLMENT_PLAN_GROUP_FAILURE = 'CREATE_INSTALLMENT_PLAN_GROUP_FAILURE';

export const GET_INSTALLMENT_PLANS_REQUEST = 'GET_INSTALLMENT_PLANS_REQUEST';
export const GET_INSTALLMENT_PLANS_SUCCESS = 'GET_INSTALLMENT_PLANS_SUCCESS';
export const GET_INSTALLMENT_PLANS_FAILURE = 'GET_INSTALLMENT_PLANS_FAILURE';

export const GET_INSTALLMENT_PLAN_GROUP_REQUEST = 'GET_INSTALLMENT_PLAN_GROUP_REQUEST';
export const GET_INSTALLMENT_PLAN_GROUP_SUCCESS = 'GET_INSTALLMENT_PLAN_GROUP_SUCCESS';
export const GET_INSTALLMENT_PLAN_GROUP_FAILURE = 'GET_INSTALLMENT_PLAN_GROUP_FAILURE';

export const UPDATE_INSTALLMENT_PLAN_GROUP_REQUEST = 'UPDATE_INSTALLMENT_PLAN_GROUP_REQUEST';
export const UPDATE_INSTALLMENT_PLAN_GROUP_SUCCESS = 'UPDATE_INSTALLMENT_PLAN_GROUP_SUCCESS';
export const UPDATE_INSTALLMENT_PLAN_GROUP_FAILURE = 'UPDATE_INSTALLMENT_PLAN_GROUP_FAILURE';

export const DELETE_INSTALLMENT_PLAN_GROUP_REQUEST = 'DELETE_INSTALLMENT_PLAN_GROUP_REQUEST';
export const DELETE_INSTALLMENT_PLAN_GROUP_SUCCESS = 'DELETE_INSTALLMENT_PLAN_GROUP_SUCCESS';
export const DELETE_INSTALLMENT_PLAN_GROUP_FAILURE = 'DELETE_INSTALLMENT_PLAN_GROUP_FAILURE';

export const CREATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST = 'CREATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST';
export const CREATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS = 'CREATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS';
export const CREATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE = 'CREATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE';

export const GET_INSTALLMENT_PLAN_FOR_GROUP_REQUEST = 'GET_INSTALLMENT_PLAN_FOR_GROUP_REQUEST';
export const GET_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS = 'GET_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS';
export const GET_INSTALLMENT_PLAN_FOR_GROUP_FAILURE = 'GET_INSTALLMENT_PLAN_FOR_GROUP_FAILURE';

export const UPDATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST = 'UPDATE_INSTALLMENT_PLAN_FOR_GROUP_REQUEST';
export const UPDATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS = 'UPDATE_INSTALLMENT_PLAN_FOR_GROUP_SUCCESS';
export const UPDATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE = 'UPDATE_INSTALLMENT_PLAN_FOR_GROUP_FAILURE';

// Shipping
export const SET_SHIPPING_ACTIVE_TAB = 'SET_SHIPPING_ACTIVE_TAB';

export const GET_SHIPPING_METHOD_LIST_REQUEST = 'GET_SHIPPING_METHOD_LIST_REQUEST';
export const GET_SHIPPING_METHOD_LIST_SUCCESS = 'GET_SHIPPING_METHOD_LIST_SUCCESS';
export const GET_SHIPPING_METHOD_LIST_FAILURE = 'GET_SHIPPING_METHOD_LIST_FAILURE';

export const GET_SHIPPING_RATE_LIST_REQUEST = 'GET_SHIPPING_RATE_LIST_REQUEST';
export const GET_SHIPPING_RATE_LIST_SUCCESS = 'GET_SHIPPING_RATE_LIST_SUCCESS';
export const GET_SHIPPING_RATE_LIST_FAILURE = 'GET_SHIPPING_RATE_LIST_FAILURE';

export const UPDATE_SHIPPING_METHOD_REQUEST = 'UPDATE_SHIPPING_METHOD_REQUEST';
export const UPDATE_SHIPPING_METHOD_SUCCESS = 'UPDATE_SHIPPING_METHOD_SUCCESS';
export const UPDATE_SHIPPING_METHOD_FAILURE = 'UPDATE_SHIPPING_METHOD_FAILURE';

export const GET_SHIPPING_RATES_REQUEST = 'GET_SHIPPING_RATES_REQUEST';
export const GET_SHIPPING_RATES_SUCCESS = 'GET_SHIPPING_RATES_SUCCESS';
export const GET_SHIPPING_RATES_FAILURE = 'GET_SHIPPING_RATES_FAILURE';

export const CREATE_SHIPPING_RATE_REQUEST = 'CREATE_SHIPPING_RATE_REQUEST';
export const CREATE_SHIPPING_RATE_SUCCESS = 'CREATE_SHIPPING_RATE_SUCCESS';
export const CREATE_SHIPPING_RATE_FAILURE = 'CREATE_SHIPPING_RATE_FAILURE';

// Shipping Rate
export const GET_SHIPPING_RATE_REQUEST = 'GET_SHIPPING_RATE_REQUEST';
export const GET_SHIPPING_RATE_SUCCESS = 'GET_SHIPPING_RATE_SUCCESS';
export const GET_SHIPPING_RATE_FAILURE = 'GET_SHIPPING_RATE_FAILURE';

export const UPDATE_SHIPPING_RATE_REQUEST = 'UPDATE_SHIPPING_RATE_REQUEST';
export const UPDATE_SHIPPING_RATE_SUCCESS = 'UPDATE_SHIPPING_RATE_SUCCESS';
export const UPDATE_SHIPPING_RATE_FAILURE = 'UPDATE_SHIPPING_RATE_FAILURE';

export const DELETE_SHIPPING_RATE_REQUEST = 'DELETE_SHIPPING_RATE_REQUEST';
export const DELETE_SHIPPING_RATE_SUCCESS = 'DELETE_SHIPPING_RATE_SUCCESS';
export const DELETE_SHIPPING_RATE_FAILURE = 'DELETE_SHIPPING_RATE_FAILURE';

export const SET_SHIPPING_PAYMENT_LIST = 'SET_SHIPPING_PAYMENT_LIST';
export const GET_SHIPPING_PAYMENT_REQUEST = 'GET_SHIPPING_PAYMENT_REQUEST';
export const GET_SHIPPING_PAYMENT_SUCCESS = 'GET_SHIPPING_PAYMENT_SUCCESS';

// Additional Fee
export const CREATE_ADDITIONAL_FEE_REQUEST = 'CREATE_ADDITIONAL_FEE_REQUEST';
export const CREATE_ADDITIONAL_FEE_SUCCESS = 'CREATE_ADDITIONAL_FEE_SUCCESS';
export const CREATE_ADDITIONAL_FEE_FAILURE = 'CREATE_ADDITIONAL_FEE_FAILURE';

export const UPDATE_ADDITIONAL_FEE_REQUEST = 'UPDATE_ADDITIONAL_FEE_REQUEST';
export const UPDATE_ADDITIONAL_FEE_SUCCESS = 'UPDATE_ADDITIONAL_FEE_SUCCESS';
export const UPDATE_ADDITIONAL_FEE_FAILURE = 'UPDATE_ADDITIONAL_FEE_FAILURE';

export const GET_ADDITIONAL_FEE_REQUEST = 'GET_ADDITIONAL_FEE_REQUEST';
export const GET_ADDITIONAL_FEE_SUCCESS = 'GET_ADDITIONAL_FEE_SUCCESS';
export const GET_ADDITIONAL_FEE_FAILURE = 'GET_ADDITIONAL_FEE_FAILURE';

export const GET_SHIPPING_ADDITIONAL_FEE_REQUEST = 'GET_SHIPPING_ADDITIONAL_FEE_REQUEST';
export const GET_SHIPPING_ADDITIONAL_FEE_SUCCESS = 'GET_SHIPPING_ADDITIONAL_FEE_SUCCESS';
export const GET_SHIPPING_ADDITIONAL_FEE_FAILURE = 'GET_SHIPPING_ADDITIONAL_FEE_FAILURE';

// Order Management
export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const CREATE_ORDER_ADDRESS_REQUEST = 'CREATE_ORDER_ADDRESS_REQUEST';
export const CREATE_ORDER_ADDRESS_SUCCESS = 'CREATE_ORDER_ADDRESS_SUCCESS';
export const CREATE_ORDER_ADDRESS_FAILURE = 'CREATE_ORDER_ADDRESS_FAILURE';

export const UPDATE_ORDER_ADDRESS_REQUEST = 'UPDATE_ORDER_ADDRESS_REQUEST';
export const UPDATE_ORDER_ADDRESS_SUCCESS = 'UPDATE_ORDER_ADDRESS_SUCCESS';
export const UPDATE_ORDER_ADDRESS_FAILURE = 'UPDATE_ORDER_ADDRESS_FAILURE';

export const CREATE_ORDER_REMARK_REQUEST = 'CREATE_ORDER_REMARK_REQUEST';
export const CREATE_ORDER_REMARK_SUCCESS = 'CREATE_ORDER_REMARK_SUCCESS';
export const CREATE_ORDER_REMARK_FAILURE = 'CREATE_ORDER_REMARK_FAILURE';

// Shipment Management
export const GET_SHIPMENTS_REQUEST = 'GET_SHIPMENTS_REQUEST';
export const GET_SHIPMENTS_SUCCESS = 'GET_SHIPMENTS_SUCCESS';
export const GET_SHIPMENTS_FAILURE = 'GET_SHIPMENTS_FAILURE';

export const GET_SHIPMENT_REQUEST = 'GET_SHIPMENT_REQUEST';
export const GET_SHIPMENT_SUCCESS = 'GET_SHIPMENT_SUCCESS';
export const GET_SHIPMENT_FAILURE = 'GET_SHIPMENT_FAILURE';

export const UPDATE_SHIPMENT_REQUEST = 'UPDATE_SHIPMENT_REQUEST';
export const UPDATE_SHIPMENT_SUCCESS = 'UPDATE_SHIPMENT_SUCCESS';
export const UPDATE_SHIPMENT_FAILURE = 'UPDATE_SHIPMENT_FAILURE';

export const CREATE_SHIPMENT_REQUEST = 'CREATE_SHIPMENT_REQUEST';
export const CREATE_SHIPMENT_SUCCESS = 'CREATE_SHIPMENT_SUCCESS';
export const CREATE_SHIPMENT_FAILURE = 'CREATE_SHIPMENT_FAILURE';

export const CANCEL_SHIPMENT_REQUEST = 'CANCEL_SHIPMENT_REQUEST';
export const CANCEL_SHIPMENT_SUCCESS = 'CANCEL_SHIPMENT_SUCCESS';
export const CANCEL_SHIPMENT_FAILURE = 'CANCEL_SHIPMENT_FAILURE';

export const BOOK_COURIER_REQUEST = 'BOOK_COURIER_REQUEST';
export const BOOK_COURIER_SUCCESS = 'BOOK_COURIER_SUCCESS';
export const BOOK_COURIER_FAILURE = 'BOOK_COURIER_FAILURE';

// Search weights
export const GET_SEARCH_WEIGHTS_REQUEST = 'GET_SEARCH_WEIGHTS_REQUEST';
export const GET_SEARCH_WEIGHTS_SUCCESS = 'GET_SEARCH_WEIGHTS_SUCCESS';
export const GET_SEARCH_WEIGHTS_FAILURE = 'GET_SEARCH_WEIGHTS_FAILURE';

export const GET_SEARCH_WEIGHT_REQUEST = 'GET_SEARCH_WEIGHT_REQUEST';
export const GET_SEARCH_WEIGHT_SUCCESS = 'GET_SEARCH_WEIGHT_SUCCESS';
export const GET_SEARCH_WEIGHT_FAILURE = 'GET_SEARCH_WEIGHT_FAILURE';

export const CREATE_SEARCH_WEIGHT_REQUEST = 'CREATE_SEARCH_WEIGHT_REQUEST';
export const CREATE_SEARCH_WEIGHT_SUCCESS = 'CREATE_SEARCH_WEIGHT_SUCCESS';
export const CREATE_SEARCH_WEIGHT_FAILURE = 'CREATE_SEARCH_WEIGHT_FAILURE';

export const UPDATE_SEARCH_WEIGHT_REQUEST = 'UPDATE_SEARCH_WEIGHT_REQUEST';
export const UPDATE_SEARCH_WEIGHT_SUCCESS = 'UPDATE_SEARCH_WEIGHT_SUCCESS';
export const UPDATE_SEARCH_WEIGHT_FAILURE = 'UPDATE_SEARCH_WEIGHT_FAILURE';

export const DELETE_SEARCH_WEIGHT_REQUEST = 'DELETE_SEARCH_WEIGHT_REQUEST';
export const DELETE_SEARCH_WEIGHT_SUCCESS = 'DELETE_SEARCH_WEIGHT_SUCCESS';
export const DELETE_SEARCH_WEIGHT_FAILURE = 'DELETE_SEARCH_WEIGHT_FAILURE';

export const SEARCH_WEIGHTS_RESYNC_REQUEST = 'SEARCH_WEIGHTS_RESYNC_REQUEST';
export const SEARCH_WEIGHTS_RESYNC_SUCCESS = 'SEARCH_WEIGHTS_RESYNC_SUCCESS';
export const SEARCH_WEIGHTS_RESYNC_FAILURE = 'SEARCH_WEIGHTS_RESYNC_FAILURE';

// Search result caches
export const CLEAR_SEARCH_RESULT_CACHE_REQUEST = 'CLEAR_SEARCH_RESULT_CACHE_REQUEST';
export const CLEAR_SEARCH_RESULT_CACHE_SUCCESS = 'CLEAR_SEARCH_RESULT_CACHE_SUCCESS';
export const CLEAR_SEARCH_RESULT_CACHE_FAILURE = 'CLEAR_SEARCH_RESULT_CACHE_FAILURE';

// Search synonyms
export const GET_SEARCH_SYNONYMS_REQUEST = 'GET_SEARCH_SYNONYMS_REQUEST';
export const GET_SEARCH_SYNONYMS_SUCCESS = 'GET_SEARCH_SYNONYMS_SUCCESS';
export const GET_SEARCH_SYNONYMS_FAILURE = 'GET_SEARCH_SYNONYMS_FAILURE';

export const CREATE_SEARCH_SYNONYM_REQUEST = 'CREATE_SEARCH_SYNONYM_REQUEST';
export const CREATE_SEARCH_SYNONYM_SUCCESS = 'CREATE_SEARCH_SYNONYM_SUCCESS';
export const CREATE_SEARCH_SYNONYM_FAILURE = 'CREATE_SEARCH_SYNONYM_FAILURE';

export const DELETE_SEARCH_SYNONYM_REQUEST = 'DELETE_SEARCH_SYNONYM_REQUEST';
export const DELETE_SEARCH_SYNONYM_SUCCESS = 'DELETE_SEARCH_SYNONYM_SUCCESS';
export const DELETE_SEARCH_SYNONYM_FAILURE = 'DELETE_SEARCH_SYNONYM_FAILURE';

// Warehouse
export const GET_WAREHOUSE_REQUEST = 'GET_WAREHOUSE_REQUEST';
export const GET_WAREHOUSE_SUCCESS = 'GET_WAREHOUSE_SUCCESS';
export const GET_WAREHOUSE_FAILURE = 'GET_WAREHOUSE_FAILURE';
export const GET_WAREHOUSE_LIST_REQUEST = 'GET_WAREHOUSE_LIST_REQUEST';
export const GET_WAREHOUSE_LIST_SUCCESS = 'GET_WAREHOUSE_LIST_SUCCESS';
export const GET_WAREHOUSE_LIST_FAILURE = 'GET_WAREHOUSE_LIST_FAILURE';
export const CREATE_WAREHOUSE_REQUEST = 'CREATE_WAREHOUSE_REQUEST';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILURE = 'CREATE_WAREHOUSE_FAILURE';
export const UPDATE_WAREHOUSE_REQUEST = 'UPDATE_WAREHOUSE_REQUEST';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILURE = 'UPDATE_WAREHOUSE_FAILURE';
export const GET_WAREHOUSE_TYPES_REQUEST = 'GET_WAREHOUSE_TYPES_REQUEST';
export const GET_WAREHOUSE_TYPES_SUCCESS = 'GET_WAREHOUSE_TYPES_SUCCESS';
export const GET_WAREHOUSE_TYPES_FAILURE = 'GET_WAREHOUSE_TYPES_FAILURE';

// Pages
export const GET_PAGES_REQUEST = 'GET_PAGES_REQUEST';
export const GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
export const GET_PAGES_FAILURE = 'GET_PAGES_FAILURE';
export const SELECT_PAGE = 'SELECT_PAGE';

// Sort categories
export const SORT_PAGES_REQUEST = 'SORT_PAGES_REQUEST';
export const SORT_PAGES_SUCCESS = 'SORT_PAGES_SUCCESS';
export const SORT_PAGES_FAILURE = 'SORT_PAGES_FAILURE';

// Category
export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'GET_PAGE_FAILURE';
export const RESET_PAGE = 'RESET_PAGE';

export const CREATE_PAGE_REQUEST = 'CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_FAILURE = 'CREATE_PAGE_FAILURE';
export const CREATE_PAGE_CLEAR_ERROR = 'CREATE_PAGE_CLEAR_ERROR';

export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_FAILURE = 'UPDATE_PAGE_FAILURE';
export const UPDATE_PAGE_CLEAR_ERROR = 'UPDATE_PAGE_CLEAR_ERROR';

export const DELETE_PAGE_REQUEST = 'DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'DELETE_PAGE_FAILURE';
export const DELETE_PAGE_CLEAR_ERROR = 'DELETE_PAGE_CLEAR_ERROR';

// Brand
export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE';

// Banner
export const GET_BANNERS_GROUP_REQUEST = 'GET_BANNERS_GROUP_REQUEST';
export const GET_BANNERS_GROUP_SUCCESS = 'GET_BANNERS_GROUP_SUCCESS';
export const GET_BANNERS_GROUP_FAILURE = 'GET_BANNERS_GROUP_FAILURE';

export const GET_BANNER_GROUP_REQUEST = 'GET_BANNER_GROUP_REQUEST';
export const GET_BANNER_GROUP_SUCCESS = 'GET_BANNER_GROUP_SUCCESS';
export const GET_BANNER_GROUP_FAILURE = 'GET_BANNER_GROUP_FAILURE';

export const CREATE_BANNER_GROUP_REQUEST = 'CREATE_BANNER_GROUP_REQUEST';
export const CREATE_BANNER_GROUP_SUCCESS = 'CREATE_BANNER_GROUP_SUCCESS';
export const CREATE_BANNER_GROUP_FAILURE = 'CREATE_BANNER_GROUP_FAILURE';

export const UPDATE_BANNER_GROUP_REQUEST = 'UPDATE_BANNER_GROUP_REQUEST';
export const UPDATE_BANNER_GROUP_SUCCESS = 'UPDATE_BANNER_GROUP_SUCCESS';
export const UPDATE_BANNER_GROUP_FAILURE = 'UPDATE_BANNER_GROUP_FAILURE';

export const DELETE_BANNER_GROUP_REQUEST = 'DELETE_BANNER_GROUP_REQUEST';
export const DELETE_BANNER_GROUP_SUCCESS = 'DELETE_BANNER_GROUP_SUCCESS';
export const DELETE_BANNER_GROUP_FAILURE = 'DELETE_BANNER_GROUP_FAILURE';

// Widget
export const GET_WIDGETS_REQUEST = 'GET_WIDGETS_REQUEST';
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS';
export const GET_WIDGETS_FAILURE = 'GET_WIDGETS_FAILURE';

export const SORT_WIDGETS_REQUEST = 'SORT_WIDGETS_REQUEST';
export const SORT_WIDGETS_SUCCESS = 'SORT_WIDGETS_SUCCESS';
export const SORT_WIDGETS_FAILURE = 'SORT_WIDGETS_FAILURE';

export const CREATE_WIDGET_REQUEST = 'CREATE_WIDGET_REQUEST';
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS';
export const CREATE_WIDGET_FAILURE = 'CREATE_WIDGET_FAILURE';

export const UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST';
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS';
export const UPDATE_WIDGET_FAILURE = 'UPDATE_WIDGET_FAILURE';

export const UPDATE_WIDGET_CATEGORY_REQUEST = 'UPDATE_WIDGET_CATEGORY_REQUEST';
export const UPDATE_WIDGET_CATEGORY_SUCCESS = 'UPDATE_WIDGET_CATEGORY_SUCCESS';
export const UPDATE_WIDGET_CATEGORY_FAILURE = 'UPDATE_WIDGET_CATEGORY_FAILURE';

export const DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_FAILURE = 'DELETE_WIDGET_FAILURE';

// Promotions
export const GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE';

export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQUEST';
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS';
export const GET_PROMOTION_FAILURE = 'GET_PROMOTION_FAILURE';

export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILURE = 'CREATE_PROMOTION_FAILURE';

export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_FAILURE = 'UPDATE_PROMOTION_FAILURE';

export const DELETE_PROMOTION_REQUEST = 'DELETE_PROMOTION_REQUEST';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_FAILURE = 'DELETE_PROMOTION_FAILURE';

export const CREATE_PROMOTION_REMARK_REQUEST = 'CREATE_PROMOTION_REMARK_REQUEST';
export const CREATE_PROMOTION_REMARK_SUCCESS = 'CREATE_PROMOTION_REMARK_SUCCESS';
export const CREATE_PROMOTION_REMARK_FAILURE = 'CREATE_PROMOTION_REMARK_FAILURE';

// On-Site Messaging
export const GET_ON_SITE_MESSAGES_REQUEST = 'GET_ON_SITE_MESSAGES_REQUEST';
export const GET_ON_SITE_MESSAGES_SUCCESS = 'GET_ON_SITE_MESSAGES_SUCCESS';
export const GET_ON_SITE_MESSAGES_FAILURE = 'GET_ON_SITE_MESSAGES_FAILURE';

export const GET_ON_SITE_MESSAGE_REQUEST = 'GET_ON_SITE_MESSAGE_REQUEST';
export const GET_ON_SITE_MESSAGE_SUCCESS = 'GET_ON_SITE_MESSAGE_SUCCESS';
export const GET_ON_SITE_MESSAGE_FAILURE = 'GET_ON_SITE_MESSAGE_FAILURE';

export const CREATE_ON_SITE_MESSAGE_REQUEST = 'CREATE_ON_SITE_MESSAGE_REQUEST';
export const CREATE_ON_SITE_MESSAGE_SUCCESS = 'CREATE_ON_SITE_MESSAGE_SUCCESS';
export const CREATE_ON_SITE_MESSAGE_FAILURE = 'CREATE_ON_SITE_MESSAGE_FAILURE';

export const UPDATE_ON_SITE_MESSAGE_REQUEST = 'UPDATE_ON_SITE_MESSAGE_REQUEST';
export const UPDATE_ON_SITE_MESSAGE_SUCCESS = 'UPDATE_ON_SITE_MESSAGE_SUCCESS';
export const UPDATE_ON_SITE_MESSAGE_FAILURE = 'UPDATE_ON_SITE_MESSAGE_FAILURE';

export const DELETE_ON_SITE_MESSAGE_REQUEST = 'DELETE_ON_SITE_MESSAGE_REQUEST';
export const DELETE_ON_SITE_MESSAGE_SUCCESS = 'DELETE_ON_SITE_MESSAGE_SUCCESS';
export const DELETE_ON_SITE_MESSAGE_FAILURE = 'DELETE_ON_SITE_MESSAGE_FAILURE';

// Roles
export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';
export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_REQUEST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAILURE = 'GET_ROLE_LIST_FAILURE';
export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';
export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';
export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
export const GET_ROLE_USERS_REQUEST = 'GET_ROLE_USERS_REQUEST';
export const GET_ROLE_USERS_SUCCESS = 'GET_ROLE_USERS_SUCCESS';
export const GET_ROLE_USERS_FAILURE = 'GET_ROLE_USERS_FAILURE';
export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';
export const ADD_USER_TO_ROLE_REQUEST = 'ADD_USER_TO_ROLE_REQUEST';
export const ADD_USER_TO_ROLE_SUCCESS = 'ADD_USER_TO_ROLE_SUCCESS';
export const ADD_USER_TO_ROLE_FAILURE = 'ADD_USER_TO_ROLE_FAILURE';
export const REMOVE_USER_FROM_ROLE_REQUEST = 'REMOVE_USER_FROM_ROLE_REQUEST';
export const REMOVE_USER_FROM_ROLE_SUCCESS = 'REMOVE_USER_FROM_ROLE_SUCCESS';
export const REMOVE_USER_FROM_ROLE_FAILURE = 'REMOVE_USER_FROM_ROLE_FAILURE';

// Pre-order
export const GET_PREORDERS_REQUEST = 'GET_PREORDERS_REQUEST';
export const GET_PREORDERS_SUCCESS = 'GET_PREORDERS_SUCCESS';
export const GET_PREORDERS_FAILURE = 'GET_PREORDERS_FAILURE';

export const GET_PREORDER_REQUEST = 'GET_PREORDER_REQUEST';
export const GET_PREORDER_SUCCESS = 'GET_PREORDER_SUCCESS';
export const GET_PREORDER_FAILURE = 'GET_PREORDER_FAILURE';

export const CREATE_PREORDER_REQUEST = 'CREATE_PREORDER_REQUEST';
export const CREATE_PREORDER_SUCCESS = 'CREATE_PREORDER_SUCCESS';
export const CREATE_PREORDER_FAILURE = 'CREATE_PREORDER_FAILURE';

export const UPDATE_PREORDER_REQUEST = 'UPDATE_PREORDER_REQUEST';
export const UPDATE_PREORDER_SUCCESS = 'UPDATE_PREORDER_SUCCESS';
export const UPDATE_PREORDER_FAILURE = 'UPDATE_PREORDER_FAILURE';

export const DELETE_PREORDER_REQUEST = 'DELETE_PREORDER_REQUEST';
export const DELETE_PREORDER_SUCCESS = 'DELETE_PREORDER_SUCCESS';
export const DELETE_PREORDER_FAILURE = 'DELETE_PREORDER_FAILURE';

// Pre-order pickup at store
export const CREATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST = 'CREATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST';
export const CREATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS = 'CREATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS';
export const CREATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE = 'CREATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE';

export const GET_PREORDER_PICKUP_AT_STORE_RULE_REQUEST = 'GET_PREORDER_PICKUP_AT_STORE_RULE_REQUEST';
export const GET_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS = 'GET_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS';
export const GET_PREORDER_PICKUP_AT_STORE_RULE_FAILURE = 'GET_PREORDER_PICKUP_AT_STORE_RULE_FAILURE';

export const UPDATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST = 'UPDATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST';
export const UPDATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS = 'UPDATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS';
export const UPDATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE = 'UPDATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE';

export const DELETE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST = 'DELETE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST';
export const DELETE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS = 'DELETE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS';
export const DELETE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE = 'DELETE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE';


// Cancelation-reason
export const GET_CANCEL_ORDERS_REQUEST = 'GET_CANCEL_ORDERS_REQUEST';
export const GET_CANCEL_ORDERS_SUCCESS = 'GET_CANCEL_ORDERS_SUCCESS';
export const GET_CANCEL_ORDERS_FAILURE = 'GET_CANCEL_ORDERS_FAILURE';

export const UPDATE_CANCEL_ORDERS_REQUEST = 'UPDATE_CANCEL_ORDERS_REQUEST';
export const UPDATE_CANCEL_ORDERS_SUCCESS = 'UPDATE_CANCEL_ORDERS_SUCCESS';
export const UPDATE_CANCEL_ORDERS_FAILURE = 'UPDATE_CANCEL_ORDERS_FAILURE';

// Feed
export const GET_FEED_REQUEST = 'GET_FEED_REQUEST';
export const GET_FEED_SUCCESS = 'GET_FEED_SUCCESS';
export const GET_FEED_FAILURE = 'GET_FEED_FAILURE';
export const GET_FEED_LIST_REQUEST = 'GET_FEED_LIST_REQUEST';
export const GET_FEED_LIST_SUCCESS = 'GET_FEED_LIST_SUCCESS';
export const GET_FEED_LIST_FAILURE = 'GET_FEED_LIST_FAILURE';
export const CREATE_FEED_REQUEST = 'CREATE_FEED_REQUEST';
export const CREATE_FEED_SUCCESS = 'CREATE_FEED_SUCCESS';
export const CREATE_FEED_FAILURE = 'CREATE_FEED_FAILURE';
export const UPDATE_FEED_REQUEST = 'UPDATE_FEED_REQUEST';
export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS';
export const UPDATE_FEED_FAILURE = 'UPDATE_FEED_FAILURE';
export const DELETE_FEED_REQUEST = 'DELETE_FEED_REQUEST';
export const DELETE_FEED_SUCCESS = 'DELETE_FEED_SUCCESS';
export const DELETE_FEED_FAILURE = 'DELETE_FEED_FAILURE';
export const CREATE_FEED_REMARK_REQUEST = 'CREATE_FEED_REMARK_REQUEST';
export const CREATE_FEED_REMARK_SUCCESS = 'CREATE_FEED_REMARK_SUCCESS';
export const CREATE_FEED_REMARK_FAILURE = 'CREATE_FEED_REMARK_FAILURE';

// Flash sale
export const GET_FLASHSALES_REQUEST = 'GET_FLASHSALES_REQUEST';
export const GET_FLASHSALES_SUCCESS = 'GET_FLASHSALES_SUCCESS';
export const GET_FLASHSALES_FAILURE = 'GET_FLASHSALES_FAILURE';
export const CREATE_FLASHSALE_REQUEST = 'CREATE_FLASHSALE_REQUEST';
export const CREATE_FLASHSALE_SUCCESS = 'CREATE_FLASHSALE_SUCCESS';
export const CREATE_FLASHSALE_FAILURE = 'CREATE_FLASHSALE_FAILURE';
export const GET_FLASHSALE_REQUEST = 'GET_FLASHSALE_REQUEST';
export const GET_FLASHSALE_SUCCESS = 'GET_FLASHSALE_SUCCESS';
export const GET_FLASHSALE_FAILURE = 'GET_FLASHSALE_FAILURE';
export const UPDATE_FLASHSALE_REQUEST = 'UPDATE_FLASHSALE_REQUEST';
export const UPDATE_FLASHSALE_SUCCESS = 'UPDATE_FLASHSALE_SUCCESS';
export const UPDATE_FLASHSALE_FAILURE = 'UPDATE_FLASHSALE_FAILURE';
export const DELETE_FLASHSALE_REQUEST = 'DELETE_FLASHSALE_REQUEST';
export const DELETE_FLASHSALE_SUCCESS = 'DELETE_FLASHSALE_SUCCESS';
export const DELETE_FLASHSALE_FAILURE = 'DELETE_FLASHSALE_FAILURE';
export const CREATE_FLASHSALE_REMARK_REQUEST = 'CREATE_FLASHSALE_REMARK_REQUEST';
export const CREATE_FLASHSALE_REMARK_SUCCESS = 'CREATE_FLASHSALE_REMARK_SUCCESS';
export const CREATE_FLASHSALE_REMARK_FAILURE = 'CREATE_FLASHSALE_REMARK_FAILURE';

// Bundle set
export const GET_BUNDLE_SETS_REQUEST = 'GET_BUNDLE_SETS_REQUEST';
export const GET_BUNDLE_SETS_SUCCESS = 'GET_BUNDLE_SETS_SUCCESS';
export const GET_BUNDLE_SETS_FAILURE = 'GET_BUNDLE_SETS_FAILURE';
export const CREATE_BUNDLE_SET_REQUEST = 'CREATE_BUNDLE_SET_REQUEST';
export const CREATE_BUNDLE_SET_SUCCESS = 'CREATE_BUNDLE_SET_SUCCESS';
export const CREATE_BUNDLE_SET_FAILURE = 'CREATE_BUNDLE_SET_FAILURE';
export const GET_BUNDLE_SET_REQUEST = 'GET_BUNDLE_SET_REQUEST';
export const GET_BUNDLE_SET_SUCCESS = 'GET_BUNDLE_SET_SUCCESS';
export const GET_BUNDLE_SET_FAILURE = 'GET_BUNDLE_SET_FAILURE';
export const UPDATE_BUNDLE_SET_REQUEST = 'UPDATE_BUNDLE_SET_REQUEST';
export const UPDATE_BUNDLE_SET_SUCCESS = 'UPDATE_BUNDLE_SET_SUCCESS';
export const UPDATE_BUNDLE_SET_FAILURE = 'UPDATE_BUNDLE_SET_FAILURE';
export const DELETE_BUNDLE_SET_REQUEST = 'DELETE_BUNDLE_SET_REQUEST';
export const DELETE_BUNDLE_SET_SUCCESS = 'DELETE_BUNDLE_SET_SUCCESS';
export const DELETE_BUNDLE_SET_FAILURE = 'DELETE_BUNDLE_SET_FAILURE';

// Audit log
export const GET_AUDIT_LOGS_REQUEST = 'GET_AUDIT_LOGS_REQUEST';
export const GET_AUDIT_LOGS_SUCCESS = 'GET_AUDIT_LOGS_SUCCESS';
export const GET_AUDIT_LOGS_FAILURE = 'GET_AUDIT_LOGS_FAILURE';
export const GET_AUDIT_LOG_REQUEST = 'GET_AUDIT_LOG_REQUEST';
export const GET_AUDIT_LOG_SUCCESS = 'GET_AUDIT_LOG_SUCCESS';
export const GET_AUDIT_LOG_FAILURE = 'GET_AUDIT_LOG_FAILURE';
export const GET_AUDIT_LOG_OPTION_REQUEST = 'GET_AUDIT_LOG_OPTION_REQUEST';
export const GET_AUDIT_LOG_OPTION_SUCCESS = 'GET_AUDIT_LOG_OPTION_SUCCESS';
export const GET_AUDIT_LOG_OPTION_FAILURE = 'GET_AUDIT_LOG_OPTION_FAILURE';

// Abandoned cart
export const GET_ABANDONED_CART_REQUEST = 'GET_ABANDONED_CART_REQUEST';
export const GET_ABANDONED_CART_SUCCESS = 'GET_ABANDONED_CART_SUCCESS';
export const GET_ABANDONED_CART_FAILURE = 'GET_ABANDONED_CART_FAILURE';

// Sync branch
export const CREATE_SYNC_BRANCH_REQUEST = 'CREATE_SYNC_BRANCH_REQUEST';
export const CREATE_SYNC_BRANCH_SUCCESS = 'CREATE_SYNC_BRANCH_SUCCESS';
export const CREATE_SYNC_BRANCH_FAILURE = 'CREATE_SYNC_BRANCH_FAILURE';

// Back in stock notification
export const GET_BACK_IN_STOCK_LIST_REQUEST = 'GET_BACK_IN_STOCK_LIST_REQUEST';
export const GET_BACK_IN_STOCK_LIST_SUCCESS = 'GET_BACK_IN_STOCK_LIST_SUCCESS';
export const GET_BACK_IN_STOCK_LIST_FAILURE = 'GET_BACK_IN_STOCK_LIST_FAILURE';

// Sub headers
export const GET_SUB_HEADERS_REQUEST = 'GET_SUB_HEADERS_REQUEST';
export const GET_SUB_HEADERS_SUCCESS = 'GET_SUB_HEADERS_SUCCESS';
export const GET_SUB_HEADERS_FAILURE = 'GET_SUB_HEADERS_FAILURE';

export const UPDATE_SUB_HEADERS_REQUEST = 'UPDATE_SUB_HEADERS_REQUEST';
export const UPDATE_SUB_HEADERS_SUCCESS = 'UPDATE_SUB_HEADERS_SUCCESS';
export const UPDATE_SUB_HEADERS_FAILURE = 'UPDATE_SUB_HEADERS_FAILURE';

// Product Limits
export const GET_LIMIT_PRODUCTS_REQUEST = 'GET_LIMIT_PRODUCTS_REQUEST';
export const GET_LIMIT_PRODUCTS_SUCCESS = 'GET_LIMIT_PRODUCTS_SUCCESS';
export const GET_LIMIT_PRODUCTS_FAILURE = 'GET_LIMIT_PRODUCTS_FAILURE';
export const GET_LIMIT_PRODUCT_REQUEST = 'GET_LIMIT_PRODUCT_REQUEST';
export const GET_LIMIT_PRODUCT_SUCCESS = 'GET_LIMIT_PRODUCT_SUCCESS';
export const GET_LIMIT_PRODUCT_FAILURE = 'GET_LIMIT_PRODUCT_FAILURE';
export const CREATE_LIMIT_PRODUCT_REQUEST = 'CREATE_LIMIT_PRODUCT_REQUEST';
export const CREATE_LIMIT_PRODUCT_SUCCESS = 'CREATE_LIMIT_PRODUCT_SUCCESS';
export const CREATE_LIMIT_PRODUCT_FAILURE = 'CREATE_LIMIT_PRODUCT_FAILURE';
export const UPDATE_LIMIT_PRODUCT_REQUEST = 'UPDATE_LIMIT_PRODUCT_REQUEST';
export const UPDATE_LIMIT_PRODUCT_SUCCESS = 'UPDATE_LIMIT_PRODUCT_SUCCESS';
export const UPDATE_LIMIT_PRODUCT_FAILURE = 'UPDATE_LIMIT_PRODUCT_FAILURE';
export const DELETE_LIMIT_PRODUCT_REQUEST = 'DELETE_LIMIT_PRODUCT_REQUEST';
export const DELETE_LIMIT_PRODUCT_SUCCESS = 'DELETE_LIMIT_PRODUCT_SUCCESS';
export const DELETE_LIMIT_PRODUCT_FAILURE = 'DELETE_LIMIT_PRODUCT_FAILURE';

// Order Auto Complete
export const GET_ORDER_AUTO_COMPLETE_LIST_REQUEST = 'GET_ORDER_AUTO_COMPLETE_LIST_REQUEST';
export const GET_ORDER_AUTO_COMPLETE_LIST_SUCCESS = 'GET_ORDER_AUTO_COMPLETE_LIST_SUCCESS';
export const GET_ORDER_AUTO_COMPLETE_LIST_FAILURE = 'GET_ORDER_AUTO_COMPLETE_LIST_FAILURE';
export const UPDATE_ORDER_AUTO_COMPLETE_LIST_REQUEST = 'UPDATE_ORDER_AUTO_COMPLETE_LIST_REQUEST';
export const UPDATE_ORDER_AUTO_COMPLETE_LIST_SUCCESS = 'UPDATE_ORDER_AUTO_COMPLETE_LIST_SUCCESS';
export const UPDATE_ORDER_AUTO_COMPLETE_LIST_FAILURE = 'UPDATE_ORDER_AUTO_COMPLETE_LIST_FAILURE';
