import { covertFileSize, datetimeFormat } from '../helpers';

export const transformedMedia = (media) => {
	const mediaData = media || {};
	const urls = mediaData.urls || {};
	const info = mediaData.info || {};
	const size = info.size || 0;

	return {
		id: mediaData.id || null,
		name: mediaData.name || null,
		fileName: mediaData.file_name || null,
		imageUrls: {
			thumbnail: urls.thumbnail || null,
			small: urls.square_small || null,
			medium: urls.square_medium || null,
			original: urls.original || null,
		},
		info: {
			width: info.width || 0,
			height: info.height || 0,
			fileSize: covertFileSize(size, 0),
		},
		createdAt: mediaData.created_at ? datetimeFormat(mediaData.created_at) : null,
	};
};

export const transformedMediaBanner = (media) => {
	const mediaData = media || {};
	const urls = mediaData.urls || {};
	const info = mediaData.info || {};
	const size = info.size || 0;

	return {
		id: mediaData.id || null,
		name: mediaData.name || null,
		fileName: mediaData.file_name || null,
		urls: {
			thumbnail: urls.thumbnail || null,
			small: urls.square_small || null,
			medium: urls.square_medium || null,
			original: urls.original || null,
			bundleDesktopBannerMedium: urls.bundle_desktop_banner_medium || null,
			bundleMobileBannerMedium: urls.bundle_mobile_banner_medium || null,
			category1BannerMedium: urls.category_1_banner_medium || null,
			flashSaleDesktopBannerMedium: urls.flash_sale_desktop_banner_medium || null,
			flashSaleMobileBannerMedium: urls.flash_sale_mobile_banner_medium || null,
			homepageDesktopBannerMedium: urls.homepage_desktop_banner_medium || null,
			homepageMobileBannerMedium: urls.homepage_mobile_banner_medium || null,
		},
		info: {
			width: info.width || 0,
			height: info.height || 0,
			fileSize: covertFileSize(size, 0),
		},
		createdAt: mediaData.created_at ? datetimeFormat(mediaData.created_at) : null,
	};
};

