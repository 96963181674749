export const WIDGET_VISIBILITY = {
	true: { name: 'On', value: 'on' },
	false: { name: 'Off', value: 'off' },
};

export const WIDGET_TYPE = {
	hero: 'hero_banner',
	highlight: 'highlight_banner',
	small: 'small_banner',
	shortcut: 'shortcut',
	content: 'content_banner',
	flashSale: 'flash_sale',
	products: 'products',
	category: 'category',
	brandBanner: 'brand_banner',
	productHighlight: 'product_highlight',
	text: 'text_widget',
	picture: 'picture_thumbnail',
};

export const WIDGET_TYPE_LABEL = {
	[WIDGET_TYPE.hero]: 'Hero banner',
	[WIDGET_TYPE.highlight]: 'Highlight banner',
	[WIDGET_TYPE.small]: 'Small banner',
	[WIDGET_TYPE.flashSale]: 'Flash sale',
	[WIDGET_TYPE.products]: 'Product',
	[WIDGET_TYPE.shortcut]: 'Shortcut banner',
	[WIDGET_TYPE.content]: 'Content banner',
	[WIDGET_TYPE.brandBanner]: 'Brand banner',
	[WIDGET_TYPE.productHighlight]: 'Product highlight banner',
	[WIDGET_TYPE.text]: 'Text widget banner',
	[WIDGET_TYPE.picture]: 'Picture thumbnail banner',
};

export const WIDGET_TYPE_EDITABLE = [
	WIDGET_TYPE.highlight,
	WIDGET_TYPE.small,
	WIDGET_TYPE.flashSale,
	WIDGET_TYPE.shortcut,
	WIDGET_TYPE.content,
	WIDGET_TYPE.products,
	WIDGET_TYPE.brandBanner,
	WIDGET_TYPE.productHighlight,
	WIDGET_TYPE.text,
	WIDGET_TYPE.picture,
];

export const WIDGET_STATUSES = {
	ongoing: 'Ongoing',
	scheduled: 'Scheduled',
	active: 'Active',
	inactive: 'Inactive',
};

export const WIDGET_STORE_ID = {
	BNN: 1,
	MICROSITE: 2,
};
