import { i18n } from '@/i18n';
import {
	getProductLabelAPI,
	getProductLabelsAPI,
	updateProductLabelAPI,
	deleteProductLabelAPI,
	postProductLabelAPI,
	postProductLabelDefaultAPI,
	sortProductLabelsAPI,
	putProductLabelBulkAttachAPI,
} from '@/services/api/productLabels.api';
import {
	CREATE_PRODUCT_LABEL_REQUEST,
	CREATE_PRODUCT_LABEL_SUCCESS,
	CREATE_PRODUCT_LABEL_FAILURE,
	CREATE_PRODUCT_LABEL_DEFAULT_REQUEST,
	CREATE_PRODUCT_LABEL_DEFAULT_SUCCESS,
	CREATE_PRODUCT_LABEL_DEFAULT_FAILURE,
	GET_PRODUCT_LABEL_REQUEST,
	GET_PRODUCT_LABEL_SUCCESS,
	GET_PRODUCT_LABEL_FAILURE,
	GET_PRODUCT_LABELS_REQUEST,
	GET_PRODUCT_LABELS_SUCCESS,
	GET_PRODUCT_LABELS_FAILURE,
	SORT_PRODUCT_LABEL_REQUEST,
	SORT_PRODUCT_LABEL_SUCCESS,
	SORT_PRODUCT_LABEL_FAILURE,
	UPDATE_PRODUCT_LABEL_REQUEST,
	UPDATE_PRODUCT_LABEL_SUCCESS,
	UPDATE_PRODUCT_LABEL_FAILURE,
	DELETE_PRODUCT_LABEL_REQUEST,
	DELETE_PRODUCT_LABEL_SUCCESS,
	DELETE_PRODUCT_LABEL_FAILURE,
	BULK_ATTACH_PRODUCT_LABEL_REQUEST,
	BULK_ATTACH_PRODUCT_LABEL_SUCCESS,
	BULK_ATTACH_PRODUCT_LABEL_FAILURE,
} from '../types';

import { PRODUCT_LABEL_TYPES, ERROR_REMOVE_DEFAULT_LABEL_KEY } from '../../enums/productLabels';
import { transformedProductLabels, transformedProductLabel } from '../../assets/js/transform/productLabels';

export default {
	namespaced: true,
	state: {
		create: {
			isLoading: false,
			isSuccess: false,
		},
		edit: {
			data: {},
			isLoading: false,
		},
		sort: {
			isLoading: false,
		},
		list: {
			all: [],
			primary: [],
			secondary: [],
			frame: [],
			default: [],
			isLoading: false,
		},
		bulkAttach: {
			isLoading: false,
			isSuccess: false,
		},
	},
	getters: {
		getPrimaryLabels(state) {
			return transformedProductLabels(state.list.primary);
		},
		getSecondaryLabels(state) {
			return transformedProductLabels(state.list.secondary);
		},
		getFrameLabels(state) {
			return transformedProductLabels(state.list.frame);
		},
		getAllLabels(state) {
			return transformedProductLabels(state.list.all);
		},
		getLabel(state) {
			return transformedProductLabel(state.edit.data);
		},
	},
	mutations: {
		[CREATE_PRODUCT_LABEL_REQUEST](state) {
			state.create.isLoading = true;
			state.create.isSuccess = false;
		},
		[CREATE_PRODUCT_LABEL_SUCCESS](state) {
			state.create.isLoading = false;
			state.create.isSuccess = true;
		},
		[CREATE_PRODUCT_LABEL_FAILURE](state) {
			state.create.isLoading = false;
			state.create.isSuccess = false;
		},
		[CREATE_PRODUCT_LABEL_DEFAULT_REQUEST](state) {
			state.create.isLoading = true;
			state.create.isSuccess = false;
		},
		[CREATE_PRODUCT_LABEL_DEFAULT_SUCCESS](state) {
			state.create.isLoading = false;
			state.create.isSuccess = true;
		},
		[CREATE_PRODUCT_LABEL_DEFAULT_FAILURE](state) {
			state.create.isLoading = false;
			state.create.isSuccess = false;
		},
		[GET_PRODUCT_LABELS_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_PRODUCT_LABELS_SUCCESS](state, { data, type }) {
			state.list.isLoading = false;

			switch (type) {
				case PRODUCT_LABEL_TYPES.primary:
					state.list.primary = data.data;
					break;

				case PRODUCT_LABEL_TYPES.secondary:
					state.list.secondary = data.data;
					break;

				case PRODUCT_LABEL_TYPES.frame:
					state.list.frame = data.data;
					break;

				default:
					state.list.all = data.data;
					break;
			}
		},
		[GET_PRODUCT_LABELS_FAILURE](state) {
			state.list.isLoading = false;
		},
		[GET_PRODUCT_LABEL_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_PRODUCT_LABEL_SUCCESS](state, { data }) {
			state.edit.data = data;
			state.edit.isLoading = false;
		},
		[GET_PRODUCT_LABEL_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[SORT_PRODUCT_LABEL_REQUEST](state) {
			state.sort.isLoading = true;
		},
		[SORT_PRODUCT_LABEL_SUCCESS](state) {
			state.sort.isLoading = false;
		},
		[SORT_PRODUCT_LABEL_FAILURE](state) {
			state.sort.isLoading = false;
		},
		[UPDATE_PRODUCT_LABEL_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[UPDATE_PRODUCT_LABEL_SUCCESS](state) {
			state.edit.isLoading = false;
		},
		[UPDATE_PRODUCT_LABEL_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[DELETE_PRODUCT_LABEL_REQUEST]() {},
		[DELETE_PRODUCT_LABEL_SUCCESS]() {},
		[DELETE_PRODUCT_LABEL_FAILURE]() {},
		[BULK_ATTACH_PRODUCT_LABEL_REQUEST](state) {
			state.bulkAttach.isLoading = true;
			state.bulkAttach.isSuccess = false;
		},
		[BULK_ATTACH_PRODUCT_LABEL_SUCCESS](state) {
			state.bulkAttach.isLoading = false;
			state.bulkAttach.isSuccess = true;
		},
		[BULK_ATTACH_PRODUCT_LABEL_FAILURE](state) {
			state.bulkAttach.isLoading = false;
			state.bulkAttach.isSuccess = false;
		},
	},
	actions: {
		async postProductLabel({ commit }, formData) {
			try {
				commit(CREATE_PRODUCT_LABEL_REQUEST);
				await postProductLabelAPI(formData);
				commit(CREATE_PRODUCT_LABEL_SUCCESS);
			} catch (error) {
				commit(CREATE_PRODUCT_LABEL_FAILURE);

				throw error;
			}
		},
		async postProductLabelDefault({ commit, dispatch }, labels) {
			try {
				commit(CREATE_PRODUCT_LABEL_DEFAULT_REQUEST);
				await postProductLabelDefaultAPI(labels);
				commit(CREATE_PRODUCT_LABEL_DEFAULT_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PRODUCT_LABEL_DEFAULT_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async getProductLabels({ commit }, params = {}) {
			try {
				commit(GET_PRODUCT_LABELS_REQUEST);
				const { data } = await getProductLabelsAPI(params);
				commit(GET_PRODUCT_LABELS_SUCCESS, { data, type: params.type });
			} catch (error) {
				commit(GET_PRODUCT_LABELS_FAILURE);

				throw error;
			}
		},
		async getProductLabel({ commit }, id) {
			try {
				commit(GET_PRODUCT_LABEL_REQUEST);
				const { data } = await getProductLabelAPI(id);
				commit(GET_PRODUCT_LABEL_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_LABEL_FAILURE);

				throw error;
			}
		},
		async updateProductLabel({ commit, dispatch }, { id, formData = null }) {
			try {
				commit(UPDATE_PRODUCT_LABEL_REQUEST);

				await updateProductLabelAPI(id, formData);
				await dispatch('getProductLabel', id);

				commit(UPDATE_PRODUCT_LABEL_SUCCESS);
			} catch (error) {
				commit(UPDATE_PRODUCT_LABEL_FAILURE);
				throw error;
			}
		},
		async deleteProductLabel({ commit, dispatch }, id) {
			try {
				commit(DELETE_PRODUCT_LABEL_REQUEST);

				await deleteProductLabelAPI(id);

				commit(DELETE_PRODUCT_LABEL_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Product label' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_PRODUCT_LABEL_FAILURE);
				const errorKey = error?.response?.data?.error ?? null;

				// Show error toast when error is not related to remove default label
				if (errorKey !== ERROR_REMOVE_DEFAULT_LABEL_KEY) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleDelete'),
						type: 'danger',
					}, { root: true });
				}

				throw error;
			}
		},
		async sortProductLabels({ commit, dispatch }, params = {}) {
			try {
				commit(SORT_PRODUCT_LABEL_REQUEST);
				await sortProductLabelsAPI(params);
				commit(SORT_PRODUCT_LABEL_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(SORT_PRODUCT_LABEL_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async sortAllProductLabels({ commit, dispatch }, { primaryLabelIds = [], secondaryLabelIds = [] }) {
			// Update Primary
			const updatePrimary = sortProductLabelsAPI({
				type: PRODUCT_LABEL_TYPES.primary,
				label_ids: primaryLabelIds,
			});

			// Update Secondary
			const updateSecondary = sortProductLabelsAPI({
				type: PRODUCT_LABEL_TYPES.secondary,
				label_ids: secondaryLabelIds,
			});

			try {
				commit(SORT_PRODUCT_LABEL_REQUEST);
				await Promise.all([updatePrimary, updateSecondary]);
				commit(SORT_PRODUCT_LABEL_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(SORT_PRODUCT_LABEL_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async putProductLabelBulkAttach({ commit }, params = {}) {
			try {
				commit(BULK_ATTACH_PRODUCT_LABEL_REQUEST);
				await putProductLabelBulkAttachAPI(params);
				commit(BULK_ATTACH_PRODUCT_LABEL_SUCCESS);
			} catch (error) {
				commit(BULK_ATTACH_PRODUCT_LABEL_FAILURE);

				throw error;
			}
		},
	},
};
