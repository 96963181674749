import { pathOr } from '../helpers';

export const transformedSubHeader = (subHeader = {}) => {
	const contentTH = pathOr(null, ['content_th'])(subHeader);
	const contentEN = pathOr(null, ['content_en'])(subHeader);
	const isContenVisible = pathOr(false, ['is_content_visible'])(subHeader);
	const deskTopBannerContentTH = pathOr(null, ['desktop', 'banner_content_th'])(subHeader);
	const deskTopBannerContentEN = pathOr(null, ['desktop', 'banner_content_en'])(subHeader);
	const mobileBannerContentTH = pathOr(null, ['mobile', 'banner_content_th'])(subHeader);
	const mobileBannerContentEN = pathOr(null, ['mobile', 'banner_content_en'])(subHeader);
	const isBannerContentVisible = pathOr(false, ['is_banner_content_visible'])(subHeader);
	const type = pathOr(null, ['type'])(subHeader);
	const link = pathOr(null, ['link'])(subHeader);

	return {
		contentTH,
		contentEN,
		isContenVisible,
		deskTopBannerContentTH,
		deskTopBannerContentEN,
		mobileBannerContentTH,
		mobileBannerContentEN,
		isBannerContentVisible,
		type,
		link,
	};
};