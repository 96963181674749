export const SUB_HEADER_TABS = Object.freeze([
	'HOMEPAGE',
	'CART',
	'STATIC_PAGE',
]);

export const SUB_HEADER_TYPE = {
	homepage: 'homepage',
	cart: 'cart',
	staticPage: 'static_page',
};

export const SUB_HEADER_COMPONENTS = Object.freeze({
	HOMEPAGE: {
		title: 'Homepage',
		component: 'SubHeaderHomepage',
		routerTo: 'SubHeaders',
	},
	CART: {
		title: 'Cart',
		component: 'SubHeaderCart',
		routerTo: 'SubHeaderCart',
	},
	STATIC_PAGE: {
		title: 'Static page',
		component: 'SubHeaderStaticPage',
		routerTo: 'SubHeaderStaticPage',
	},
});
