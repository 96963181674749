import { pathOr } from '../helpers';

export const transformedCustomerGroup = (customerGroup = {}) => {
	const id = pathOr(null, ['id'])(customerGroup);
	const name = pathOr(null, ['name'])(customerGroup);
	const description = pathOr(null, ['description'])(customerGroup);
	const total = pathOr(0, ['total_customer'])(customerGroup);
	const isDeletable = !!pathOr(false, ['is_deletable'])(customerGroup);

	return {
		id,
		name,
		description,
		total,
		isDeletable,
	};
};
