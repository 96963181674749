<template>
	<div role="group" class="form-group">
		<label v-if="labelDropDown">{{ labelDropDown }}</label>
		<div
			:class="['wrapper-select', {
				'is-disabled': $attrs['disabled'],
				'is-invalid': $attrs['is-valid'] === false,
				'multiple-options': this.$attrs.multiple
			}]"
		>
			<slot class="prepend-icon" name="prepend"></slot>
			<Multiselect
				v-bind="{ ...this.$attrs, showLabels: false }"
				:class="{ 'is-error': $attrs['is-valid'] === false }"
				v-on="$listeners"
			/>
			<slot name="append-Icon"></slot>
		</div>
		<div
			v-if="isShowInvalidFeedback"
			class="invalid-feedback"
		>
			{{ $attrs['invalid-feedback'] }}
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
	name: 'BaseDropDown',
	components: {
		Multiselect,
	},
	props: {
		labelDropDown: {
			type: String,
			default: '',
		},
	},
	computed: {
		isShowInvalidFeedback() {
			return this.$attrs['is-valid'] === false;
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .multiselect {
		border-radius: 4px;
		height: rem(34);
		min-height: rem(34);

		.multiselect__tags {
			display: block;
			width: 100%;
			height: inherit;
			min-height: inherit;
			padding-top: rem(6);
			padding-left: rem(12);
			color: $color-black-90;
			font-size: rem(14);
			border: 1px solid $color-gray-400;
			background-color: $color-white;
			border-radius: 4px;

			.multiselect__single {
				@include ellipsisOneline;

				font-size: inherit;
				padding-left: 0;
			}

			.multiselect__placeholder {
				padding-top: 0;
				margin-bottom: 0;
				color: $color-black-45;
			}
		}

		.multiselect__element {
			font-size: rem(14);

			.multiselect__option--selected,
			.multiselect__option--highlight,
			.multiselect__option--selected.multiselect__option--highlight {
				background: $color-gray-100;
				color: inherit;
			}
		}

		.multiselect__input {
			font-size: rem(14);
		}

		.multiselect__select {
			height: rem(32); // 34 - 2 from border
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;

			&::before {
				position: static;
				margin-top: 0;
			}
		}

		.multiselect__single {
			background: transparent;
		}

		.multiselect__option {
			word-break: break-word;
			white-space: inherit;
		}

		.multiselect__tag {
			color: $color-black-90;
			background-color: $color-gray-300;
			margin-bottom: 0;
			border-radius: 4px;

			.multiselect__tag-icon {
				&::after {
					font-size: rem(20);
					font-weight: 400;
					color: $color-black-45;
				}

				&:hover {
					background-color: $color-gray-300;
				}
			}
		}
	}

	::v-deep .multiselect--active {
		.multiselect__tags {
			background-color: $color-white;
			border-color: $color-primary;
			outline: 0;
			box-shadow: $shadow-text-input;
		}
	}

	::v-deep .multiselect--disabled {
		opacity: 1;

		.multiselect__tags,
		.multiselect__select {
			border-color: $color-gray-200;
			background-color: $color-gray-200;
			cursor: not-allowed;

			.multiselect__single,
			.multiselect__placeholder {
				color: $color-black-45;
			}
		}
	}

	// Error
	::v-deep .multiselect.is-error {
		.multiselect__tags {
			border-color: $color-alert;
		}

		.multiselect__select::before {
			border-color: $color-alert transparent transparent;
		}
	}

	::v-deep .multiselect.is-error.multiselect--active {
		.multiselect__tags {
			box-shadow: $shadow-text-input-error;
		}
	}

	.multiple-options {
		::v-deep .multiselect {
			height: auto;
		}
	}
</style>
