// For on site messaging list
export const ON_SITE_MESSAGE_TABS = Object.freeze([
	'ON_SITE_MESSAGE_POPUP',
	'ON_SITE_MESSAGE_STRIP',
]);

export const ON_SITE_MESSAGE_COMPONENTS = Object.freeze({
	ON_SITE_MESSAGE_POPUP: {
		title: 'Popup campaign',
		component: 'OnSiteMessageGroupList',
		routerTo: 'OnSiteMessagePopupList',
		routerToCreate: 'OnSiteMessagePopupCreate',
		routerToEdit: 'OnSiteMessagePopupEdit',
	},
	ON_SITE_MESSAGE_STRIP: {
		title: 'Strip bar campaign',
		component: 'OnSiteMessageGroupList',
		routerTo: 'OnSiteMessageStripList',
		routerToCreate: 'OnSiteMessageStripCreate',
		routerToEdit: 'OnSiteMessageStripEdit',
	},
});


export const TYPES = {
	POPUP: 'POPUP',
	STRIP: 'STRIP',
};

export const TYPE_LABELS = {
	[TYPES.POPUP]: 'Popup Campaign',
	[TYPES.STRIP]: 'Strip Campaign',
};

export const TYPE_TABS = [
	{
		value: TYPES.POPUP,
		label: TYPE_LABELS[TYPES.POPUP],
	},
	{
		value: TYPES.STRIP,
		label: TYPE_LABELS[TYPES.STRIP],
	},
];

export const STATUSES = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const STATUS_LABELS = {
	[STATUSES.ACTIVE]: 'Active',
	[STATUSES.INACTIVE]: 'Inactive',
};

export const STATUS_OPTIONS = [
	{
		value: null,
		label: 'All status',
	},
	{
		value: STATUSES.ACTIVE,
		label: STATUS_LABELS[STATUSES.ACTIVE],
	},
	{
		value: STATUSES.INACTIVE,
		label: STATUS_LABELS[STATUSES.INACTIVE],
	},
];

export const MESSAGE_STATUSES = {
	true: {
		label: STATUS_LABELS[STATUSES.ACTIVE],
		value: STATUSES.ACTIVE,
	},
	false: {
		label: STATUS_LABELS[STATUSES.INACTIVE],
		value: STATUSES.INACTIVE,
	},
};

export const PERIOD_STATUSES = {
	ON_GOING: 'ON_GOING',
	SCHEDULE: 'SCHEDULE',
	EXPIRED: 'EXPIRED',
};

export const PERIOD_STATUS_LABELS = {
	[PERIOD_STATUSES.ON_GOING]: 'Ongoing',
	[PERIOD_STATUSES.SCHEDULE]: 'Scheduled',
	[PERIOD_STATUSES.EXPIRED]: 'Expired',
};

export const PERIOD_STATUS_OPTIONS = [
	{
		value: null,
		label: 'All period status',
	},
	{
		value: PERIOD_STATUSES.ON_GOING,
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.ON_GOING],
	},
	{
		value: PERIOD_STATUSES.SCHEDULE,
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.SCHEDULE],
	},
	{
		value: PERIOD_STATUSES.EXPIRED,
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.EXPIRED],
	},
];

export const MESSAGE_PERIOD_STATUSES = {
	[PERIOD_STATUSES.ON_GOING]: {
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.ON_GOING],
		value: PERIOD_STATUSES.ON_GOING,
	},
	[PERIOD_STATUSES.SCHEDULE]: {
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.SCHEDULE],
		value: PERIOD_STATUSES.SCHEDULE,
	},
	[PERIOD_STATUSES.EXPIRED]: {
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.EXPIRED],
		value: PERIOD_STATUSES.EXPIRED,
	},
};

// mapping period status color,
export const PERIOD_STATUS_COLOR = {
	[PERIOD_STATUSES.ON_GOING]: 'orange',
	[PERIOD_STATUSES.SCHEDULE]: 'info',
	[PERIOD_STATUSES.EXPIRED]: 'alto',
};

export const STATUS_COLOR = {
	[STATUSES.ACTIVE]: 'active',
	[STATUSES.INACTIVE]: 'inactive',
};

export const TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id th-mw-30' },
	{ key: 'name', label: 'Campaign Name', class: 'col-name th-mw-250' },
	{ key: 'periodStatus', label: 'Period', class: 'col-period th-mw-80' },
	{ key: 'startAt', label: 'Start date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'endAt', label: 'End date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'status', label: 'Status', class: 'col-status' },
]);
