import { pathOr, covertTimeFromAPI } from '../helpers';

export const transformedBusinessHour = (businessHour = {}) => {
	const day = pathOr(null, ['day'])(businessHour);
	const from = pathOr(null, ['hour', 'from'])(businessHour);
	const to = pathOr(null, ['hour', 'to'])(businessHour);
	const isActive = pathOr(false, ['is_active'])(businessHour);

	return {
		day,
		from: from ? covertTimeFromAPI(from) : null,
		to: to ? covertTimeFromAPI(to) : null,
		isActive,
	};
};

export const transformedAddressInfo = (addressInfo = {}) => {
	const addressTH = pathOr(null, ['address_th'])(addressInfo);
	const addressEN = pathOr(null, ['address_en'])(addressInfo);
	const zone = pathOr(null, ['zone'])(addressInfo);
	const provinceId = pathOr(null, ['province_id'])(addressInfo);
	const districtId = pathOr(null, ['district_id'])(addressInfo);
	const subdistrictId = pathOr(null, ['subdistrict_id'])(addressInfo);
	const postcode = pathOr(null, ['postcode'])(addressInfo);
	const phone = pathOr(null, ['phone'])(addressInfo);
	const deliveryPhone = pathOr(null, ['delivery_phone_number'])(addressInfo);
	const email = pathOr(null, ['email'])(addressInfo);
	const latitude = pathOr(null, ['latitude'])(addressInfo);
	const longitude = pathOr(null, ['longitude'])(addressInfo);

	return {
		addressTH,
		addressEN,
		zone,
		provinceId,
		districtId,
		subdistrictId,
		postcode,
		phone,
		deliveryPhone,
		email,
		latitude,
		longitude,
	};
};

export const transformedWarehouse = (warehouse = {}) => {
	const id = pathOr(0, ['id'])(warehouse);
	const logo = warehouse && Array.isArray(warehouse.logo) && pathOr(null, ['logo'])(warehouse)[0];
	const nameEN = pathOr('', ['name_en'])(warehouse);
	const nameTH = pathOr('', ['name_th'])(warehouse);
	const com7BranchId = pathOr('', ['com7_branch_id'])(warehouse).toString();
	const isActive = pathOr(false, ['is_active'])(warehouse);
	const description = pathOr('', ['description'])(warehouse);
	const addressInfo = transformedAddressInfo(pathOr({}, ['address_info'])(warehouse));
	const provinceTH = pathOr('', ['address_info', 'province', 'name_th'])(warehouse);
	const businessHours = pathOr([], ['business_hours'])(warehouse).map(transformedBusinessHour);
	const remark = pathOr('', ['remark'])(warehouse);
	const deliveryAreas = pathOr([], ['postcodes'])(warehouse).map((postcode) => postcode.id);
	const warehouseType = {
		id: pathOr(null, ['branch_brand_type', 'id'])(warehouse),
		name: pathOr(null, ['branch_brand_type', 'name'])(warehouse),
	};

	return {
		id,
		logo,
		nameEN,
		nameTH,
		com7BranchId,
		isActive,
		description,
		addressInfo,
		provinceTH,
		businessHours,
		remark,
		deliveryAreas,
		warehouseType,
	};
};

export const transformedWarehouseList = (warehouses = []) => {
	return warehouses.map(transformedWarehouse);
};

export const transformedWarehouseType = (type = {}) => ({
	id: type.id || null,
	name: type.name || null,
});

export const transformedWarehouseTypeList = (types = []) => {
	return types.map(transformedWarehouseType);
};

export const transformedWarehouseDropdownOption = (warehouse = {}) => {
	return {
		name: warehouse.name || null,
		value: warehouse?.value ? `${warehouse.value}` : null,
	};
};
