export const CATEGORY_LEVEL = Object.freeze({
	ONE: 'ONE',
	TWO: 'TWO',
	THREE: 'THREE',
});

export const CATEGORY_ATTRIBUTE_STATUS_OPTIONS = [
	{ value: null, name: 'All status' },
	{ value: '1', name: 'On' },
	{ value: '0', name: 'Off' },
];

export const CATEGORY_LEVEL_OPTIONS = [
	{ value: CATEGORY_LEVEL.ONE, label: 'Level 1' },
	{ value: CATEGORY_LEVEL.TWO, label: 'Level 2' },
	{ value: CATEGORY_LEVEL.THREE, label: 'Level 3' },
];

export const CONFIRM_DELETE_TEXT = 'REMOVE';

export const TABLE_ATTRIBUTE_KEY_FIELDS = [
	{ key: 'name', label: 'Attribute', class: 'col-name' },
	{ key: 'data', label: 'Filter visibility', class: 'col-id text-right w-200' },
];

export const MAPPING_SWITCH_VALUE_TO_STATUS = {
	[true]: 'on',
	[false]: 'off',
};
