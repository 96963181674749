import { transformedUserInfo } from '../../assets/js/transform/user';
import { getProfileAPI, updateProfileAPI } from '../../services/api/profile.api';
import {
	GET_PROFILE_SUCCESS,
	GET_PROFILE_FAILURE,
	GET_PROFILE_REQUEST,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAILURE,
	UPDATE_PROFILE_REQUEST,
	CLEAR_PROFILE,
} from '../types';
import { ROUTE_PERMISSION_KEY, ROUTE_PERMISSION_NAME } from '../../enums/permissions';
import { HTTP_STATUS_CODE } from '../../enums/http';

const flattenPermissions = (permissions) => {
	return permissions.reduce((acc, permission) => {
		let temp = [...acc];
		if (permission.key) {
			temp = [
				...temp,
				{
					key: permission.key,
					name: permission.name,
					permissions: permission.permissions,
				},
			];
		}
		if (permission.children) {
			temp = [
				...acc,
				...permission.children,
			];
		}
		return [...temp];
	}, []);
};

export default {
	namespaced: true,
	state: {
		profile: {
			isLoading: false,
			isUpdating: false,
			data: {},
		},
		permissions: [],
		isError500: false,
	},
	getters: {
		getProfile(state) {
			return state.profile.data;
		},
		// return route name for redirecting after login if have no any permissions, it will return forbidden page
		getDefaultRouteName(state) {
			const permission = state.permissions.find((val) => val.permissions.view) || [];
			return ROUTE_PERMISSION_NAME[permission.key] || ROUTE_PERMISSION_KEY.FORBIDDEN;
		},
		hasManageShipmentPermission(state) {
			const shipmentPermissionKey = ROUTE_PERMISSION_KEY.SHIPMENT;
			const shipmentPermission = state.permissions.find((permisison) => permisison.key === shipmentPermissionKey);

			return shipmentPermission && shipmentPermission.permissions.manage;
		},
	},
	mutations: {
		[GET_PROFILE_REQUEST](state) {
			state.profile = {
				...state.profile,
				isLoading: true,
			};
			state.permissions = [];
			state.isError500 = false;
		},
		[GET_PROFILE_SUCCESS](state, { data }) {
			state.profile = {
				...state.profile,
				isLoading: false,
				data: transformedUserInfo(data),
			};
			state.permissions = flattenPermissions(data?.role?.permissions || []);
		},
		[GET_PROFILE_FAILURE](state, error = {}) {
			const errorStatus = error?.response?.status;

			state.profile = {
				...state.profile,
				isLoading: false,
			};
			state.permissions = [];
			state.isError500 = errorStatus === HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR;
		},
		[UPDATE_PROFILE_REQUEST](state) {
			state.profile = {
				...state.profile,
				isUpdating: true,
			};
		},
		[UPDATE_PROFILE_SUCCESS](state, data) {
			state.profile = {
				...state.profile,
				isUpdating: false,
				data: transformedUserInfo(data.data),
			};
		},
		[UPDATE_PROFILE_FAILURE](state) {
			state.profile = {
				...state.profile,
				isUpdating: false,
			};
		},
		[CLEAR_PROFILE](state) {
			state.profile = {
				isLoading: false,
				isUpdating: false,
				data: {},
			};

			state.permissions = [];
		},
	},
	actions: {
		async getProfile({ commit }) {
			try {
				commit(GET_PROFILE_REQUEST);
				const { data } = await getProfileAPI();
				commit(GET_PROFILE_SUCCESS, data);
			} catch (error) {
				commit(GET_PROFILE_FAILURE, error);
			}
		},
		async updateProfile({ commit, dispatch }, params) {
			try {
				commit(UPDATE_PROFILE_REQUEST);
				const { data } = await updateProfileAPI(params);
				commit(UPDATE_PROFILE_SUCCESS, data);
				dispatch('toast/showUpdateSuccessToast', null, { root: true });
			} catch (error) {
				commit(UPDATE_PROFILE_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });

				throw error;
			}
		},
		clearProfile({ commit }) {
			commit(CLEAR_PROFILE);
		},
	},
};
