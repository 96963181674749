export default {
	searchNotFound: 'We didn\'t find any {field} matching your criteria.',
	successMessageTitle: 'Success!',
	successMessage: 'Your changes have been saved.',
	successMessageTitleDelete: 'Removed',
	successMessageDelete: '{type} has been removed.',
	successMessageCreate: 'New {type} has been created.',
	errorMessageTitleGetData: 'Unable to get data',
	errorMessageTitleEdit: 'Unable to save',
	errorMessageTitleCreate: 'Unable to create',
	errorMessageTitleDelete: 'Unable to remove',
	errorMessageTitleSend: 'Unable to send',
	errorMessageExport: 'Unable to export',
	errorMessage: 'An unexpected error occurred. Please try again.',
	error: {
		required: 'Required field.',
		invalid: 'Invalid value',
		maximumPercentage: 'Please input the maximum number 100.',
		maxFileSize: 'Allow only {size} MB for image.',
		minimumNumber: 'At least {number}',
		maximumNumber: 'Maximum {number}',
		emailInvalidFormat: 'Invalid email',
		phoneInvalidFormat: 'Invalid phone number',
		timeInvalidFormat: 'Time format is invalid',
		timeAfter: 'Should be after {time}',
		url: 'Invalid Url',
		between: 'Must be between {min} and {max} {unit}',
		exist: 'This value already exist',
		datetimeAfterNow: 'The datetime is less than current time',
		maximumLength: 'Maximum {field} length is {number}',
		minimumLength: 'Must contain {number} digits',
		phoneNumberMustStartWithZero: 'Phone number should start with zero',
		lengthCharacters: 'Field Length: {minLength} - {maxLength} characters',
		maximumCharacters: 'Field Length: 1 - 255 characters',
		recommendCharacters: 'Recommend {number} characters',
	},
	status: {
		active: 'Active',
		inActive: 'Inactive',
	},
	visibility: {
		on: 'On',
		off: 'Off',
	},
	slug: {
		slugFormat: 'Allow letters, numbers, and ( - )',
		duplicateSlug: 'Slug already exists',
	},
	seperateBySemicolon: 'Use semicolon (;) to input multiple items',
	alternateProductUrl: 'This link will be a replacement of product detail link. System will redirect to this link only when product detail visibility is hidden (not apply on product group). Please leave it blank to use product slug',
};
