import { WIDGET_TYPE, WIDGET_VISIBILITY, WIDGET_TYPE_EDITABLE } from '../../../enums/widgets';
import { pathOr } from '../helpers';

export const linkToBanner = (widget = {}) => {
	const type = pathOr(null, ['type'])(widget);
	const items = pathOr([], ['items'])(widget);

	// Count number of banner that visible
	const number = items.filter((item) => item.is_visible).length;
	if (type === WIDGET_TYPE.highlight) {
		return number === 0 ? 'Add banner' : `${number} set(s)`;
	}

	return number === 0 ? 'Add banner' : `${number} banner(s)`;
};

export const linkToFlashSale = (widget = {}) => {
	return pathOr(null, ['items', 'name'])(widget);
};

export const linkToProducts = (widget = {}) => {
	const name = pathOr(null, ['items', '0', 'name_en'])(widget);

	return name || 'Add products';
};

export const transformedWidgetList = (widget = {}) => {
	const id = pathOr(null, ['id'])(widget);
	const name = pathOr(null, ['name'])(widget);
	const titleEN = pathOr(null, ['title_en'])(widget);
	const titleTH = pathOr(null, ['title_th'])(widget);
	const link = pathOr(null, ['link'])(widget);

	const type = pathOr(null, ['type'])(widget);
	const order = pathOr(null, ['order'])(widget);
	const visibility = WIDGET_VISIBILITY[!!pathOr(false, ['is_active'])(widget)];
	const data = pathOr([], ['items'])(widget);

	let linkToName = '';
	let status = null;
	let categoryId = null;

	const normalBanners = [
		WIDGET_TYPE.hero,
		WIDGET_TYPE.highlight,
		WIDGET_TYPE.small,
		WIDGET_TYPE.text,
		WIDGET_TYPE.picture,
		WIDGET_TYPE.content,
		WIDGET_TYPE.shortcut,
	];

	if (normalBanners.includes(type)) {
		linkToName = linkToBanner(widget);
	} else if (type === WIDGET_TYPE.flashSale) {
		linkToName = linkToFlashSale(widget);
		status = pathOr(null, ['items', 'period'])(widget);
	} else if (type === WIDGET_TYPE.products) {
		linkToName = linkToProducts(widget);
		status = pathOr(null, ['items', '0', 'period_status'])(widget);
	} else if (type === WIDGET_TYPE.category) {
		categoryId = pathOr(null, ['items', 'category_id'])(widget);
	}

	const linkTo = {
		id,
		name: linkToName,
		status,
		type,
	};

	const editable = WIDGET_TYPE_EDITABLE.includes(type);

	const edit = {
		id,
		editable,
	};

	return {
		id,
		name,
		titleEN,
		titleTH,
		link,
		type,
		order,
		visibility,
		linkTo,
		edit,
		data,
		categoryId,
	};
};
