export default Object.freeze({
	DEFAULT_TITLE: 'Education Studio7 - Back-office',
	USER_MANAGEMENT: {
		LIST: 'User management',
		CREATE_NEW_USER: 'Create new user',
		UPDATE_USER: 'Update user',
		PROFILE: 'My profile',
		ROLE: 'Role & permission',
		CREATE_ROLE: 'Role & permission',
	},
	CATEGORY: {
		LIST: 'Category management',
		CREATE: 'Create category',
		ATTRIBUTE_EDIT: 'Manage filters',
	},
	CUSTOMER_MANAGEMENT: {
		LIST: 'Customer management',
		CREATE: 'Create customer account',
	},
	CUSTOMER_GROUP: {
		CREATE: 'Create customer group',
		LIST: 'Customer group',
		EDIT: 'Edit customer group',
	},
	PRODUCT: {
		LIST: 'Products',
		EDIT: 'Product detail',
		IMPORT_HISTORY: 'Product Import History',
	},
	ATTRIBUTE: {
		SET: 'Attribute Set',
		KEY: 'Attribute Key',
	},
	PRODUCT_LABEL: {
		LIST: 'Product Label',
		CREATE: 'Create new primary label',
	},
	PROMOTION: {
		LIST: 'Promotions',
		CREATE: 'Create new promotion',
		EDIT: 'Promotion detail',
	},
	PAYMENTS: {
		LIST: 'Payment setting',
	},
	SHIPPING: {
		SETTING: 'Shipping setting',
	},
	ORDER: {
		LIST: 'Orders',
	},
	SHIPMENT: {
		LIST: 'Shipments',
	},
	RATES: {
		CREATE: 'Create new base shipping fee',
		EDIT: 'Edit base shipping fee',
	},
	SEARCH_SYNONYMS: {
		LIST: 'Search synonyms',
	},
	SEARCH_WEIGHT: {
		CREATE: 'Create new rule',
		EDIT: 'Edit search weight',
		LIST: 'Search weight',
	},
	MARKETING_CAMPAIGN: {
		LIST: 'Marketing campaign management',
		BREADCRUMBS_LIST: 'Marketing campaign',
		CREATE: 'Create marketing campaign',
		EDIT: 'Edit marketing campaign',
		ATTRIBUTE_EDIT: 'Manage filters',
	},
	SKU_ADDITIONAL_FEE: {
		CREATE: 'Create SKUs additional fee',
		EDIT: 'Edit SKUs additional fee',
	},
	CATEGORY_ADDITIONAL_FEE: {
		CREATE: 'Create categories additional fee',
		EDIT: 'Edit categories additional fee',
	},
	POSTCODE_ADDITIONAL_FEE: {
		CREATE: 'Create postcode additional fee',
		EDIT: 'Edit postcode additional fee',
	},
	WAREHOUSE: {
		LIST: 'Warehouse/branch',
		CREATE: 'Create warehouse/branch',
		EDIT: 'Edit warehouse/branch',
	},
	PAGE: {
		LIST: 'Static page management',
		CREATE: 'Create static page',
		EDIT: 'Edit static page',
	},
	HOMEPAGE: {
		LIST: 'Homepage',
	},
	BANNER_HERO: {
		LIST: 'Hero banner',
		CREATE: 'Create hero banner',
		EDIT: 'Edit hero banner',
	},
	BANNER_HIGHLIGHT: {
		LIST: 'Highlight banner',
		CREATE: 'Create highlight banner',
		EDIT: 'Edit highlight banner',
	},
	BANNER_SMALL: {
		LIST: 'Small banner',
		CREATE: 'Create small banner',
		EDIT: 'Edit small banner',
	},
	BANNER_TEXT: {
		LIST: 'Text banner',
		CREATE: 'Create text banner',
		EDIT: 'Edit text banner',
	},
	BANNER_PICTURE_THUMBNAIL: {
		LIST: 'Picture Thumbnail banner',
		CREATE: 'Create Picture Thumbnail banner',
		EDIT: 'Edit Picture Thumbnail banner',
	},
	BANNER_SHORTCUT: {
		LIST: 'Shortcut banner',
		CREATE: 'Create shortcut banner',
		EDIT: 'Edit shortcut banner',
	},
	BANNER_CONTENT: {
		LIST: 'Content banner',
		CREATE: 'Create content banner',
		EDIT: 'Edit content banner',
	},
	INSTALLMENT_PLAN: {
		CREATE: 'Create installment plan',
		EDIT: 'Edit installment plan',
		CHANNEL: 'Edit 0% installment plan and available SKU',
		CREATE_CAMPAIGN: 'Create campaign installment plan',
		EDIT_CAMPAIGN: 'Edit campaign installment plan',
		MERCHANT_SUPPLIER_LIST: 'Merchant and supplier installment plan list',
		CREATE_MERCHANT_SUPPLIER: 'Create merchant and supplier installment plan',
		EDIT_MERCHANT_SUPPLIER: 'Edit merchant and supplier installment plan',
	},
	PREORDER: {
		LIST: 'Pre-order campaign',
		CREATE: 'Create pre-order campaign',
		EDIT: 'Edit pre-order campaign',
		PICKUP_AT_STORE: {
			CREATE: 'Create pickup at stores rule',
			EDIT: 'Edit pickup at stores rule',
		},
	},
	BANK_CHANNEL: {
		CREATE: 'Create bank channel',
	},
	FEED: {
		LIST: 'Product feed',
		CREATE: 'Create feed',
		EDIT: 'Product feed setting',
	},
	FLASHSALE: {
		LIST: 'Flash sale',
		CREATE: 'Create flash sale',
		EDIT: 'Edit flash sale',
	},
	AUDIT_LOG: {
		LIST: 'Audit log',
		CREATE: 'Create audit log',
		DETAIL: 'Audit log detail',
	},
	MICROSITE: {
		LIST: 'Microsite Apple',
	},
	ABANDONED_CART: {
		LIST: 'Abandoned cart report',
	},
	BUNDLE_SET: {
		LIST: 'Bundle settings',
		CREATE: 'Create bundle set',
		EDIT: 'Edit bundle set',
	},
	BACK_IN_STOCK_NOTIFICATIONS: {
		LIST: 'Back-in-stock Notification',
	},
	FOREVER_PLAN: {
		CREATE_FOREVER: 'Create CC forever plan',
		EDIT_FOREVER: 'Edit CC forever plan',
	},
	SUB_HEADERS: {
		LIST: 'Sub header homepage',
		CART: 'Sub header cart',
		STATIC_PAGE: 'Sub header static pages',
	},
	LIMIT_PRODUCT_PER_ACCOUNT: {
		LIST: 'Limit Product',
		CREATE: 'Create limit Product',
		EDIT: 'Edit limit Product',
	},
	ON_SITE_MESSAGING: {
		LIST: 'On-site Messaging',
		CREATE: 'Create On-site Messaging',
		EDIT: 'Edit On-site Messaging',
	},
	ON_SITE_MESSAGE_POPUP: {
		LIST: 'Popup campaign list',
		CREATE: 'Create popup campaign',
	},
	ON_SITE_MESSAGE_STRIP: {
		LIST: 'Strip bar campaign list',
		CREATE: 'Create strip campaign',
	},
	SEARCH_RESULT_CACHE: {
		LIST: 'Search result cache',
	},
	ORDER_AUTO_COMPLETE: {
		LIST: 'Order Auto Complete',
	},
});
