import 'core-js/stable';
import Vue from 'vue';
import {
	CRow,
	CCol,
	CBadge,
	CButton,
	CButtonClose,
	CButtonGroup,
	CCard,
	CCardBody,
	CCollapse,
	CContainer,
	CDataTable,
	CDropdown,
	CDropdownDivider,
	CDropdownHeader,
	CDropdownItem,
	CFooter,
	CForm,
	CHeader,
	CHeaderBrand,
	CHeaderNav,
	CHeaderNavItem,
	CHeaderNavLink,
	CIcon,
	CInput,
	CInputCheckbox,
	CInputRadio,
	CInputRadioGroup,
	CLink,
	CListGroup,
	CListGroupItem,
	CModal,
	CPagination,
	CRenderFunction,
	CSidebar,
	CSidebarBrand,
	CSidebarClose,
	CSidebarNav,
	CSidebarNavDropdown,
	CSidebarNavDivider,
	CSidebarNavItem,
	CSidebarNavTitle,
	CSpinner,
	CSwitch,
	CSubheader,
	CTab,
	CTabs,
	CTextarea,
	CToast,
	CToaster,
	CToggler,
	CWrapper,
} from '@coreui/vue';
import {
	faSearch,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faFileExport,
	faPlus,
	faTag,
	faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faChevronDown,
	faChevronRight,
	faChevronUp,
	faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import BaseActionPanelStickyFooter from '@/components/BaseActionPanelStickyFooter.vue';
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue';
import BaseButtonGroup from '@/components/BaseButtonGroup.vue';
import BaseDropDown from '@/components/BaseDropDown.vue';
import BaseFilterButton from '@/components/BaseFilterButton.vue';
import BaseSearchFormFooter from '@/components/BaseSearchFormFooter.vue';
import BaseIndeterminateCheckbox from '@/components/BaseIndeterminateCheckbox.vue';
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseInputImage from '@/components/BaseInputImage.vue';
import BaseInputMedia from '@/components/BaseInputMedia.vue';
import BaseInputNumber from '@/components/BaseInputNumber.vue';
import BaseInputPhone from '@/components/BaseInputPhone.vue';
import BaseInputTime from '@/components/BaseInputTime.vue';
import BaseInputTimeTable from '@/components/BaseInputTimeTable.vue';
import BaseLoading from '@/components/BaseLoading.vue';
import BaseModalConfirmDelete from '@/components/BaseModalConfirmDelete.vue';
import BaseModalLoading from '@/components/BaseModalLoading.vue';
import BaseNoItemBanner from '@/components/BaseNoItemBanner.vue';
import BaseRichTextEditor from '@/components/BaseRichTextEditor.vue';
import BaseSaveButton from '@/components/BaseSaveButton.vue';
import BaseTable from '@/components/BaseTable.vue';
import BaseColorPicker from '@/components/BaseColorPicker.vue';
import Vuelidate from 'vuelidate';
import IdleVue from 'idle-vue';

import App from './App.vue';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons';
import store from './store';
import { i18n } from './i18n';
import './filters';

const eventsHub = new Vue();

Vue.use(Vuelidate);
Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	store,
	idleTime: 12 * 60 * 60 * 1000, // 12 hours
	startAtIdle: false,
});
Vue.component('BaseActionPanelStickyFooter', BaseActionPanelStickyFooter);
Vue.component('BaseBreadcrumb', BaseBreadcrumb);
Vue.component('BaseButtonGroup', BaseButtonGroup);
Vue.component('BaseDropDown', BaseDropDown);
Vue.component('BaseFilterButton', BaseFilterButton);
Vue.component('BaseIndeterminateCheckbox', BaseIndeterminateCheckbox);
Vue.component('BaseInputDate', BaseInputDate);
Vue.component('BaseInputImage', BaseInputImage);
Vue.component('BaseInputMedia', BaseInputMedia);
Vue.component('BaseInputNumber', BaseInputNumber);
Vue.component('BaseInputPhone', BaseInputPhone);
Vue.component('BaseInputTime', BaseInputTime);
Vue.component('BaseInputTimeTable', BaseInputTimeTable);
Vue.component('BaseLoading', BaseLoading);
Vue.component('BaseModalConfirmDelete', BaseModalConfirmDelete);
Vue.component('BaseModalLoading', BaseModalLoading);
Vue.component('BaseNoItemBanner', BaseNoItemBanner);
Vue.component('BaseRichTextEditor', BaseRichTextEditor);
Vue.component('BaseSaveButton', BaseSaveButton);
Vue.component('BaseSearchFormFooter', BaseSearchFormFooter);
Vue.component('BaseTable', BaseTable);
Vue.component('BaseColorPicker', BaseColorPicker);
Vue.component('CBadge', CBadge);
Vue.component('CButton', CButton);
Vue.component('CButtonClose', CButtonClose);
Vue.component('CButtonGroup', CButtonGroup);
Vue.component('CCard', CCard);
Vue.component('CCardBody', CCardBody);
Vue.component('CCol', CCol);
Vue.component('CCollapse', CCollapse);
Vue.component('CContainer', CContainer);
Vue.component('CDataTable', CDataTable);
Vue.component('CDropdown', CDropdown);
Vue.component('CDropdownDivider', CDropdownDivider);
Vue.component('CDropdownHeader', CDropdownHeader);
Vue.component('CDropdownItem', CDropdownItem);
Vue.component('CFooter', CFooter);
Vue.component('CForm', CForm);
Vue.component('CHeader', CHeader);
Vue.component('CHeaderBrand', CHeaderBrand);
Vue.component('CHeaderNav', CHeaderNav);
Vue.component('CHeaderNavItem', CHeaderNavItem);
Vue.component('CHeaderNavLink', CHeaderNavLink);
Vue.component('CIcon', CIcon);
Vue.component('CInput', CInput);
Vue.component('CInputCheckbox', CInputCheckbox);
Vue.component('CInputRadio', CInputRadio);
Vue.component('CInputRadioGroup', CInputRadioGroup);
Vue.component('CLink', CLink);
Vue.component('CListGroup', CListGroup);
Vue.component('CListGroupItem', CListGroupItem);
Vue.component('CModal', CModal);
Vue.component('CPagination', CPagination);
Vue.component('CRenderFunction', CRenderFunction);
Vue.component('CRow', CRow);
Vue.component('CSidebar', CSidebar);
Vue.component('CSidebarBrand', CSidebarBrand);
Vue.component('CSidebarClose', CSidebarClose);
Vue.component('CSidebarNav', CSidebarNav);
Vue.component('CSidebarNavDivider', CSidebarNavDivider);
Vue.component('CSidebarNavDropdown', CSidebarNavDropdown);
Vue.component('CSidebarNavItem', CSidebarNavItem);
Vue.component('CSidebarNavTitle', CSidebarNavTitle);
Vue.component('CSpinner', CSpinner);
Vue.component('CSubheader', CSubheader);
Vue.component('CSwitch', CSwitch);
Vue.component('CTab', CTab);
Vue.component('CTabs', CTabs);
Vue.component('CTextarea', CTextarea);
Vue.component('CToast', CToast);
Vue.component('CToaster', CToaster);
Vue.component('CToggler', CToggler);
Vue.component('CWrapper', CWrapper);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

faLibrary.add(
	faSearch,
	faAngleDown,
	faAngleLeft,
	faAngleRight,
	faAngleDoubleRight,
	faAngleDoubleLeft,
	faFileExport,
	faPlus,
	faChevronDown,
	faChevronRight,
	faChevronDown,
	faChevronUp,
	faTag,
	faCheckCircle,
	faCircleNotch,
);

new Vue({
	i18n,
	router,
	store,
	// CIcon component documentation: https://coreui.io/vue/docs/components/icon
	icons,
	render: (h) => h(App),
}).$mount('#app');
