import { i18n } from '@/i18n';
import {
	getPopupCampaignsAPI,
	getPopupCampaignAPI,
	postPopupCampaignAPI,
	updatePopupCampaignAPI,
	deletePopupCampaignAPI,

	getStripCampaignsAPI,
	getStripCampaignAPI,
	postStripCampaignAPI,
	updateStripCampaignAPI,
	deleteStripCampaignAPI,
} from '../../services/api/onSiteMessages.api';
import {
	GET_ON_SITE_MESSAGES_REQUEST,
	GET_ON_SITE_MESSAGES_SUCCESS,
	GET_ON_SITE_MESSAGES_FAILURE,
	GET_ON_SITE_MESSAGE_REQUEST,
	GET_ON_SITE_MESSAGE_SUCCESS,
	GET_ON_SITE_MESSAGE_FAILURE,
	CREATE_ON_SITE_MESSAGE_REQUEST,
	CREATE_ON_SITE_MESSAGE_SUCCESS,
	CREATE_ON_SITE_MESSAGE_FAILURE,
	UPDATE_ON_SITE_MESSAGE_REQUEST,
	UPDATE_ON_SITE_MESSAGE_SUCCESS,
	UPDATE_ON_SITE_MESSAGE_FAILURE,
	DELETE_ON_SITE_MESSAGE_REQUEST,
	DELETE_ON_SITE_MESSAGE_SUCCESS,
	DELETE_ON_SITE_MESSAGE_FAILURE,
} from '../types';
import {
	transformedCampaignList,
	transformedCampaignDetail,
	transformedCampaignDetailForm,
} from '../../assets/js/transform/onSiteMessages';
import { numberFormat } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			data: [],
			lastUpdated: null,
			isLoading: false,
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
	},
	getters: {
		getCampaignList(state) {
			return transformedCampaignList(state.list.data);
		},
		getCampaignDetail(state) {
			return transformedCampaignDetailForm(state.edit.data);
		},
		getCampaignGeneralInfo(state) {
			return transformedCampaignDetail(state.edit.data);
		},
	},
	mutations: {
		[GET_ON_SITE_MESSAGES_REQUEST](state) {
			state.list = {
				...state.list,
				data: [],
				meta: {
					currentPage: 1,
					lastPage: 0,
				},
				isLoading: true,
			};
		},
		[GET_ON_SITE_MESSAGES_SUCCESS](state, { data, meta = {}, ...rest }) {
			state.list = {
				...rest,
				data,
				meta: {
					...meta,
					currentPage: meta.current_page,
					lastPage: meta.last_page,
					total: numberFormat(meta.total),
				},
				isLoading: false,
			};
		},
		[GET_ON_SITE_MESSAGES_FAILURE](state) {
			state.list.isLoading = false;
		},
		[GET_ON_SITE_MESSAGE_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_ON_SITE_MESSAGE_SUCCESS](state, { data }) {
			state.edit.data = data;
			state.edit.isLoading = false;
		},
		[GET_ON_SITE_MESSAGE_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[CREATE_ON_SITE_MESSAGE_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_ON_SITE_MESSAGE_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_ON_SITE_MESSAGE_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_ON_SITE_MESSAGE_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_ON_SITE_MESSAGE_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_ON_SITE_MESSAGE_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		// TODO: implement later
		[DELETE_ON_SITE_MESSAGE_REQUEST]() { },
		[DELETE_ON_SITE_MESSAGE_SUCCESS]() { },
		[DELETE_ON_SITE_MESSAGE_FAILURE]() { },
	},
	actions: {
		// POPUP CAMPAIGNS
		async getPopupCampaigns({ commit }, query) {
			try {
				commit(GET_ON_SITE_MESSAGES_REQUEST);
				const { data } = await getPopupCampaignsAPI(query);
				commit(GET_ON_SITE_MESSAGES_SUCCESS, data);
			} catch (error) {
				commit(GET_ON_SITE_MESSAGES_FAILURE);
			}
		},
		async getPopupCampaign({ commit }, id) {
			try {
				commit(GET_ON_SITE_MESSAGE_REQUEST);

				const { data } = await getPopupCampaignAPI(id);

				commit(GET_ON_SITE_MESSAGE_SUCCESS, data);
			} catch (error) {
				commit(GET_ON_SITE_MESSAGE_FAILURE);
			}
		},
		async createPopupCampaign({ commit, dispatch }, params) {
			try {
				commit(CREATE_ON_SITE_MESSAGE_REQUEST);
				await postPopupCampaignAPI(params);
				commit(CREATE_ON_SITE_MESSAGE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_ON_SITE_MESSAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async updatePopupCampaign({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_ON_SITE_MESSAGE_REQUEST);

				await updatePopupCampaignAPI(id, params);

				commit(UPDATE_ON_SITE_MESSAGE_SUCCESS);
				dispatch('getPopupCampaign', id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_ON_SITE_MESSAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async deletePopupCampaign({ commit, dispatch }, id) {
			try {
				commit(DELETE_ON_SITE_MESSAGE_REQUEST);

				await deletePopupCampaignAPI(id);

				commit(DELETE_ON_SITE_MESSAGE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Promotion' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_ON_SITE_MESSAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},

		// STRIP CAMPAIGNS
		async getStripCampaigns({ commit }, query) {
			try {
				commit(GET_ON_SITE_MESSAGES_REQUEST);
				const { data } = await getStripCampaignsAPI(query);
				commit(GET_ON_SITE_MESSAGES_SUCCESS, data);
			} catch (error) {
				commit(GET_ON_SITE_MESSAGES_FAILURE);
			}
		},
		async getStripCampaign({ commit }, id) {
			try {
				commit(GET_ON_SITE_MESSAGE_REQUEST);

				const { data } = await getStripCampaignAPI(id);

				commit(GET_ON_SITE_MESSAGE_SUCCESS, data);
			} catch (error) {
				commit(GET_ON_SITE_MESSAGE_FAILURE);
			}
		},
		async createStripCampaign({ commit, dispatch }, params) {
			try {
				commit(CREATE_ON_SITE_MESSAGE_REQUEST);
				await postStripCampaignAPI(params);
				commit(CREATE_ON_SITE_MESSAGE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_ON_SITE_MESSAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async updateStripCampaign({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_ON_SITE_MESSAGE_REQUEST);

				await updateStripCampaignAPI(id, params);

				commit(UPDATE_ON_SITE_MESSAGE_SUCCESS);
				dispatch('getStripCampaign', id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_ON_SITE_MESSAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async deleteStripCampaign({ commit, dispatch }, id) {
			try {
				commit(DELETE_ON_SITE_MESSAGE_REQUEST);

				await deleteStripCampaignAPI(id);

				commit(DELETE_ON_SITE_MESSAGE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Promotion' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_ON_SITE_MESSAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},

	},
};
