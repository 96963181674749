export const INSTALLMENT_PLAN_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'id-col th-mw-50' },
	{ key: 'name', label: 'Plan name', class: 'plan-name-col th-mw-250' },
	{ key: 'interestRate', label: 'Interest rate', class: 'interest-rate-col', cellClass: 'interest-rate-cel' },
	{ key: 'startDate', label: 'Start date', class: 'start-date-col th-mw-150' },
	{ key: 'endDate', label: 'End date', class: 'end-date-col th-mw-150' },
	{ key: 'periodStatus', label: 'Status', class: 'col-status th-mw-80' },
	{ key: 'period', label: 'Term', class: 'period-col th-mw-100' },
	{ key: 'availableSkus', label: 'Available SKUs', class: 'available-skus-col', cellClass: 'available-skus-cel' },
	{ key: 'minOrderAmount', label: 'Minimum order', class: 'minimum-order-col', cellClass: 'minimum-order-cel' },
	{ key: 'maxOrderAmount', label: 'Maximum order', class: 'maximum-order-col', cellClass: 'maximum-order-cel' },
	{ key: 'supplierId', label: 'Supplier ID', class: 'supplier-id-col', cellClass: 'supplier-id-cel' },
	{ key: 'planId', label: '', class: 'deleted-col' },
]);
export const INSTALLMENT_PLAN_PERIODS_STATUSES = {
	ON_GOING: 'ongoing',
	SCHEDULED: 'scheduled',
	EXPIRED: 'expired',
};
export const INSTALLMENT_PLAN_PERIODS_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Ongoing', value: INSTALLMENT_PLAN_PERIODS_STATUSES.ON_GOING },
	{ name: 'Scheduled', value: INSTALLMENT_PLAN_PERIODS_STATUSES.SCHEDULED },
	{ name: 'Ended', value: INSTALLMENT_PLAN_PERIODS_STATUSES.EXPIRED },
];
// mapping installment title, use with CBadge component
export const INSTALLMENT_PLAN_TITLE = {
	PERIODS_STATUSES: {
		[INSTALLMENT_PLAN_PERIODS_STATUSES.ON_GOING]: 'Ongoing',
		[INSTALLMENT_PLAN_PERIODS_STATUSES.SCHEDULED]: 'Scheduled',
		[INSTALLMENT_PLAN_PERIODS_STATUSES.EXPIRED]: 'Ended',
	},
};
// mapping installment color, use with CBadge property components
export const INSTALLMENT_PLAN_COLOR = {
	PERIODS_STATUSES: {
		[INSTALLMENT_PLAN_PERIODS_STATUSES.ON_GOING]: 'hypertext',
		[INSTALLMENT_PLAN_PERIODS_STATUSES.SCHEDULED]: 'info',
		[INSTALLMENT_PLAN_PERIODS_STATUSES.EXPIRED]: 'alto',
	},
};