<template>
	<div class="color-picker-input-container">
		<div class="color-picker-input-wrapper">
			<div ref="colorPicker" class="custom-color-picker">
				<div
					v-if="previewPosition === 'left'"
					class="custom-preview-color-box"
					:style="dynamicStyle"
					@click="showPicker()"
				></div>

				<CInput
					v-model="localValue"
					class="custom-color-picker-input"
					type="text"
					data-test-id="input-color-picker"
					:is-valid="!$v.localValue.$invalid && null"
					:invalid-feedback="invalidFeedback"
					@focus="showPicker"
					@input="handleInput"
					@change="handleChange"
				/>

				<div
					v-if="previewPosition === 'right'"
					class="custom-preview-color-box"
					:style="dynamicStyle"
					@click="showPicker()"
				></div>

				<!-- color picker popup -->
				<ColorPicker
					v-if="displayPicker"
					:color="color"
					:class="dynamicPositionMenu"
					:on-start-change="color => onChange(color)"
					:on-change="color => onChange(color)"
					:on-end-change="color => onChange(color)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
// ref:: https://github.com/arthay/vue-color-gradient-picker
import { ColorPicker } from 'vue-color-gradient-picker';
import 'vue-color-gradient-picker/dist/index.css';

import { hexColorFormat } from '../assets/js/validators';
import { hexColor } from '../regex/general';

export default {
	components: {
		ColorPicker,
	},

	props: {
		value: {
			type: String,
			default: null,
		},
		previewPosition: {
			type: String,
			default: 'left',
			validator(value) {
				return /^(left|right)$/.test(value);
			},
		},
		pickerPosition: {
			type: String,
			default: 'bottom',
			validator(value) {
				return /^(top|bottom)$/.test(value);
			},
		},
	},

	validations() {
		return {
			localValue: {
				required,
				format: hexColorFormat,
			},
		};
	},

	data() {
		const color = {
			red: 255,
			green: 0,
			blue: 0,
			alpha: 1,
			style: 'rgba(255, 0, 0, 1)',
		};

		return {
			localValue: '#FF0000',
			displayPicker: false,
			color,
		};
	},

	computed: {
		/**
		 * Checks if the given value is a valid hex color.
		 *
		 * @return {boolean} True if the value is a valid hex color, otherwise false.
		 */
		isHexColor() {
			return hexColor.test(this.localValue);
		},

		/**
		 * Returns the error message for invalid feedback.
		 *
		 * @return {null} The error message for invalid feedback or null if no error.
		 */
		invalidFeedback() {
			const { format, required: requiredFormat } = this.$v.localValue;

			if (!format) return this.$t('global.error.invalid');
			if (!requiredFormat) return this.$t('global.error.required');

			return null;
		},

		/**
		 * Generates the dynamic style for the component.
		 *
		 * @return {Object} The dynamic style object.
		 */
		dynamicStyle() {
			return {
				'background-color': this.color.style,
			};
		},

		/**
		 * Generates the dynamic position for the custom color picker menu.
		 *
		 * @return {string} The dynamic position for the custom color picker menu.
		 */
		dynamicPositionMenu() {
			return `custom-color-picker-menu ${this.pickerPosition}`;
		},
	},

	mounted() {
		if (this.value) {
			this.localValue = this.value;

			this.handleChange();
		}
	},

	methods: {
		/**
		 * Converts RGB color values to a hexadecimal color code.
		 *
		 * @return {string} The hexadecimal color code.
		 */
		rgbToHex() {
			const r16 = this.color.red.toString(16).padStart(2, '0');
			const g16 = this.color.green.toString(16).padStart(2, '0');
			const b16 = this.color.blue.toString(16).padStart(2, '0');
			return `#${r16}${g16}${b16}`;
		},

		/**
		 * Updates the color and emits the new value.
		 *
		 * @param {Object} attrs - The new color attributes.
		 * @return {void}
		 */
		onChange(attrs) {
			this.color = { ...attrs };
			const hex = this.rgbToHex();

			this.localValue = hex;
			this.$emit('input', hex);
		},

		/**
		 * Shows the picker and adds an event listener for clicks on the document.
		 *
		 * @param {type} paramName - description of parameter
		 * @return {type} description of return value
		 */
		showPicker() {
			this.displayPicker = true;
			document.addEventListener('click', this.documentClick);
		},

		/**
		 * Hides the picker and removes the event listener for clicks on the document.
		 *
		 * @return {void}
		 */
		hidePicker() {
			this.displayPicker = false;
			document.removeEventListener('click', this.documentClick);
		},

		/**
		 * Handles the document click event.
		 *
		 * @param {Event} event - The click event.
		 */
		documentClick(event) {
			const element = this.$refs.colorPicker;
			const target = event.target;

			if (element !== target && !element.contains(target)) {
				this.hidePicker();
			}
		},

		/**
		 * Handles the input value.
		 *
		 * @param {any} value - The input value.
		 * @return {void}
		 */
		handleInput(value) {
			this.$emit('input', value);
		},

		/**
		 * Handles the change event.
		 *
		 * @param {type} paramName - description of parameter
		 * @return {type} description of return value
		 */
		handleChange() {
			const regexp = /([0-9A-F])([0-9A-F])([0-9A-F])/i;
			let tempLocalValue = this.localValue;

			if (this.isHexColor) {
				if (tempLocalValue[0] === '#') {
					tempLocalValue = tempLocalValue.slice(1);
				}

				if (tempLocalValue.length === 3) {
					tempLocalValue = tempLocalValue.replace(regexp, '$1$1$2$2$3$3');
				}

				this.color.red = parseInt(tempLocalValue.substr(0, 2), 16);
				this.color.green = parseInt(tempLocalValue.substr(2, 2), 16);
				this.color.blue = parseInt(tempLocalValue.substr(4, 2), 16);
				this.color.alpha = (parseInt(tempLocalValue.substr(6, 2), 16) / 255) || 1;

				this.color.style = `rgba(${this.color.red}, ${this.color.green}, ${this.color.blue}, ${this.color.alpha})`;

				this.$emit('input', this.rgbToHex());
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.color-picker-input-container {
	.color-picker-input-wrapper {
		.custom-color-picker {
			display: flex;
			gap: rem(8);

			position: relative;

			.custom-color-picker-input {
				width: 100%;
			}

			.custom-preview-color-box {
				width: rem(35);
				height: rem(35);
				padding: rem(2);

				border: rem(2) solid $color-gray-300;
				border-radius: rem(4);
			}

			&-menu {
				position: absolute;
				z-index: 2;

				&.bottom {
					top: rem(45);
					left: rem(-18);
				}

				&.top {
					bottom: rem(45);
					left: rem(-18);
				}
			}
		}
	}
}
</style>
